import styled from "styled-components";

export const InformationContainer= styled.div`
    padding: 25px;
    max-width: 1000px;
`;

export const Blur = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background: linear-gradient(0deg, rgba(183, 94, 213, 0.1) 53.97%, rgba(34, 255, 54, 0.1) 150.62%);
  filter: blur(45px);
  z-index: -1;
`