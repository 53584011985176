
import { FC, memo, ReactNode, useState } from 'react';
import { Blur, InformationContainer } from './rules.styled';
import { Column } from '../../../ui-kit/column';
import { Text } from '../../../ui-kit/text';
import { themes } from '../../../ui-kit/themes/theme';
import { Spacing } from '../../../ui-kit/spacing';
import { Surface } from '../../../ui-kit/surface';
import { Translate } from '../../../ui-kit/translate';
import { gamesRulesTextEng } from '../../../resources/texts/gamesRulesText/gamesRulesTextEng';
import { gamesRulesTextRu } from '../../../resources/texts/gamesRulesText/gamesRulesTextRu';
import { gamesRulesTextPortuguese } from '../../../resources/texts/gamesRulesText/gamesRulesTextPortuguese';
import { gamesRulesTextSpain } from '../../../resources/texts/gamesRulesText/gamesRulesTextSpain';

type LanguageCode = 'en' | 'ru' | 'pt' | 'ES';

const textOptions: Record<LanguageCode, dataTextType[]> = {
  en: gamesRulesTextEng,
  ru: gamesRulesTextRu,
  pt: gamesRulesTextPortuguese,
  ES: gamesRulesTextSpain,
};

export type dataTextType = {
  header: ReactNode,
  text: ReactNode
}

export type RulesViewProps = {
  language: string
};
export const RulesView: FC<RulesViewProps> = memo(({language}) => {
  
  const [data] = useState<dataTextType[]>(textOptions[language as LanguageCode] || gamesRulesTextEng);

  return (
    <Column style={{ height: "100vh", position: "relative", overflow: 'auto' }} horizontalAlign="center">
      <Blur />
      <InformationContainer>
        <Surface padding={25}>
          <Text align='center' themeColor={themes.colors.gray} themeFont={themes.fonts.h1}>
            <Translate keyWord='account.rules' />
          </Text>
          <Spacing themeSpace={25} variant='Column' />
          <Text align='center' themeColor={themes.colors.secondary} themeFont={themes.fonts.h2}>
            <Translate keyWord='sitgo.btrt.title' />
          </Text>
          <Spacing themeSpace={20} variant='Column' />
          { data.map(item => <Paragraph header={item.header} text={item.text}/>) }
        </Surface>
      </InformationContainer>
    </Column>
  )
});

export type ParagraphProps ={
  header?: ReactNode;
  text?: ReactNode;
}

export const Paragraph: FC<ParagraphProps> = memo(({
  header,
  text
}) => {
  return (
    <>
      <Text style={{fontSize: 16, lineHeight: 1.2, textAlign: 'center'}} themeColor={themes.colors.secondary} themeFont={themes.fonts.t}>
        <b>{header}</b>
      </Text>
      <br/>
      <Text style={{fontSize: 14, lineHeight: 1.3, textAlign: 'justify',textIndent: 30}} themeColor={themes.colors.foreground} themeFont={themes.fonts.t}>
        {text}
      </Text>
      <br/>
    </>
  );
});