export const gamesRulesTextRu = [
  {
    header: <>Участие</>,
    text: <>
      Участие в турнирах доступно только зарегистрированным пользователям, 
      достигшим совершеннолетия в соответствии с законами и правилами их страны.
    </>
  },
  {
    header: <>Турниры</>,
    text: <>
      Турниры проводятся в формате SIT&GO и могут включать 2, 4 или 6 участников, 
      в зависимости от выбранной категории. Турниры фильтруются по продолжительности 
      и размеру взноса. Продолжительность турнира может составлять 5 минут, 15 минут, 
      1 час или 4 часа, а размер взноса варьируется от $2 до $100.
    </>
  },
  {
    header: <>Вход в турнир</>,
    text: <>
      При входе в турнир с пользователя списывается сумма взноса, которая формирует 
      призовой фонд турнира. Пользователи могут выйти из турнира и получить возврат 
      взноса до тех пор, пока не будет достигнуто необходимое количество участников 
      для начала игры.
    </>
  },
  {
    header: <>Прогнозы в турнире</>,
    text: <>
      Как только достигнуто необходимое количество участников, каждый участник турнира 
      имеет 30 секунд, чтобы сделать прогноз на цену Биткоина к концу таймера турнира. 
      Если пользователь не успевает сделать прогноз в течение этого времени, то его 
      прогноз по умолчанию будет равен курсу Биткоина на момент окончания таймера.
    </>
  },
  {
    header: <>Определение победителя</>,
    text: <>
      Побеждает участник, чьи прогнозы ближе всего к фактической цене Биткоина на 
      момент окончания таймера турнира. В случае, если несколько участников сделают 
      один и тот же прогноз, который окажется самым точным, призовой фонд турнира 
      делится между ними поровну. Призы указываются в окне турнира.
    </>
  },
  {
    header: <>Честная игра</>,
    text: <>
      Пользователям запрещено использовать мошеннические методы или манипуляции для 
      улучшения своих шансов на победу. Любые попытки мошенничества или нарушения 
      правил могут привести к дисквалификации и потере доступа к аккаунту.
    </>
  },
  {
    header: <>Разрешение споров</>,
    text: <>
      В случае споров или недоразумений, связанных с игрой, решение принимает 
      администрация Bitrate, и оно является окончательным и обязательным для всех 
      участников.
      <br/><br/>
      <div style={{textIndent: 30}}>
        Эти правила обеспечивают справедливую и увлекательную игровую среду для 
        всех участников турниров в нашем приложении.
      </div>
    </>
  }
];
