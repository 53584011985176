import styled, { keyframes } from "styled-components";

export const PinkBlur = styled.div`
position: absolute;
width:100%;
max-width: 600px;
left: 50%;
transform: translateX(-50%);
height: 545px;
top: 70px;
z-index: -1;
background: radial-gradient(circle, rgb(183, 94, 213,0.17) 0%, rgb(28, 28, 28) 75%);
`

export const GreenBlur = styled.div`
position: absolute;
width:100%;
height: 545px;
top: 20px;
z-index: -1;
background: linear-gradient(0deg, transparent 55%, rgba(34, 255, 54,0.07) 100%);
`

export const Panel = styled.div<{ color: string }>`
    width: 100%;
    height: 180px;
    border-radius: 0px 0px 20px 20px;
    background: ${(({ color }) => color)};
`

export const HeaderBackground = styled.div`
    display: flex;
    width: 100%;
    height: 45px;
    align-items: center;
    background: rgba(28, 28, 28, 0.2);
    filter: drop-shadow(0px -10px 15px rgba(255,255,255, 0.07));
    backdrop-filter: blur(5px);
    border-radius: 50px;
    padding-left: 7px;
`;

export const WalletBackground = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    border: 0.5px solid #FFFFFF;
    border-radius: 20px;
    width: 165px;
    position: relative;
    height: 30px;
`

export const Circle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 30px;
    width: 30px;
    box-shadow: inset 0px 0px 4px 2px rgba(255,255,255, 0.1);
    border-radius: 50%;
    border: 0.5px solid #FFFFFF;
`


const move = keyframes`
   0% { offset-distance: 25% }
   25% { offset-distance: 35% }
   75% { offset-distance: 15% }
   100% { offset-distance: 25% }
`
export const SwipeIconWrapper = styled.div`
    width: 45px;
    height: 30px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.3);
    bottom: 0;
    border-radius: 50px;
    background-color: #1C1C1C;
    left: 50%;
    offset-path: ellipse(135px 68px at 50% 50%);
    offset-distance: 25%;
    transform: rotate(0.5turn);
    animation: ${move} 2s linear;

    @media (max-height: 715px){
      offset-path: ellipse(105px 52px at 50% 50%);
    }

    @media (min-height: 750px) and (min-width: 350px) {
      offset-path: ellipse(160px 80px at 50% 50%);
    }

    @media (min-height: 800px) and (min-width: 390px) {
      offset-path: ellipse(180px 90px at 50% 50%);
    }

    @media (max-height: 650px) {
      offset-path: ellipse(90px 37px at 50.00% 48.67%);
    }
`;

export const CoinContainer = styled.div`
  position: relative;
  width: 240px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-height: 715px){
    width: 180px;
    height: 180px;
  }

  @media (min-height: 750px) and (min-width: 350px) {
    width: 240px;
    height: 240px;
  }

  @media (min-height: 800px) and (min-width: 390px) {
    width: 240px;
    height: 240px;
  }

  @media (max-height: 650px) {
    width: 130px;
    height: 130px;
  }
`;

export const Ring = styled.div<{ wave: 'one' | 'two' }>`
  position: absolute;
  border-radius: 50%;
  width: 270px;
  height: 135px;
  bottom: ${(({ wave }) => `${wave === 'one' ? 60 : 22}px`)};

  border: 1px solid rgba(255, 255, 255, 0.1);


  @media (max-height: 715px){
    width: 210px;
    height: 105px;
    bottom: ${(({ wave }) => `${wave === 'one' ? 55 : 18}px`)};
  }

  @media (min-height: 750px) and (min-width: 350px) {
    width: 320px;
    height: 160px;
    bottom: ${(({ wave }) => `${wave === 'one' ? 70 : 25}px`)};
  }

  @media (min-height: 800px) and (min-width: 390px) {
    width: 360px;
    height: 180px;
    bottom: ${(({ wave }) => `${wave === 'one' ? 80 : 20}px`)};
  }

  @media (max-height: 650px) {
    width: 180px;
    height: 75px;
    bottom: ${(({ wave }) => `${wave === 'one' ? 40 : 18}px`)};
  }
`

export const GameContent = styled.div`
    height: 100%;
    width: 100%;
`;

const moveVertically = (y: number) => keyframes`
  from{
    display: block;
    opacity: 1;
    transform: translateY(${y}px)
  }
  to{
    opacity: 0;
    transform: translateY(${y - 150}px);
    display: none;
  }
`
export const AnimateText = styled.div<{ vartical: number, horizontal: number }>`
  position: absolute;
  left: ${(({ horizontal }) => horizontal)}px;
  animation: ${props => moveVertically(props.vartical)} 2s ease forwards ;
  z-index: 10000;
`