export enum Currency {
    BTC = "BTC",
    ETH = "ETH",
    LTC = "LTC",
    DOGE = "DOGE",
    XRP = "XRP",
    BCH = "BCH",
    TON = "TON",
    SOL = "SOL",
    USDT = "USDT"
}

export enum NetworkCurrency {
    BTC = "bitcoin",
    ERC20 = "ERC20",
    TRC20 = "TRC20",
    LTC = "litecoin",
    DOGE = "doge",
    ETH = "ethereum",
    XRP = "ripple",
    BCH = "bitcoin Cash",
    TON = "TON",
    SOL = "solana"
}

export const networkTags = {
    bitcoin: "BTC",
    ERC20: "ERC20",
    TRC20: "TRC20",
    litecoin: "LTC",
    doge: "DOGE",
    ethereum: "ETH",
    ripple: "XRP",
    "bitcoin Cash": "BCH",
    TON: "TON",
    solana: "SOL"
}

export enum WalletType {
    WITHDRAW = "WITHDRAW",
    DEPOSIT = "DEPOSIT",
}