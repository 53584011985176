import { useCallback, useEffect } from "react";
import { chartSlice, startDataListening, stopDataListening } from "../store/reducers/chart-slice";
import { useAppDispatch, useTypedSelector } from "./use-typed-selector";
import { useUser } from "./user-hook";

export const useChart = () => {

  const { data, status } = useTypedSelector(state => state.chart);
  const { authToken } = useUser();
  const dispatch = useAppDispatch();

  const onStartChartPlay = useCallback(() => {
    dispatch(startDataListening(authToken));
  }, [authToken, dispatch]);

  const onStopChartPlay = useCallback(() => {
    dispatch(stopDataListening());
    dispatch(chartSlice.actions.dataClear());
  }, [dispatch])

  useEffect(() => {
    onStartChartPlay();
    return () => {
      onStopChartPlay();
    };
  }, [onStartChartPlay, onStopChartPlay]);

  return { data: data, status: status, stop: stopDataListening, start: onStartChartPlay };

}

