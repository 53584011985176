import { FC, memo } from "react";
import { FooterBackground, IconFooter, SelectButton } from "./footer.styled";
import { Text } from '../text';
import { themes } from "../themes/theme";
import { Pressable } from "../pressable";
import { Spacing } from "../../сomponents/spacing";

import farmIconSecondary from '../assets/footer/farm.svg';
import taskIconSecondary from '../assets/footer/tasks.svg';
import topIconSecondary from '../assets/footer/top.svg';
import gamesIconSecondary from '../assets/footer/games.svg';
import fortuneWheelIconSecondary from '../assets/footer/fortune-wheel.svg';

import farmIconPrimary from '../assets/footer/farm-primary.svg';
import taskIconPrimary from '../assets/footer/tasks-primary.svg';
import topIconPrimary from '../assets/footer/top-primary.svg';
import gamesIconPrimary from '../assets/footer/games-primary.svg';
import fortuneWheelIconPrimary from '../assets/footer/fortune-wheel-primary.svg';
import { Translate } from "../translate";

export type FooterProps = {
  type: "footer.farm" | "footer.tasks" | "footer.top" | "footer.games";
  onLeaderboard: () => void;
  onTask: () => void;
  onFortune: () => void;
  onFarm: () => void;
  onGames: () => void;
}

export const Footer: FC<FooterProps> = memo(({ type, onLeaderboard, onFortune, onFarm, onTask, onGames }) => {
  
  const items = [
    {
      title: "footer.farm",
      iconPrimary: farmIconPrimary,
      iconSecondary: farmIconSecondary,
      hidden: false,
      local: onFarm
    },
    {
      title: "footer.tasks",
      iconPrimary: taskIconPrimary,
      iconSecondary: taskIconSecondary,
      hidden: false,
      local: onTask
    },
    {
      title: "footer.fortune",
      iconPrimary: fortuneWheelIconPrimary,
      iconSecondary: fortuneWheelIconSecondary,
      hidden: false,
      local: onFortune
    },
    {
      title: "footer.top",
      iconPrimary: topIconPrimary,
      iconSecondary: topIconSecondary,
      hidden: false,
      local: onLeaderboard,
    },
    {
      title: "footer.games",
      iconPrimary: gamesIconPrimary,
      iconSecondary: gamesIconSecondary,
      hidden: false,
      local: onGames,
    }
  ];


  const filtered = items.map((item) => item.title === type ? { ...item, hidden: true } : item);
  return (
    <FooterBackground>
      {filtered.map((item) =>
        <Pressable onClick={item.local}>
          <SelectButton active={item.hidden}>
            <IconFooter src={item.hidden ? item.iconPrimary : item.iconSecondary} size={16} />
            <Spacing variant="Column" themeSpace={2} />
            <Text themeColor={item.hidden ? themes.colors.background : item.title === "footer.games" ? themes.colors.primary : themes.colors.gray} themeFont={themes.fonts.ht2}>
              <Translate keyWord={item.title} />
            </Text>
          </SelectButton>
        </Pressable>
      )}
    </FooterBackground>
  );
})