import { useEffect } from "react";
import { useAppDispatch, useTypedSelector } from "../use-typed-selector";
import { useUser } from "../user-hook";
import { getGameResult, getGameStatistics, statisticsSlice } from "../../store/reducers/personal-account/statistics-slice";

export const useStatistics = () => {
    const { games } = useTypedSelector(state => state.statistics);
    const { authToken } = useUser();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getGameStatistics({ authToken: authToken }));
    }, [authToken, dispatch])

    return { games }
}

export const useGameResult = (roomId: number) => {
    const { gameResult } = useTypedSelector(state => state.statistics);
    const { authToken } = useUser();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getGameResult({ authToken: authToken, roomId: roomId }));
        return () => {
            dispatch(statisticsSlice.actions.clearGameResult());
        }
    }, [authToken, dispatch, roomId])

    return { gameResult }
}