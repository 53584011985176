import { useCallback, useEffect, useMemo, useState } from "react";
import { useActiveRooms, useRoomInfo } from "../rooms-hook"
import { useAppDispatch, useTypedSelector } from "../use-typed-selector";
import { useUser } from "../user-hook";
import { fetchCurrentBet, fetchLeave, fetchMakeBet, fetchSeatUserGame, gameSlice, startGameListening, stopGameListening } from "../../store/reducers/sit-and-go/game-slice";
import { FinishPayloadType, GameDataProps, GamePayloadType, SSEStatus, UserDataType } from "../../api/sit-go/game-api";
import { RoomInfo } from "../../store/reducers/sit-and-go/rooms-slice";

export type Game = {
    usersGame: GameDataProps<GamePayloadType | FinishPayloadType> | null;
    bet: "loading" | "error" | "idle" | "success";
    status: SSEStatus;
    betValue: string;
    leave: boolean;
    nonValidate: boolean;
    roomInfo: RoomInfo | null;
    players: UserDataType[];
    playerStatus: "VIEWER" | "PLAYER";
    currentBetValue: number;
    makeBetValue: number;
    time: number;
    nonAmountGame: boolean;
    onMakeBet: () => void;
    onSeat: () => void;
    onLeave: () => void;
    onStart: () => void;
    onStop: () => void;
    onBack: () => void;
}

export const useGame = (roomId: number) => {
    const { user, refreshUserBalance, authToken } = useUser();
    const { usersGame, bet, status, betValue, leave, nonValidate, currentBetValue, nonAmountGame } = useTypedSelector(state => state.game);
    const room = useRoomForGame(roomId);
    const dispatch = useAppDispatch();

    const startGame = useCallback((isRecconet: boolean) => {
        if (room.status !== "loading") dispatch(startGameListening(authToken, roomId, isRecconet? false : room.status === "enter" ? false : true));
    }, [authToken, dispatch, room.status, roomId]);

    const stopGame = useCallback(() => {
        dispatch(stopGameListening());
        dispatch(gameSlice.actions.dataClear());
    }, [dispatch]);

    useEffect(() => {
        startGame(false);
        return () => { stopGame() };
    }, [startGame, stopGame]);

    useEffect(() => {
        if (usersGame?.status === "ENDED") {
            dispatch(stopGameListening());
            refreshUserBalance();
        }
    }, [dispatch, refreshUserBalance, usersGame?.status]);

    const onSeat = useCallback(() => {
        const game = usersGame?.payload as GamePayloadType;
        const playerSeat = game.info.some((item) => item.userId === user.userId && item.role === "VIEWER");
        if (game && playerSeat) {
            dispatch(fetchSeatUserGame({ authToken: authToken, roomId: roomId, amount: game.amount, currency: game.currencies }));
        }
    }, [authToken, dispatch, roomId, user.userId, usersGame?.payload])

    const [makeBetValue, setBetNewValue] = useState<number>(0);
    const onMakeBet = useCallback(() => {
        const game = usersGame?.payload as GamePayloadType;
        if (betValue !== "") {
            setBetNewValue(Number(betValue));
            dispatch(fetchMakeBet({ authToken: authToken, roomId: roomId, userName: user.userName, bet: Number(betValue), amount: game.amount }));
            refreshUserBalance();
        }
    }, [authToken, betValue, dispatch, refreshUserBalance, roomId, user.userName, usersGame?.payload]);
    useEffect(() => {
        if (usersGame?.status === "MAKE_BET") {
            dispatch(fetchCurrentBet({ roomId: roomId, authToken: authToken }));
        }
    }, [authToken, dispatch, roomId, usersGame?.status])

    const reconnectGame = useCallback(() => {
        if (status === "reconnect") {
            startGame(true);
        }
    }, [startGame, status]);
    useEffect(() => {
        reconnectGame();
    }, [reconnectGame]);

    const players = useMemo(() => {
        const info = usersGame?.payload;
        if (info !== undefined && usersGame?.status !== "ENDED") {
            const players = info as GamePayloadType;
            return players.info;
        }
        else {
            return [];
        }
    }, [usersGame?.payload, usersGame?.status]);
    const playerStatus = useMemo(() => {
        const status = players.find((item) => item.userId === user.userId);
        return status ? status.role : "VIEWER";
    }, [players, user.userId]);

    const time = useMemo(() => {
        if (usersGame?.payload !== undefined && usersGame?.status !== "ENDED") {
           
            const game = usersGame?.payload as GamePayloadType;
            const diff = game.info.find((item) => item.userId === user.userId)?.difference;
            if (diff !== undefined && diff !== null) return Number(diff);
        }
        return 0;
    }, [user.userId, usersGame?.payload, usersGame?.status]);

    const onLeave = useCallback(() => {
        dispatch(fetchLeave({ authToken: authToken, roomId: roomId, currency: room.room?.currencies || "BTRT" }));
    }, [authToken, dispatch, room.room?.currencies, roomId])

    const gameStatus = useMemo(() => { return status }, [status]);

    return {
        user,
        usersGame,
        bet,
        status: gameStatus,
        betValue,
        leave,
        nonValidate,
        room : room.room,
        players,
        playerStatus,
        onLeave,
        onSeat,
        onMakeBet,
        currentBetValue,
        makeBetValue,
        time,
        nonAmountGame,
        onStart: startGame,
        onStop: stopGame,
    };
}


const useRoomForGame = (roomId: number) => {
    const { rooms, state } = useActiveRooms();
    const { room } = useRoomInfo(roomId);

    type GameRoomStatus = "loading" | "enter" | "connect";

    const isOldConnection = useMemo((): GameRoomStatus => {
        if (state === "success" && room !== null) {
            const isActive = rooms.some((acitve) => roomId === acitve.id);
            return isActive ? "connect" : "enter";
        }
        return "loading";
    }, [room, roomId, rooms, state])

    return { status: isOldConnection, room };
}