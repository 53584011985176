
import { useNavigate } from 'react-router';

export const statisticsRoute = '/statistics';
export type StatisticsProps = {};
export const useStatistics = () => {

    const navigate = useNavigate();
    const statistics = (props?: StatisticsProps) => {
        navigate(statisticsRoute, { state: props });
    };

    return statistics;
};