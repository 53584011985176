import { useLocation } from "react-router";
import { useTelegram } from "./telegram-hook"
import { useEffect } from "react";

export const useTelegramBack = (back: () => void) => {
    const { backButton } = useTelegram();
    const location = useLocation();
    
    useEffect(() => {
       if(location.pathname === "/") backButton?.hide();
       else backButton?.show();
    }, [backButton, location.pathname])
  
    useEffect(() => {
      backButton?.on('click', back);
      return () => backButton?.off('click', back);
    }, [backButton, back])

    return { backButton: backButton }
}