import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "..";
import { authApi } from "../../api/auth-api";
import { AxiosError } from "axios";
import { twoFactorApi } from "../../api/two-factor-api";
import { CloudStorage } from "@telegram-apps/sdk-react";
import { appStatusSlice } from "./app-status-slice";

type AuthState = {
    userName: string;
    password: string;
    loading: "idle" | "loading" | "success" | "error";
    isTelegramApp : boolean;
    error: string | null;
    using2FA: boolean;
    using2FAsms: boolean;
    authToken: string;
    errorUserName: null | string;
    errorPassword: null | string;
};

const initialState: AuthState = {
    userName: "",
    password: "",
    isTelegramApp: false,
    loading: "idle",
    error: null,
    using2FA: false,
    using2FAsms: false,
    authToken: "",
    errorUserName: null,
    errorPassword: null
};


export const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        setTelegramAppInfo(state, action: PayloadAction<boolean>){
            state.isTelegramApp = action.payload;
        },
        fetchAuthErrorActionCreater(state, action: PayloadAction<string>) {
            state.error = action.payload;
            state.loading = "error";
        },
        fetchAuthUserActionCreater(state) {
            state.loading = "loading";
        },
        fetchAuthSuccessActionCreater(state) {
            state.loading = "success";
            state.error = null;
        },
        setUserNameActionCreater(state, action: PayloadAction<string>) {
            state.userName = action.payload;
        },
        setPasswordActionCreater(state, action: PayloadAction<string>) {
            state.password = action.payload;
        },
        fetchAuthTwoFA(state, action: PayloadAction<{ using2FA: boolean, using2FAsms: boolean }>) {
            state.using2FA = action.payload.using2FA;
            state.using2FAsms = action.payload.using2FAsms;
        },
        setAuthToken(state, action: PayloadAction<string>) {
            state.authToken = action.payload;
        },
        reset(state) {
            state.userName = "";
            state.password = "";
            state.loading = "idle";
            state.error = null;
            state.using2FA = false;
            state.using2FAsms = false;
            state.authToken = "";
            state.errorUserName = null;
            state.errorPassword = null;
        }
    },
});

// export const loginActionCreater = (userName: string, password: string,) => {
//     return (dispatch: AppDispatch) => {
//         dispatch(authSlice.actions.fetchAuthUserActionCreater());
//         authApi.login(userName, password)
//             .then(responce => {
//                 dispatch(authSlice.actions.fetchAuthTwoFA({ using2FA: responce.using2FA, using2FAsms: responce.using2FAsms }));
//                 twoFactorApi.welcome(userName, password)
//                     .then(responceWelcome => {
//                         if (responce.using2FA || responce.using2FAsms) {
//                             dispatch(authSlice.actions.fetchAuthSuccessActionCreater({ using2FA: responce.using2FA, using2FAsms: responce.using2FAsms }));
//                             console.log(responce.using2FA);
//                         }
//                         else {
//                             dispatch(authSlice.actions.setAuthToken(responceWelcome.accessToken));
//                             dispatch(authSlice.actions.fetchAuthSuccessActionCreater({ using2FA: responce.using2FA, using2FAsms: responce.using2FAsms }));
//                         }
//                     })
//                     .catch((error) => {
//                         const err = error as AxiosError;
//                         const responce: any = err.response?.data;
//                         dispatch(authSlice.actions.fetchAuthErrorActionCreater(responce.message));
//                     })
//             })
//             .catch((error) => {
//                 const err = error as AxiosError;
//                 const responce: any = err.response?.data || "Error";
//                 dispatch(authSlice.actions.fetchAuthErrorActionCreater(responce.message));
//             })
//     }
// }

export const loginActionCreater = (userName: string, password: string, onSuccessfully?: () => void) => {
    return (dispatch: AppDispatch) => {
        dispatch(authSlice.actions.fetchAuthUserActionCreater());
        authApi.login(userName, password)
            .then(responce => {
                twoFactorApi.welcome(userName, password)
                    .then(responceWelcome => {
                        dispatch(authSlice.actions.setAuthToken(responceWelcome.accessToken));
                        dispatch(appStatusSlice.actions.setStatusApp({ status: "idle" }))
                        localStorage.setItem('authToken', responceWelcome.accessToken);
                        if(onSuccessfully) onSuccessfully();
                        dispatch(authSlice.actions.reset());
                        dispatch(authSlice.actions.fetchAuthSuccessActionCreater());
                    })
                    .catch((error) => {
                        dispatch(authSlice.actions.fetchAuthErrorActionCreater(error.response.data.message));
                    })
            })
            .catch((error) => {
                dispatch(authSlice.actions.fetchAuthErrorActionCreater(error.response.data.message));
            })
    }
}

export type UserTelegram = {
    auth_date: number;
    first_name: string;
    last_name?: string;
    hash: string;
    id: number;
    photo_url?: string;
    username?: string;
}

export const loginByTelegramActionCreater = (user: any, cloud: CloudStorage | undefined, isTelegram: boolean, onSuccessfully?: () => void) => {
    return (dispatch: AppDispatch) => {
        dispatch(authSlice.actions.fetchAuthUserActionCreater());
        authApi.authByTelegram(user)
            .then(responceWelcome => {
                if(isTelegram && cloud !== undefined){
                    cloud.set('authToken',responceWelcome.accessToken);
                }
                localStorage.setItem('authToken',responceWelcome.accessToken);
                if(onSuccessfully !== undefined) onSuccessfully();
                dispatch(authSlice.actions.reset());
                dispatch(authSlice.actions.fetchAuthSuccessActionCreater());
            })
            .catch((error) => {
                const err = error as AxiosError;
                const responce: any = err.response?.data || "Error";
                dispatch(authSlice.actions.fetchAuthErrorActionCreater(responce.message));
            })
    }
}

export const loginByGoogleActionCreater = (onSuccessfully?: () => void) => {
    return (dispatch: AppDispatch) => {
        dispatch(authSlice.actions.fetchAuthUserActionCreater());
        authApi.authByGoogle()
            .then(responceWelcome => {
                localStorage.setItem("authToken", responceWelcome.accessToken);
                if(onSuccessfully) onSuccessfully();
                dispatch(authSlice.actions.reset());
                dispatch(authSlice.actions.fetchAuthSuccessActionCreater());
            })
            .catch((error) => {
                const err = error as AxiosError;
                const responce: any = err.response?.data;
                dispatch(authSlice.actions.fetchAuthErrorActionCreater(responce.message));
            })
    }
}

export default authSlice.reducer;
