import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
});

export const registrationApi = {
    registration(userName: string, password: string, confirmPassword: string, referralCode: string | null){
         return instance.post('/auth/signup',{
            userName: userName,
            password: password,
            confirmPassword: confirmPassword,
            referralCode: referralCode,
         })
         .then(responce => responce.data);
    }
}

