import { useQuery } from "@tanstack/react-query";
import { WalletResponseDepositMinAmount } from "../../api/wallet/dto/response";
import { WalletGetDepositMinAmount } from "../../api/wallet/dto/request";
import { queryKeys } from "../queryKeys";
import { apiGetMinAmountOfDeposit } from "./lib/apiGetMinAmountOfDeposit";

export const useGetMinAmountOfDeposit = ({
  authToken,
  currency_from,
  enable = true,
}: {
  authToken: string;
  currency_from: string;
  enable?: boolean;
}) => {
  const { data, isError, isFetched, isLoading, isPending, error } = useQuery<
    WalletResponseDepositMinAmount,
    WalletGetDepositMinAmount
  >({
    queryKey: queryKeys.wallet.depositMinAmount(currency_from),
    queryFn: async () => apiGetMinAmountOfDeposit({ authToken, currency_from }),
    enabled: enable,
  });

  return {
    data,
    isError,
    isFetched,
    isLoading,
    isPending,
    error,
  };
};
