import { useQuery } from "@tanstack/react-query";
import { Currency } from "../../api/wallet/dto/common";
import { WalletResponseFee } from "../../api/wallet/dto/response";
import { WalletGetFee } from "../../api/wallet/dto/request";
import { queryKeys } from "../queryKeys";
import { apiGetFeeWithdraw } from "./lib/apiGetFeeWithdraw";

type Props = {
  authToken: string;
  amount: string;
  currency: Currency;
  enable?: boolean;
};

export const useGetFeeWithdraw = ({
  authToken,
  amount,
  currency,
  enable = true,
}: Props) => {
  const { data, isError, isFetched, isPending, error, isLoading } = useQuery<
    WalletResponseFee,
    WalletGetFee
  >({
    queryKey: queryKeys.wallet.fee(currency, amount),
    queryFn: async () => apiGetFeeWithdraw(amount, currency, authToken),
    enabled: enable && currency && !!amount,
  });

  return {
    data,
    isError,
    isFetched,
    isPending,
    error,
    isLoading,
  };
};
