
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { SitAndGoProps } from './sit-and-go.props';
import { SitAndGoView } from './sit-and-go.view';

import addPlayer from "../../../../ui-kit/assets/add-player.svg";
import { useSitAndGoBtrtRooms } from '../../rooms/sit-and-go-btrt-rooms/sit-and-go-btrt-rooms.props';
import { useParams } from 'react-router';
import { useChart } from '../../../../hooks/chart-hook';
import { useGameTimer } from '../../../../hooks/game-timer-hook';
import { useAppDispatch } from '../../../../hooks/use-typed-selector';
import { gameSlice, stopGameListening } from '../../../../store/reducers/sit-and-go/game-slice';
import { getTimeFromRoomType } from '../../../../data/room-info';
import { FinishPayloadType } from '../../../../api/sit-go/game-api';
import { useSitAndGoRooms } from '../../rooms/sit-and-go-rooms/sit-and-go-rooms.props';
import { useTelegramBack } from '../../../../hooks/telegram-button';
import { useGame } from '../../../../hooks/games/game.hook';

export const SitAndGo: FC<SitAndGoProps> = memo(() => {


  const roomId = Number(useParams().roomId);
  const currency = useParams().currency;

  const { data, stop, start, status } = useChart();
  const { onLeave, ...sitAndGo } = useGame(roomId);
  const timerGame = useGameTimer({
    status: sitAndGo.usersGame?.status,
    difference: sitAndGo.time,
    roomInfo: sitAndGo.room
  });

  const dispatch = useAppDispatch();

  const maxPlayers = sitAndGo.room?.all || 0;

  const players = useMemo(() => {
    const arr: { name: string, picture: string; size: number }[] = sitAndGo.players
      .filter((item) => item.role === "PLAYER")
      .map(player => ({
        ...{
          name: player.playerName || "",
          picture: player.picture,
          size: 40
        }
      }));
    while (arr.length < maxPlayers) {
      arr.push({
        name: "",
        picture: addPlayer,
        size: 20,
      });
    }
    return arr;
  }, [maxPlayers, sitAndGo.players])

  const rooms = useSitAndGoBtrtRooms();
  const onBTRTLobby = useCallback(() => {
    rooms({});
  }, [rooms]);

  const stngLobby = useSitAndGoRooms();
  const OnSTNGLobby = useCallback(() => {
    stngLobby({});
  }, [stngLobby])

  const backViewer = useCallback(() => {
    if (sitAndGo.playerStatus === "VIEWER") onLeave();
  }, [onLeave, sitAndGo.playerStatus])


  const onLobby = useCallback(() => {
    backViewer();
    if (currency === "BTRT")
      onBTRTLobby();
    else
      OnSTNGLobby();
    dispatch(gameSlice.actions.dataClear());
  }, [backViewer, currency, onBTRTLobby, OnSTNGLobby, dispatch])

  const backPlayer = useCallback(() => {
    onLeave();
    onLobby();
  }, [onLeave, onLobby])

  useEffect(() => {
    if (sitAndGo.usersGame?.status === "ENDED") {
      dispatch(stopGameListening());
      dispatch(stop());
    }
  }, [dispatch, sitAndGo.usersGame?.status, stop]);

  const onBack = useCallback(() => {
    onLobby();
  }, [onLobby])
  useTelegramBack(onBack);

  const userIsSeat = sitAndGo.playerStatus === "PLAYER" ? true : false;

  const setBetInput = useCallback((value: string) => {
    const regex = /^(\d+\.?\d*)$|^(\d*\.?\d+)$/;
    if (value === "" || regex.test(value)) {
      dispatch(gameSlice.actions.setBetValue(value));
    }
  }, [dispatch]);

  const timeRoom = getTimeFromRoomType.get(sitAndGo.room?.time || "FIFTEEN") || "5min";
  const deposite = `${sitAndGo.room?.amount || 0}${sitAndGo.room?.currencies === "BTRT" ? " BTRT" : " USDT"}`;

  const [validatePopup, setValidatePopup] = useState<boolean>(false);
  useEffect(() => {
    if (sitAndGo.nonValidate) {
      setValidatePopup(sitAndGo.nonValidate);
    }
  }, [sitAndGo.nonValidate]);
  const onCloseValidate = useCallback(() => {
    setValidatePopup(false);
    dispatch(gameSlice.actions.clearValidate());
  }, [dispatch]);

  const [prizePoolPopup, setPrizePoolPopup] = useState<boolean>(false);
  const onPrizePoolPopup = useCallback(() => {
    setPrizePoolPopup(true);
  }, [])
  const onClosePrizePool = useCallback(() => {
    setPrizePoolPopup(false);
  }, []);

  const [playersBetPopup, setPlayersBetPopup] = useState<boolean>(false);
  const onPlayersBetPopup = useCallback(() => {
    setPlayersBetPopup(true);
  }, [])
  const closePlayersBetPopup = useCallback(() => {
    setPlayersBetPopup(false);
  }, []);

  const [amountGamePopup, setAmountGamePopup] = useState<boolean>(false);
  useEffect(() => {
    if (sitAndGo.nonAmountGame) setAmountGamePopup(true);
  }, [sitAndGo.nonAmountGame])
  const closeAmountGamePopup = useCallback(() => {
    setAmountGamePopup(false);
    dispatch(gameSlice.actions.clearAmountGame());
  }, [dispatch])

  const makeBetValue = useMemo(() => sitAndGo.currentBetValue === 0 ? sitAndGo.makeBetValue : sitAndGo.currentBetValue, [sitAndGo.currentBetValue, sitAndGo.makeBetValue]);

  const playersBets = useMemo(() => sitAndGo.players.filter((item) => item.role === "PLAYER"), [sitAndGo.players]).map((item) => item.userId === sitAndGo.user.userId ? { ...item, playerName: "You" } : item);

  const onReconnect = useCallback(() => {
    stop();
    sitAndGo.onStart(true);
    start()
  }, [sitAndGo, start, stop])

  return (
    <SitAndGoView
      timestamp={sitAndGo.usersGame?.timestamp || ""}
      end={sitAndGo.usersGame?.payload as FinishPayloadType}
      prizePool={sitAndGo.room?.prizePool.data || []}
      deposite={deposite}
      timeRoom={timeRoom}
      userIsSeat={userIsSeat}
      chartData={data}
      players={players}
      status={sitAndGo.usersGame?.status || "PADDING"}
      onLobby={onLobby}
      onLeave={backPlayer}
      onSeat={sitAndGo.onSeat}
      onMakeBet={sitAndGo.onMakeBet}
      maxPlayers={maxPlayers}
      timer={timerGame.time}
      setInputValue={setBetInput}
      inputValue={sitAndGo.betValue}
      isMakeBet={makeBetValue > 0 ? true : false}
      makeBetValue={makeBetValue}
      currencies={sitAndGo.room?.currencies || "BTRT"}
      validatePopup={validatePopup}
      closeValidatePopup={onCloseValidate}
      prizePoolPopup={prizePoolPopup}
      onPrizePoolPopup={onPrizePoolPopup}
      closePrizePoolPopup={onClosePrizePool}
      playersBetPopup={playersBetPopup}
      onPlayersBetPopup={onPlayersBetPopup}
      closePlayersBetPopup={closePlayersBetPopup}
      playersBets={playersBets}
      amountGamePopup={amountGamePopup}
      closeAmountGamePopup={closeAmountGamePopup}
      onReconnect={onReconnect}
      statusChart={status}
    />
  );
});
