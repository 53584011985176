import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
});

export const VerifySecurityApi = {
    verifyApp(userName: string, code: string, authToken: string) {
        return instance.post('/m2fa/verifym2fa', null, {
            headers: {'Authorization' : `Bearer ${authToken}`},
            params: {
                code: code,
                userName: userName
              }
        })
            .then(responce => responce.data);
    },
    verifySms(userName: string, code: string) {
        return instance.post('/m2fa-sms/verifym2fa', null, {
            params: {
                code: code,
                userName: userName
              }
        })
            .then(responce => responce.data);
    },
    verifyEmail(userName: string, code: string) {
        return instance.post('/m2fa-email/verifym2fa', null, {
            params: {
                code: code,
                userName: userName
              }
        })
            .then(responce => responce.data);
    },
    sendSms(userName: string, phone: string) {
        return instance.post('/m2fa-sms/send-code-sms', null, {
                params: {
                    phone: phone,
                    userName: userName
                }
            })
            .then(responce => responce.data);
    },
    sendEmail(userName: string, email: string) {
        return instance.post('/m2fa-email/send-code-email', null, {
            params: {
                email: email,
                userName: userName
            }
        })
            .then(responce => responce.data);
    },
}