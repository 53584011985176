import { FC, memo, useCallback } from "react";
import { WalletProps } from "./wallet.props";
import { WalletView } from "./wallet.view";
import { usePersonalAccount } from "../personal-account/personal-account.props";
import { useTelegramBack } from "../../../hooks/telegram-button";

export const Wallet: FC<WalletProps> = memo(() => {
  const personal = usePersonalAccount();
  const onBack = useCallback(() => {
    personal({});
  }, [personal]);

  useTelegramBack(onBack);
  return <WalletView />;
});
