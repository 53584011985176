import axios from "axios"

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
});


export const swiperApi = {
    tapSwiper(authToken: string) {
        return instance.post('/swipper/swap', null, {
            headers: { 'Authorization': `Bearer ${authToken}` }
        })
            .then((response) => {
                return response.data;
            })
    },
    intervalTapSwiper(authToken: string) {
        return instance.post('/swipper/tap', null, {
            headers: { 'Authorization': `Bearer ${authToken}` }
        })
            .then((response) => {
                return response.data;
            })
    },
    initSwiper(authToken: string) {
        return instance.get('/swipper/init', {
            headers: { 'Authorization': `Bearer ${authToken}` }
        })
            .then((response) => {
                return response.data;
            })
    },
    getAllTasks(authToken: string) {
        return instance.get('/daily-tasks/all', {
            headers: { 'Authorization': `Bearer ${authToken}` }
        })
            .then((response) => {
                return response.data;
            })
    },
    getMainTasks(authToken: string) {
        return instance.get('/daily-tasks/main', {
            headers: { 'Authorization': `Bearer ${authToken}` }
        })
            .then((response) => {
                return response.data;
            })
    },
    checkTask(gameTaskId: number, authToken: string) {
        return instance.get('/daily-tasks/checked', {
            params: {
                gameTaskId: gameTaskId
            },
            headers: { 'Authorization': `Bearer ${authToken}` }
        })
            .then((response) => {
                return response.data;
            })
    },
    getLeaderboard(authToken: string, filter: "PLAYERS" | "FARMERS") {
        return instance.get('/rate/board', {
            params: {
                filter: filter,
            },
            headers: { 'Authorization': `Bearer ${authToken}` },
        }).then((responce) => {
            return responce.data.content;
        })
    }
}