import { FC, HTMLAttributes, ReactNode, memo } from "react";
import { ContentButtonStyled } from "./button.styled";
import { Pressable } from "../pressable";

export type ButtonProps = {
  variant?: "primary" | "secondary" | "foreground";
  width?: number | string;
  disable?: boolean;
  height?: number | string;
  padding?: number | [number?, number?, number?, number?];
  children?: ReactNode;
  radius?: number;
  onClick: () => void;
} & HTMLAttributes<HTMLDivElement>;

export const Button: FC<ButtonProps> = memo(
  ({
    variant = "primary",
    radius = 20,
    disable = false,
    width,
    height,
    padding,
    children,
    onClick,
    ...rest
  }) => {
    return (
      <Pressable style={{ width: width }} disabled={disable} onClick={onClick}>
        <ContentButtonStyled
          radius={radius}
          variant={variant}
          width={width}
          height={height}
          padding={padding}
          {...rest}
        >
          {children}
        </ContentButtonStyled>
      </Pressable>
    );
  }
);
