import { styled } from "styled-components";

export const WrapperSegment = styled.div`
    width: 245px;
    height: 245px;
    position: absolute;
    transform: rotate(15deg);
    div{
      width: 245px;
      height: 245px;
      position: absolute;
      display: flex;
      
      svg{
        transform: rotate(29deg);
        position: absolute;
        left: 50%;
      }
    }
    
    div:nth-child(1) {
      transform: rotate(0deg);
    }
    div:nth-child(2) {
      transform: rotate(45deg);
    }
    div:nth-child(3) {
      transform: rotate(90deg);
    }
    div:nth-child(4) {
      transform: rotate(135deg);
    }
    div:nth-child(5) {
      transform: rotate(180deg);
    }
    div:nth-child(6) {
      transform: rotate(225deg);
    }
    div:nth-child(7) {
      transform: rotate(270deg);
    }
    div:nth-child(8) {
      transform: rotate(315deg);
    }
`

export const WrapperFortuneWhile = styled.div`
    width: 245px;
    height: 245px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`

export const ArrowWrapper = styled.div`
  position: absolute;
  z-index: 100;
  display: flex;
  width: 100%;
  top: -10px;
  height: auto;
  justify-content: center;
  align-items: center;
`