import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../../..";
import { emailSecurityApi } from "../../../../api/security/email-security-api";
import { getUserInfoActionCreater } from "../../user-slice";
import { appStatusSlice } from "../../app-status-slice";

export type EmailSecurityState = {
    email: string;
    code: string;
    emailTimer: number;
    error: string | null;
    errorVerify: string | null;
    errorEmail: string | null;
    intervalEmail: NodeJS.Timer | null;
    isGotCode: boolean;
    status: 'sended' | 'wait';
    isVerify: "idle" | "loading" | "success" | "error";
};

const initialState: EmailSecurityState = {
    email: '',
    intervalEmail: null,
    code: '',
    emailTimer: 0, 
    status: 'wait',
    errorVerify: null,
    errorEmail: null,
    isGotCode: false,
    error: null,
    isVerify: 'idle'
};


export const emailSecuritySlice = createSlice({
    name: "email-security",
    initialState: initialState,
    reducers: {
        fetchErrorActionCreater(state, action: PayloadAction<string>) {
            state.error = action.payload;
        },
        setVerifyErrorActionCreater(state, action: PayloadAction<string | null>) {
            state.errorVerify = action.payload;
        },
        setErrorEmailActionCreater(state, action: PayloadAction<string | null>) {
            state.errorEmail = action.payload;
        },
        setEmailActionCreater(state, action: PayloadAction<string>) {
            state.email = action.payload;
        },
        setIntervalEmail(state, action: PayloadAction<NodeJS.Timer | null>) {
            state.intervalEmail = action.payload;
        },
        setEmailTimerActionCreater(state, action: PayloadAction<{ time: number,interval: NodeJS.Timer | null}>) {
            if(action.payload.interval ===  null) {
                state.emailTimer = action.payload.time;
                return;
            }
            if(action.payload.time !== 0){
                state.emailTimer = action.payload.time;
            } else {
                state.emailTimer = 0;
                clearInterval(action.payload.interval);
                state.status = 'wait';
            };
        },
        setCodeActionCreater(state, action: PayloadAction<string>) {
            state.code = action.payload;
        },
        setStatus(state, action: PayloadAction<'wait' | 'sended'>) {
            state.status = action.payload;
        },
        setIsVerifyActionCreater(state, action: PayloadAction<"idle" | "loading" | "success" | "error">) {
            state.isVerify = action.payload;
        },
        setIsGotTrue(state) {
            state.isGotCode = true;
        },
        reset(state) {
            if(state.intervalEmail !==  null){
                clearInterval(state.intervalEmail);
            }
            state.email = '';
            state.code = '';
            state.emailTimer = 0;
            state.errorVerify = null;
            state.errorEmail = null;
            state.isGotCode = false;
            state.status = 'wait';
            state.error = null;
        }
    }
});

export const sendCodeOnEmailCodeActionCreater = (
    userName: string,
    emailTimer: number, 
    email: string, 
    authToken: string, 
    status: 'sended' | 'wait'
) => {
    return (dispatch: AppDispatch) => {
        if(emailTimer !== 0 || status === 'sended') return;  
        dispatch(emailSecuritySlice.actions.setStatus('sended'));   
        
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            dispatch(emailSecuritySlice.actions.setStatus('wait')); 
            dispatch(emailSecuritySlice.actions.setErrorEmailActionCreater("Email entered incorrectly"));
            return;
        }
        emailSecurityApi.sendCodeOnEmail(userName, email, authToken)
            .then(responce => {
                dispatch(emailSecuritySlice.actions.setErrorEmailActionCreater(null));
                dispatch(emailSecuritySlice.actions.setVerifyErrorActionCreater(null));

                dispatch(emailSecuritySlice.actions.setIsGotTrue());  

                dispatch(emailSecuritySlice.actions.setEmailTimerActionCreater({time: 30, interval: null}));   

                let secound: number = 30;
                let intervalId: NodeJS.Timeout = setInterval(() => {
                if (secound > 0) { 
                    dispatch(emailSecuritySlice.actions.setEmailTimerActionCreater({time: --secound, interval: intervalId}))} else { clearInterval(intervalId); }
                }, 1000);
                dispatch(emailSecuritySlice.actions.setIntervalEmail(intervalId));
            })
            .catch((error) => {
                dispatch(emailSecuritySlice.actions.setErrorEmailActionCreater("Email entered incorrectly"));
                dispatch(emailSecuritySlice.actions.setStatus('wait')); 
                if(error?.responce.status === 401){
                    dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                }
            })
    }
}

export const verifySendedCodeOnEmailActionCreater = (userName: string, email: string, code: string, authToken: string, onSuccessfully?: () => void) => {
    return (dispatch: AppDispatch) => {
        dispatch(emailSecuritySlice.actions.setIsVerifyActionCreater('loading'));

        emailSecurityApi.verifySendedCodeOnEmail(userName, code, authToken)
            .then(responce => {
                if(responce.verified){
                    emailSecurityApi.updateEmail(email, authToken)
                        .then(responce => {
                            dispatch(getUserInfoActionCreater(authToken));
                            dispatch(emailSecuritySlice.actions.reset());
                            if(onSuccessfully !== undefined) onSuccessfully();
                            dispatch(emailSecuritySlice.actions.setIsVerifyActionCreater('success'));
                        })
                        .catch((err) => {
                            dispatch(emailSecuritySlice.actions.setErrorEmailActionCreater('Email is already in use'));
                            dispatch(emailSecuritySlice.actions.setVerifyErrorActionCreater(null));
                            dispatch(emailSecuritySlice.actions.setIsVerifyActionCreater('error'));
                            if(err?.responce.status === 401){
                                dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                            }
                        })
                } else {
                    dispatch(emailSecuritySlice.actions.setIsVerifyActionCreater('error'));
                    dispatch(emailSecuritySlice.actions.setVerifyErrorActionCreater('The code doesn’t work. Try again'));
                }
            })
            .catch((err) => {
                dispatch(emailSecuritySlice.actions.setVerifyErrorActionCreater('The code doesn’t work. Try again'));
                if(err?.responce.status === 401){
                    dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                }
            })
    }
}

export const disableTwoFactorEmail = (authToken: string) => {
    return (dispatch: AppDispatch) => {
        emailSecurityApi.disableEmail(authToken)
            .then(responce => {
                dispatch(getUserInfoActionCreater(authToken));
                dispatch(emailSecuritySlice.actions.reset());
            })
            .catch((err) => {
                dispatch(emailSecuritySlice.actions.fetchErrorActionCreater('The data has not been updated'));
                if(err?.responce.status === 401){
                    dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                }
            })
    }
}

export default emailSecuritySlice.reducer;