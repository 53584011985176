import { FC, useRef, HTMLAttributes, memo } from "react";
import { Row } from "../row";
import { StyledInput } from "./pin-code-input.styles";
import { theme } from "../../themes/theme";
import { useMediaQuery } from "react-responsive";

type PinCodeInputProps = {
    setDigits:(value: [string,string,string,string,string,string]) => void;
    digits: [string,string,string,string,string,string];
    isError: boolean;
} & HTMLAttributes<HTMLInputElement>;
  
function updateArray( array: [string,string,string,string,string,string], index: number, newValue: string): [string,string,string,string,string,string] {
    const copy : [string,string,string,string,string,string] = [...array];
    copy[index] = newValue;
    return copy;
}

export const PinCodeInput: FC<PinCodeInputProps> = memo(({digits, setDigits, isError,...rest }) => {
    const inputRefs = useRef<(HTMLInputElement | null)[]>(new Array(digits.length));

    const handleChange = (index: number, newValue: string) => {
        const oldDigit = digits[index];
        const newDigit = newValue.trim().replace(oldDigit, '');

        if (newDigit < '0' || newDigit > '9') {
            return;
        }
        
        handleKeyDown(index, 'click');

        setDigits(updateArray(digits, index, newDigit));
        const inputs = inputRefs.current;
        if (index < inputs.length - 1 && inputs[index + 1]) {
            inputs[index + 1]?.focus();
        } else if (inputs[index]) {
            inputs[index]?.blur();
        }
    };

    const handleKeyDown = (index: number, keyPressed: string) => {
        if (keyPressed !== 'Backspace') return;
        if (digits[index]) {
            setDigits(updateArray(digits, index, ''));
        }else if (index > 0 && inputRefs.current[index - 1]) {
            inputRefs.current[index - 1]?.focus();
        }
    };

    const isPhone = useMediaQuery({maxWidth: theme.toMobileSize});

    return (
        <>
        <Row style={isPhone? {gap:5} : {gap: 16}}>
            {digits.map((digit, index) => (
                <StyledInput
                isError={isError}
                font={theme.fonts.b}
                type='number'
                key={index}
                value={digit}
                onChange={(event) => handleChange(index, event.target.value)}
                onKeyDown={(event) => handleKeyDown(index, event.nativeEvent.key)}
                ref={(ref) => (inputRefs.current[index] = ref)}
                {...rest}/>
                )
            )}
        </Row>
        </>
        
    )
});
