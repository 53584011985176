import { useCallback } from "react";
import { useAppDispatch, useTypedSelector } from "../use-typed-selector";
import { claimMainTask, claimTask, getAllTasksActionCreater, getMainTask, swiperTasksSlice } from "../../store/reducers/swiper/swiper-tasks-slice";
import { useUser } from "../user-hook";

export const useTask = () => {
    const { authToken } = useUser();
    const { dailyTasks, mainTask, btrt } = useTypedSelector(state => state.swiperTasks);

    const dispatch = useAppDispatch();

    const getMainTasks = useCallback(() => {
        dispatch(getMainTask({ authToken: authToken }));
    }, [authToken, dispatch])

    const getTasks = useCallback(() => {
        dispatch(getAllTasksActionCreater(authToken));
    }, [authToken, dispatch])

    const checkTask = useCallback((url: string, type: "SOCIAL" | "ACHIEVEMENTS") => {
        if (type === "SOCIAL")
            window.open(url)
    }, []);

    const claimSimpleTask = useCallback((id: number) => {
        dispatch(claimMainTask({ authToken: authToken, id: id }));
    }, [authToken, dispatch]);

    const claimSectionTask = useCallback((id: number) => {
        dispatch(claimTask({ authToken: authToken, id: id }))
    }, [authToken, dispatch])

    const resetCheck = useCallback(() => {
        dispatch(swiperTasksSlice.actions.resetCheckBalance());
    }, [dispatch])

    return {
        tasks: dailyTasks,
        checkTask: checkTask,
        mainTask: mainTask,
        getMainTasks: getMainTasks,
        getTasks: getTasks,
        claimSimpleTask: claimSimpleTask,
        claimSectionTask: claimSectionTask,
        btrt: btrt,
        resetCheck: resetCheck,
    }
}