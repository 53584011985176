import { FC, memo } from "react";
import { StyledLineText, StyledLineTextWrapper, StyledText } from "./line-text.styles";

export const LineText: FC = memo(() =>
    <StyledLineTextWrapper>
        <StyledLineText />
        <StyledText>OR</StyledText>
        <StyledLineText />
    </StyledLineTextWrapper>
);
