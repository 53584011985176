import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../../..";
import { passwordSecurityApi } from "../../../../api/security/password-security-api";
import { appStatusSlice } from "../../app-status-slice";

export type PasswordSecurityState = {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
    errorConfirmPassword: string | null;
    errorCurrentPassword: string | null;
    loading: "idle" | "loading" | "success" | "error";
    error: string | null;
};

const initialState: PasswordSecurityState = {
    oldPassword: '',
    errorConfirmPassword: null,
    newPassword: '',
    errorCurrentPassword: null,
    confirmPassword: '', 
    loading: "idle",
    error: null
};


export const passwordSecuritySlice = createSlice({
    name: "password-security",
    initialState: initialState,
    reducers: {
        fetchErrorActionCreater(state, action: PayloadAction<string>) {
            state.error = action.payload;
            state.loading = "error";
        },
        fetchLoadingActionCreater(state) {
            state.loading = 'loading';
        },
        setOldPasswordActionCreater(state, action: PayloadAction<string>) {
            state.oldPassword = action.payload;
        },
        setNewPasswordActionCreater(state, action: PayloadAction<string>) {
            state.newPassword = action.payload;
        },
        setConfirmPasswordActionCreater(state, action: PayloadAction<string>) {
            state.confirmPassword = action.payload;
        },
        fetchSuccessActionCreater(state) {
            state.loading = "success";
            state.error = null;
        },
        fetchErrorEqualsPasswordsActionCreater(state, action: PayloadAction<string | null>) {
            state.errorConfirmPassword = action.payload;
        },
        fetchErrorCurrentPasswordActionCreater(state, action: PayloadAction<string | null>) {
            state.errorCurrentPassword = action.payload;
        },
        reset(state) {
            state.oldPassword = '';
            state.newPassword = '';
            state.confirmPassword = ''; 
            state.errorConfirmPassword = null;
            state.loading = "idle";
            state.errorCurrentPassword = null;
            state.error = null;
        }
    }
});

export const changePasswordActionCreater = (oldPassword: string, newPassword: string, confirmPassword: string, authToken: string, onSuccessfully: () => void) => {
    return (dispatch: AppDispatch) => {
        if(newPassword !== confirmPassword){
            dispatch(passwordSecuritySlice.actions.fetchErrorEqualsPasswordsActionCreater("Passwords don't match"));
            return;
        }
        dispatch(passwordSecuritySlice.actions.fetchLoadingActionCreater());

        dispatch(passwordSecuritySlice.actions.fetchErrorEqualsPasswordsActionCreater(null));

        passwordSecurityApi.changePassword(oldPassword, newPassword,confirmPassword,authToken)
            .then(responce => {
                dispatch(passwordSecuritySlice.actions.fetchSuccessActionCreater());
                onSuccessfully();
                dispatch(passwordSecuritySlice.actions.reset());
            })
            .catch((error) => {
                dispatch(passwordSecuritySlice.actions.fetchErrorCurrentPasswordActionCreater('Old password not equal'));
                dispatch(passwordSecuritySlice.actions.fetchErrorActionCreater('Error'));
                if(error?.responce.status === 401){
                    dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                }
            })
    }
}
export const addPasswordActionCreater = (newPassword: string, confirmPassword: string, authToken: string,onSuccessfully: () => void) => {
    return (dispatch: AppDispatch) => {
        if(newPassword !== confirmPassword){
            dispatch(passwordSecuritySlice.actions.fetchErrorEqualsPasswordsActionCreater("Passwords don't match"));
            return;
        }
        dispatch(passwordSecuritySlice.actions.fetchLoadingActionCreater());

        dispatch(passwordSecuritySlice.actions.fetchErrorEqualsPasswordsActionCreater(null));
        
        passwordSecurityApi.addPassword(newPassword,confirmPassword,authToken)
            .then(responce => {
                dispatch(passwordSecuritySlice.actions.fetchSuccessActionCreater());
                onSuccessfully();
                dispatch(passwordSecuritySlice.actions.reset());
            })
            .catch((error) => {
                dispatch(passwordSecuritySlice.actions.fetchErrorActionCreater('Error'));
                if(error?.responce.status === 401){
                    dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                }
            })
    }
}

export default passwordSecuritySlice.reducer;