import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../..";
import { appStatusSlice } from "../app-status-slice";
import { userApi } from "../../../api/user-api";
import { updatePlayerNameOfUser } from "../user-slice";

export type LogoInfo = {
    url: string;
    id: number;
}

export type PersonalAccountState = {
    loadingLogos: "idle" | "loading" | "success" | "error";
    logos: LogoInfo[];
    error: string | null;
    errorLoadingLogos: string | null;

    playerName: string;
    errorUpdatePlayerName: string | null;
}

const initialState: PersonalAccountState = {
    loadingLogos: 'loading',
    logos: [],
    error: null,
    errorLoadingLogos: null,

    playerName: '',
    errorUpdatePlayerName: null
}

export const personalAccountSlice = createSlice({
    name: 'personal-account',
    initialState: initialState,
    reducers: {
        setLoadingLogos(state, action: PayloadAction<"idle" | "loading" | "success" | "error">) {
            state.loadingLogos = action.payload;
        },
        setErrorLoadingLogosActionCreater(state, action: PayloadAction<string | null>) {
            state.errorLoadingLogos = action.payload;
        },
        setPlayerNameActionCreater(state, action: PayloadAction<string>) {
            state.playerName = action.payload;
        },
        setErrorUpdatePlayerNameActionCreater(state, action: PayloadAction<string | null>) {
            state.errorUpdatePlayerName = action.payload;
        },
        setLogos(state, action: PayloadAction<LogoInfo[]>) {
            state.logos = action.payload;
        },
        reset(state) {
            state.loadingLogos = 'loading';
            state.error = null;
            state.errorLoadingLogos = null;
        }
    }
})

export const getLogosActionCreater = (authToken: string) => {
    return (dispatch: AppDispatch) => {
        dispatch(personalAccountSlice.actions.setLoadingLogos('loading'));
        userApi.getLogos(authToken)
            .then(responce => {
                dispatch(personalAccountSlice.actions.setLogos(responce));
                dispatch(personalAccountSlice.actions.setLoadingLogos('success'));
            })
            .catch((err) => {
                dispatch(personalAccountSlice.actions.setLoadingLogos('error'));
                if (err.response?.status === 401) {
                    dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }));
                }
            })
    }
}

export const updatePlayerNameActionCreater = (newPlayerName: string, authToken: string,onSuccess?: () => void) => {
    return (dispatch: AppDispatch) => {
        if(newPlayerName.length < 1 || newPlayerName.length > 32){
            return;
        }

        userApi.updatePlayerName(newPlayerName,authToken)
            .then(responce => {
                dispatch(updatePlayerNameOfUser({playerName: newPlayerName, authToken: authToken}));
                if(onSuccess !== undefined) onSuccess();
            })
            .catch((err) => {
                personalAccountSlice.actions.setErrorUpdatePlayerNameActionCreater('Player\'s Name has not been changed');
                if(err?.responce.status === 401){
                    dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                }
            })
    }
}

export default personalAccountSlice.reducer;