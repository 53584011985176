import styled from "styled-components";
import { themes } from "../../../../themes/theme";

export const SquareButton = styled.div<{ hidden: boolean }>`
    display: block;
    border-radius: 5px;
    width: 50px;
    height: 50px;
    background-color: ${({ hidden }) => hidden ? themes.colors.primary : '#19192E'};;
`;

export const Blur = styled.div`
position: absolute;
width: 100%;
height: 615px;
top: 67px;
z-index: -1;
background: linear-gradient(0deg, rgba(183, 94, 213, 0.1) 53.97%, rgba(34, 255, 54, 0.1) 150.62%);
filter: blur(45px);
`

export const MobilerButton = styled.div<{ select: boolean }>`
position: relative;
cursor: pointer;
width: 51px;
height: 42px;
border-radius: 15px;
background-color: ${(({ select }) => select ? `${themes.colors.secondary}` : `${themes.colors.background}`)};;
&:before {
  content: "";
  position: absolute;
  inset: 0px;
  border-radius: 15px;
  border: 2px solid transparent;
  background: linear-gradient(180deg, #835C7C, #240203) border-box;
  mask: 
  linear-gradient(#fff 0 0) padding-box, 
  linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude;
    opacity: 0.5;
}
`
export const VerticalLine = styled.div`
  height: 33px;
  width: 1px;
  background-color: ${themes.colors.gray};
`

export const RoomButtonStyled = styled.div`
    width: 130px;
    height: 130px;
    background-color: #19192E;
    border-radius: 10px;
    background-color: ${'#19192E'};
`;

export const GridContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  column-gap: 0.5rem;
  justify-items: center;
  align-items: center;
  margin-bottom: 30px;
  row-gap: 10px;
  grid-template-columns: 1fr;
`;