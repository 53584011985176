import { FC, memo, useCallback, useState } from "react";
import { Surface } from "../surface";
import { Row } from "../row";
import { Spacing } from "../spacing";
import { Column } from "../column";
import { Image } from "../image";
import { Text } from '../text';
import { themes } from "../themes/theme";
import open from '../assets/open-task.svg';
import { CustomGrid, SubTask as SubTaskComponent } from "./task.styled";
import completed from '../assets/completed.svg';
import { SubTask } from "../../store/reducers/swiper/swiper-tasks-slice";
import { Button } from "../button";
import referal from "../assets/referal.svg";
import { Pressable } from "../pressable";

export type TaskProps = {
    icon: string;
    title: string;
    value: number;
    maxValue: number;
    subTask: SubTask[];
    checkTask: (url: string, type: "SOCIAL" | "ACHIEVEMENTS") => void;
    claim: (id: number) => void;
}

export const Task: FC<TaskProps> = memo(({ checkTask, icon, value, title, maxValue, subTask, claim }) => {
    const [active, setActive] = useState<boolean>(false);
    const onClick = useCallback(() => {
        setActive((prev) => !prev);
    }, []);

    return (
        <Column horizontalAlign="center">
            <Surface onClick={onClick} style={{ zIndex: 2 }} padding={[8, 13]}>
                <Row horizontalAlign="space-between" verticalAlign="center" style={{ height: "100%", width: "100%" }}>
                    <Row style={{ width: "100%" }} verticalAlign="center">
                        <Image src={icon} size={35} />
                        <Spacing themeSpace={15} variant="Row" />
                        <Column>
                            <Text themeFont={themes.fonts.h3}>{title}</Text>
                            <Spacing themeSpace={2} variant="Column" />
                            <Text themeColor={themes.colors.secondary} themeFont={themes.fonts.ht2}>{subTask.some((item) => item.completed === false) === true ? `${value}/${maxValue} BTRT` : `${maxValue} BTRT earned`}</Text>
                        </Column>
                        <Image style={{ transform: active ? 'rotateZ(45deg)' : 'rotateZ(0deg)', transition: '0.3s' }} src={open} size={30} />
                    </Row>
                </Row>
            </Surface>
            <CustomGrid style={{ display: active ? "grid" : "none" }}>
                {subTask.map((item) =>
                    <Sub 
                        key={item.id}
                        id={item.id}
                        title={item.title}
                        bonus={item.bonus}
                        isCompleted={item.completed}
                        url={item.url}
                        icon={item.icon}
                        type={item.dailyTaskType}
                        checkTask={checkTask}
                        claim={claim}
                    />
                )}
            </CustomGrid>
        </Column>
    )
})

type SubTaskProps = {
    id: number;
    isCompleted: boolean;
    bonus: number;
    icon: string;
    title: string;
    url: string;
    type: "SOCIAL" | "ACHIEVEMENTS";
    checkTask: (url: string, type: "SOCIAL" | "ACHIEVEMENTS") => void;
    claim: (id: number) => void
}

const Sub: FC<SubTaskProps> = memo(({ icon, isCompleted, title, url, bonus, checkTask, id, type, claim }) => {
    const [state, setState] = useState<boolean>(false);
    const onClick = useCallback(() => {
        setState(true);
        checkTask(url, type)
    }, [checkTask, type, url]);

    const onClaim = useCallback(() => {
        if (state || type === "ACHIEVEMENTS") {
            claim(id);
        }
    }, [claim, id, state, type])
    return (
        <SubTaskComponent>
            <Row horizontalAlign="space-between" verticalAlign="center" style={{ height: "100%", width: "100%" }}>
                <Row style={{ width: "100%" }} verticalAlign="center">
                    <Pressable style={{ width: "100%", zIndex: 10 }} onClick={onClick}>
                        <Row verticalAlign="center">
                            <Image src={type === "ACHIEVEMENTS" ? referal : icon} size={35} />
                            <Spacing themeSpace={15} variant="Row" />
                            <Column>
                                <Text align="left" themeFont={themes.fonts.h3}>{title}</Text>
                                <Spacing themeSpace={2} variant="Column" />
                                <Text themeColor={themes.colors.secondary} themeFont={themes.fonts.ht2}>{isCompleted ? `${bonus} BTRT earned` : `+ ${bonus} BTRT`}</Text>
                            </Column>
                        </Row>
                    </Pressable>
                    {
                        isCompleted ?
                            <Image src={completed} size={16} />
                            : (
                                <Button onClick={onClaim} variant="secondary" width={50} height={30}>
                                    <Text themeColor={themes.colors.background} themeFont={themes.fonts.ht2}>Claim</Text>
                                </Button>
                            )
                    }
                </Row>
            </Row>
        </SubTaskComponent>
    )
});
