import { FC, HtmlHTMLAttributes, memo, useCallback, useEffect, useRef, useState } from "react";
import { MobileSwiperBackground } from "./mobile-swiper.styled";

export type MobileSwiperProps = {
    onSwipe: (coordinates: Coordinates) => void;
} & HtmlHTMLAttributes<HTMLElement>;

export type Coordinates = {
    deltaX: number;
    deltaY: number;
};

export const MobileSwipe: FC<MobileSwiperProps> = memo(({ onSwipe, ...rest }) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [isTouch, setIsTouch] = useState(false);

    const handleTouchStart = useCallback((e: TouchEvent) => {
        if (wrapperRef.current === null || !wrapperRef.current.contains(e.target as Node)) {
            return;
        }

        setStartX(e.touches[0].clientX);
        setStartY(e.touches[0].clientY);
        setIsTouch(true); 
    }, []);

    const handleTouchEnd = useCallback((e: TouchEvent) => {
        if (wrapperRef.current === null || !wrapperRef.current.contains(e.target as Node)) {
            return;
        }

        const endX = e.changedTouches[0].clientX;
        const endY = e.changedTouches[0].clientY;
        const deltaX = endX - startX;
        const deltaY = endY - startY;

        onSwipe({ deltaX, deltaY });
        setIsTouch(false); 
    }, [startX, startY, onSwipe]);

    const handleClick = useCallback((e: MouseEvent) => {
        if (isTouch) {
            e.preventDefault();
            setIsTouch(false); 
        }
    }, [isTouch]);

    useEffect(() => {
        const options = { passive: false }; 

        window.addEventListener("touchstart", handleTouchStart, options);
        window.addEventListener("touchend", handleTouchEnd, options);
        window.addEventListener("click", handleClick, true); 

        return () => {
            window.removeEventListener("touchstart", handleTouchStart);
            window.removeEventListener("touchend", handleTouchEnd);
            window.removeEventListener("click", handleClick, true);
        };
    }, [handleTouchStart, handleTouchEnd, handleClick]);

    return (
        <MobileSwiperBackground ref={wrapperRef} {...rest}>
        </MobileSwiperBackground>
    );
});
