import { FC, ReactNode, memo } from "react";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import './custom-phone-input.css';
import { Row } from "../row/row";
import { Controller } from "react-hook-form";
import { InputContainer } from "../input/input.styles";
import { Column } from "../column";
import { Spacing } from "../spacing";

export type PhoneInputParentProps = {
    control: any;
    name: string;
    errorInput: ReactNode;
    setValue?: (value: string) => void;
    valueInput?: string;
};

export const PhoneInputParent: FC<PhoneInputParentProps> = memo(({ control, name, errorInput, setValue, valueInput }) => {
    const handleInputChange = (value: string) => {
        if (setValue !== undefined) {
            setValue(value);
        }
    };
    return (
        <InputContainer>
            <Row style={{ width: '100%' }} horizontalAlign="center">
                <div className="phone-input">
                    <Controller
                        control={control}
                        name={name}
                        defaultValue={valueInput}
                        rules={{
                            required: true,
                            pattern: {
                                value: /^\d{10,14}$/,
                                message: 'Invalid phone number',
                            },
                        }}
                        render={() => (
                            <PhoneInput
                                buttonClass="custom-button"
                                dropdownStyle={{
                                    background: '#1D1D38',
                                }}
                                inputStyle={{
                                    background: '#1D1D38'
                                }}
                                dropdownClass="custom-dropdown"
                                buttonStyle={{
                                    background: '#1D1D38'
                                }}
                                inputClass="custom-phone-input"
                                country={'ru'}
                                value={valueInput}
                                onChange={handleInputChange}
                            />
                        )}
                    />

                </div>
            </Row>

            {errorInput &&
                <Column>
                    <Spacing themeSpace={5} variant="Column" />
                    {errorInput}
                </Column>
            }
        </InputContainer>
    )
}
);
