import { FC, memo } from "react";
import { Surface } from "../surface";
import { Column } from "../column";
import { Row } from "../row";
import { Text } from "../text";
import { Spacing } from "../spacing";
import { themes } from "../themes/theme";
import { Button } from "../button";
import { Image } from "../image";
import shareImage from '../assets/share.svg';
import { Translate } from "../translate";

export type BenefitLinkBoardProps = {
    onShare: () => void;
    refCode: string | null;
  };
  export const BenefitLinkBoard: FC<BenefitLinkBoardProps> = memo(({refCode, onShare}) => {
    return (
      <Surface radius={10} themeColor='rgba(28, 28, 28,0.6)'>
          <Column padding={9}>
            <Row width={'100%'} horizontalAlign='space-between' padding={[0,5,0, 10]}>
              <Column>
                <Text themeColor={themes.colors.foreground} themeFont={themes.fonts.b}>
                  <span style={{ color: `${themes.colors.secondary}` }}><Translate keyWord="lobby.benefit.part1" /></span> <Translate keyWord="lobby.benefit.part2" />
                </Text>
                <Spacing variant='Column' themeSpace={6} />
                <Text themeColor={themes.colors.gray} themeFont={themes.fonts.ht2}>
                  <Translate keyWord="lobby.benefit.text" />
                </Text>
              </Column>
              <Image src={shareImage} height={41} width={34}/>
            </Row>
            <Spacing variant='Column' themeSpace={10} />
            <Surface borderWidth={1} radius={20} themeColor='transparent' themeColorBorder={themes.colors.gradients.foreground}>
              <Column padding={[5,5,5,12]}>
                <Row verticalAlign='center' horizontalAlign='space-between' width={'100%'}>
                  <Text style={{wordBreak:'break-all'}} themeColor={themes.colors.foreground} themeFont={themes.fonts.ht2}>
                    {`${process.env.REACT_APP_API_TG_REF_LINK}${refCode}`}
                  </Text>
                  <Spacing variant='Row' themeSpace={10}/>
                  <Button onClick={onShare} style={{flexShrink: 0}} width={70} height={25} variant='secondary'>
                    <Text themeColor={themes.colors.background} themeFont={themes.fonts.ht2}>
                      <Translate keyWord="lobby.benefit.button" />
                    </Text>
                  </Button>
                </Row>
              </Column>
            </Surface>
          </Column>
        </Surface>
    );
  });
  