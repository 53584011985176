export const termsAndConditionsTextSpain = [
  {
    header: <>Elegibilidad del Participante</>,
    text: <>
      Al acceder o utilizar los servicios de Bitrate, afirmas que tienes la edad legal de juego en tu jurisdicción, generalmente no menor de 18 años. Bitrate se reserva el derecho de verificar la edad y elegibilidad de los participantes y puede solicitar información adicional para este propósito.
    </>
  },
  {
    header: <>Proceso de Registro</>,
    text: <>
      Para participar en los torneos de Bitrate, los usuarios deben proporcionar información de contacto precisa y actualizada, incluyendo un número de teléfono válido, dirección de correo electrónico o cuenta de Telegram. No proporcionar información precisa puede resultar en la suspensión o terminación de la cuenta.
    </>
  },
  {
    header: <>Reglas del Torneo</>,
    text: <>
      Todos los participantes deben familiarizarse y cumplir con las reglas y normativas descritas en las directrices de los torneos de Bitrate. Al participar, los usuarios aceptan cumplir con estas reglas, que pueden incluir directrices para predicciones, puntuación, plazos y actividades prohibidas.
    </>
  },
  {
    header: <>Premios y Recompensas</>,
    text: <>
      Los ganadores de los torneos de Bitrate recibirán una parte del premio acumulado, con un porcentaje destinado a Bitrate para cubrir costos operativos, Bitrols y desarrollo futuro. La distribución específica de los premios se detallará en la ventana de cada torneo.
    </>
  },
  {
    header: <>Tarifas de Entrada y Pagos</>,
    text: <>
      Bitrate acepta depósitos en criptomonedas para recargar el saldo del jugador. Los usuarios son responsables de cubrir las tarifas de transacción asociadas con sus depósitos y retiros. Al participar, los usuarios reconocen que las transacciones en criptomonedas son irreversibles y que Bitrate no ofrece reembolsos bajo ninguna circunstancia.
      <br/><br/>
      En caso de una solicitud de retiro grande, Bitrate se reserva el derecho de iniciar procedimientos adicionales de verificación, incluyendo, entre otros, los controles de Conozca a Su Cliente (KYC). Como parte de este proceso, los usuarios pueden tener que proporcionar documentación adicional para verificar su identidad y la legitimidad de la transacción. Ten en cuenta que los tiempos de procesamiento para dichos retiros pueden tomar entre 6 y 24 horas, dependiendo del volumen de solicitudes y la complejidad del proceso de verificación. Bitrate hará esfuerzos razonables para agilizar estos procedimientos y minimizar cualquier inconveniente para el usuario.
    </>
  },
  {
    header: <>Privacidad y Seguridad de los Datos</>,
    text: <>
      Bitrate respeta la privacidad de sus usuarios y se compromete a proteger su información personal. Los datos del usuario y las cookies se recopilan únicamente con el propósito de facilitar el juego y no se comparten con terceros sin consentimiento explícito. Bitrate emplea medidas de seguridad estándar de la industria para proteger la información del usuario.
    </>
  },
  {
    header: <>Derechos de Propiedad Intelectual</>,
    text: <>
      Todos los derechos de propiedad intelectual asociados con la plataforma de Bitrate, incluidos, entre otros, las marcas registradas, logotipos y algoritmos propietarios, pertenecen exclusivamente a Bitrate. Los usuarios acuerdan no reproducir, distribuir ni modificar ningún contenido o material sin el consentimiento previo por escrito de Bitrate.
    </>
  },
  {
    header: <>Responsabilidad y Descargos</>,
    text: <>
      Aunque Bitrate se esfuerza por proporcionar una experiencia de juego confiable y justa, los usuarios reconocen que la participación en actividades basadas en criptomonedas conlleva riesgos inherentes. Bitrate no se hace responsable de ninguna pérdida incurrida como resultado de fallos del sistema, problemas técnicos, ataques de hackers o fluctuaciones en los mercados de criptomonedas. Al participar, los usuarios asumen plena responsabilidad por sus acciones e inversiones.
    </>
  },
  {
    header: <>Código de Conducta</>,
    text: <>
      Se espera que los usuarios se comporten de manera coherente con prácticas de juego éticas y justas. Cualquier intento de manipular o explotar la plataforma de Bitrate, incluyendo, entre otros, hacer trampa, fraude o colusión, resultará en la suspensión o terminación inmediata de la cuenta. Bitrate se reserva el derecho de emprender acciones legales contra los infractores.
    </>
  },
  {
    header: <>Modificación y Terminación</>,
    text: <>
      Bitrate se reserva el derecho de modificar, suspender o terminar sus servicios o torneos en cualquier momento, sin previo aviso. En caso de terminación del servicio, Bitrate hará esfuerzos razonables para notificar a los usuarios y facilitar el retiro de los fondos restantes de sus cuentas.
    </>
  },
  {
    header: <>Cumplimiento Legal</>,
    text: <>
      Los usuarios son responsables de cumplir con todas las leyes y regulaciones relevantes en su jurisdicción con respecto a actividades basadas en criptomonedas y juegos en línea. Bitrate no fomenta ni facilita comportamientos ilegales o poco éticos y se reserva el derecho de informar de actividades sospechosas a las autoridades competentes.
      <br/><br/>
      Al acceder o utilizar los servicios de Bitrate, reconoces que has leído, entendido y aceptado estos términos y condiciones. Si no estás de acuerdo con alguna parte de estos términos, no puedes acceder o utilizar nuestros servicios.
    </>
  }
]
