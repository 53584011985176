import axios from "axios"

const instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      withCredentials: true,
});

export const pnlApi = {
    getPnlUserInfo(authToken: string) {
        return instance.get('/pnl/user-info', { 
            headers: {'Authorization' : `Bearer ${authToken}`} })
            .then((response) => response.data)
            .catch((error) => error)
    },
}