import { FC, memo } from "react";
import { SliderBackgroundStyled, SliderStyled } from "./slider.styled";

export type SliderProps = {
    precent: number;
    themeColorSlider?: string;
    themeColorBackground?: string
  }
  export const Slider: FC<SliderProps> = memo(({ 
    precent, 
    themeColorSlider = '#BAA7FF', 
    themeColorBackground = '#1C1C1C' 
}) => {
    return (
      <SliderBackgroundStyled themeColor={themeColorBackground}>
        <SliderStyled themeColor={themeColorSlider} precent={precent} />
      </SliderBackgroundStyled>
    )
  })