import { useNavigate } from "react-router";

export type RegistrationLogInProps = {
};

export const useRegistrationLogin = () => {
     const navigate = useNavigate();
     const registrationLogin = (props?: RegistrationLogInProps) => {
           navigate('/log-in', { state: props });
     }
     return registrationLogin;
}