import React, { useRef, useState, useEffect, useCallback } from 'react';
import coinSwiperImage from '../assets/coin-swiper-anim.png';
import { CircleLoading } from '../circle-loading';

export type CoinSwiperProps = {
  frameWidth: number;
  frameHeight: number;
  frameCount?: number;
  fps?: number;
  isPlaying: boolean;
  setIsPlaying: (active: boolean) => void;
  setLoadingState: () => void;
  loading: boolean;
}

export const CoinSwiper: React.FC<CoinSwiperProps> = ({
  frameWidth,
  frameHeight,
  frameCount = 149,
  fps = 40,
  isPlaying,
  setIsPlaying,
  setLoadingState,
  loading,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [currentFrame, setCurrentFrame] = useState(0);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const intervalRef = useRef<number | null>(null);

  const drawFrame = useCallback((frame: number) => {
    if (canvasRef.current && imageRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, frameWidth, frameHeight);
        const frameX = frame * 217.11; // Расчет позиции кадра

        ctx.drawImage(
          imageRef.current,
          frameX, 0, // Координаты на изображении спрайта
          frameWidth, frameHeight, // Размеры кадра
          0, 0, // Координаты на канве
          frameWidth, frameHeight // Размеры на канве
        );
      }
    }
  }, [frameHeight, frameWidth]);

  useEffect(() => {
    // Загружаем изображение спрайта и сразу рисуем первый кадр
    const image = new Image();
    image.src = coinSwiperImage;

    imageRef.current = image;

    imageRef.current.onload = () => {
      setLoadingState();
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [drawFrame, setLoadingState]);

  useEffect(() => {
    if (loading) drawFrame(0);
  }, [drawFrame, loading])

  useEffect(() => {
    if (isPlaying && imageRef.current) {
      const interval = 1000 / fps;
      intervalRef.current = window.setInterval(() => {
        setCurrentFrame((prevFrame) => {
          const nextFrame = prevFrame + 1;
          if (nextFrame >= frameCount) {
            clearInterval(intervalRef.current!);
            setIsPlaying(false);
            return 0;
          }
          return nextFrame;
        });
      }, interval);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isPlaying, fps, frameCount, setIsPlaying]);

  useEffect(() => {
    drawFrame(currentFrame);
  }, [currentFrame, drawFrame, setLoadingState]);

  return (
    <div style={{
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
      {loading ?
        <canvas style={{ width: "90%", height: "90%", userSelect: "none" }} ref={canvasRef} width={frameWidth} height={frameHeight} />
        : <CircleLoading state={'loading'} />}
    </div>
  );
};
