import axios from "axios"

const instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      withCredentials: true,
});

export const authApi = {
      login(userName: string, password: string) {
            return instance.post('/auth/login', { userName: userName, password: password }, {withCredentials: true})
                  .then((response) => {
                        return response.data;
                  }).catch((err) => console.log(err))
      },
      authByTelegram(user: any) {
            return instance.post('/auth/telegram', user , {withCredentials: true})
                  .then((response) => {
                        return response.data;
                  })
      },
      authByGoogle() {
            return instance.post(`/oauth2/authorize/google?redirect_uri=${process.env.REACT_APP_API_URL}/auth/welcome`, null , {withCredentials: true})
                  .then((response) => {
                        return response.data;
                  })
      },
      loginTwoFA(userName: string | null) {
            return instance.post('/auth/login/params', { userName: userName })
                  .then(responce => responce.data);
      },
      loginTelegramAsync(initData: string){
            return instance.post(`/auth/telegram-async`, {}, {
                  params: {
                        initData: initData,
                  }
            }).then(responce => responce.data);
      }
}