import { FC, HtmlHTMLAttributes, ReactNode, memo, useEffect } from "react";
import { WrapperPopap } from "./popup.styles";
import { Surface } from "../surface";
import { Column } from "../column";
import { Image } from "../image";

import CloseIcon from "../../images/close.svg";
import { Text } from "../text";
import { Spacing } from "../spacing";
import { FontProps, theme } from "../../themes/theme";
import { Row } from "../row";
import { useMediaQuery } from "react-responsive";

type PopupProps = {
    name?: string;
    themeName?: FontProps;
    isActive : boolean,
    hideHeader?: boolean,  
    padding?: string;
    borderRadius?: number;
    closePopup: () => void,
    children?: ReactNode,
    paddingBeforeName?: number,
}  & HtmlHTMLAttributes<HTMLElement>;;
  
export const Popup: FC<PopupProps> = memo(({padding, borderRadius, themeName, paddingBeforeName, name, hideHeader = false,isActive, closePopup,children,...rest }) => {
    useEffect(() => {
        const handleScroll = (event: Event) => {
          if (isActive) {
            event.preventDefault();
            event.stopPropagation();
          }
        };
        if (isActive) {
          document.body.style.overflow = "hidden";
          document.body.addEventListener("scroll", handleScroll, {
            passive: false,
          });
        } else {
          document.body.style.overflow = "";
          document.body.removeEventListener("scroll", handleScroll);
        }
        return () => {
          document.body.style.overflow = "";
          document.body.removeEventListener("scroll", handleScroll);
        };
    }, [isActive]);
    
    const isPhone = useMediaQuery({maxWidth: theme.toMobileSize});

    return (
        <WrapperPopap isActive={isActive} onClick={closePopup}>
            <Surface
              borderRadius={borderRadius !== undefined? borderRadius + 'px' : '20px'}
              padding={padding !== undefined ? padding : isPhone? "20px" : "40px"} 
              style={{position: "relative", width: "auto", height: "auto"}} onClick={(e) => e.stopPropagation()} {...rest} >
                {!hideHeader && <Row style={{width: "100%"}} horizontalAlign={"space-between"} verticalAlign={'center'}>
                  <Text style={{maxWidth: isPhone? 210 : 300}} themeColor={ theme.colors.primary} themeFont={themeName !== undefined ? themeName : theme.fonts.b} themePadding={[0,0,0,0]}>
                    {name}
                  </Text>
                  <Image style={{cursor: "pointer"}} onClick={closePopup} src={CloseIcon}/>
                </Row>}
                <Column horizontalAlign={"center"}>
                  {name ? <Spacing variant="Column" themeSpace={paddingBeforeName !== undefined? paddingBeforeName : 25}/> : <></>}
                  {children}
                </Column>
            </Surface>
        </WrapperPopap>
    )
});
