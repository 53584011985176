import styled from "styled-components";

export const SliderBackgroundStyled = styled.div<{ themeColor: string }>`
    width: 100%;
    position: relative;
    background: ${(({ themeColor }) => `${themeColor}`)};
    box-shadow: 0px -3px 15px rgba(186, 167, 255, 0.3);
    backdrop-filter: blur(2.5px);
    border-radius: 50px;
    height: 20px;
    padding: 2px 2px;
    &:before {
    content: "";
  position: absolute;
  inset: 0;
  border-radius: 10px;
  border: 2px solid transparent;
  background: linear-gradient(160deg, #BAA7FF , #206f5f) border-box;
  mask: linear-gradient(#fff 0 0) padding-box, 
        linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude;
    opacity: 0.4;
}
`;

export const SliderStyled = styled.div<{ precent: number, themeColor: string }>`
width: ${(({ precent }) => `${precent}%`)};
height: 100%;
background: ${(({ themeColor }) => `${themeColor}`)};
box-shadow: 0px -3px 15px rgba(186, 167, 255, 0.25);
backdrop-filter: blur(2.5px);
border-radius: 50px;
filter: blur(1.5px);
`;