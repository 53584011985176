import { useNavigate } from "react-router";

export const walletRoute = "/wallet";
export type WalletProps = {};
export const useWallet = () => {
  const navigate = useNavigate();
  const wallet = (props?: WalletProps) => {
    navigate(walletRoute, { state: props });
  };

  return wallet;
};
