import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "..";
import { userApi } from "../../api/user-api";
import axios, { AxiosError } from "axios";
import { statisticsApi } from "../../api/statistics-api";
import { appStatusSlice } from "./app-status-slice";

export type WalletDTO = {
    id: number,
    currencies: "USDTERC20" | "BTRT",
    amount: number,
    created: null | string,
    nowPayment: {
        id: number,
        name: null | string,
        createdAt: null | string,
        updatedAt: null | string
    } | null
}

export type UserInfo = {
    userId: number;
    locale: string | null;
    userName: string;
    referralLink: string | null;
    playerName: string;
    email: string;
    buyInnPercent: null;
    level: string;
    wallets: WalletDTO[];
    levelPercent: string;
    picture: string;
    phone: string | null;
    referralCode: string | null;
    tgReferralCode: string | null;
    userTimeZone: string;
    backUpCode: string;
    hasPassword: boolean;
    twoFactorAuthEnabled: boolean;
    smsTwoFactorAuthEnabled: boolean;
    language: string;
    emailTwoFactorAuthEnabled: boolean;
    isFirstTime: boolean;
    pnlUsdt: number | null;
    pnlPercent: number | null;
}

export type UserState = {
    user: UserInfo;
    loading: "loading" | "success" | "error";
    pnlLoading: "loading" | "success" | "error";
    updateLocaleState: "idle" | "loading" | "success" | "error";
    authToken: string;
    error: string | null;
    isEnterIntoApp: boolean;
    pnlUser: number;
}

const initialState: UserState = {
    user: {
        userId: -1,
        wallets: [],
        userName: '',
        locale: null,
        referralLink: '',
        userTimeZone: '',
        email: 'null',
        buyInnPercent: null,
        playerName: '',
        level: '0',
        levelPercent: '0',
        picture: '',
        phone: 'null',
        referralCode: null,
        tgReferralCode: null,
        backUpCode: '',
        language: 'ru',
        hasPassword: false,
        twoFactorAuthEnabled: false,
        smsTwoFactorAuthEnabled: false,
        emailTwoFactorAuthEnabled: false,
        isFirstTime: true,
        pnlPercent: 0,
        pnlUsdt: 0,
    },
    loading: 'loading',
    pnlLoading: 'loading',
    updateLocaleState: 'idle',
    pnlUser: 0,
    authToken: '',
    error: null,
    isEnterIntoApp: false,
}


export const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setUserInfo(state, action: PayloadAction<UserInfo>) {
            state.user = action.payload;
            state.loading = "success"
        },
        setAuthTokenActionCreater(state, action: PayloadAction<string>) {
            state.authToken = action.payload;
        },
        setPlayerNameActionCreater(state, action: PayloadAction<string>) {
            state.user.playerName = action.payload;
        },
        setTimeZoneActionCreater(state, action: PayloadAction<string>) {
            state.user.userTimeZone = action.payload;
        },
        setPictureActionCreater(state, action: PayloadAction<string>) {
            state.user.picture = action.payload;
        },
        setStatePnlActionCreater(state, action: PayloadAction<"loading" | "success" | "error">) {
            state.pnlLoading = action.payload;
        },
        enterIntoApp(state) {
            state.isEnterIntoApp = true;
        },
        setPnlUsrData(state, action: PayloadAction<number>) {
            state.pnlUser = action.payload;
        },
        errorFetchUserInfoActionCreater(state, action: PayloadAction<{ value: string }>) {
            state.error = action.payload.value;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(refrashBalance.fulfilled, (state, action: PayloadAction<WalletDTO[]>) => {
                state.user.wallets = action.payload;
            })
            .addCase(updatePlayerNameOfUser.fulfilled, (state, action: PayloadAction<string>) => {
                state.user.playerName = action.payload;
            })
            .addCase(updatePictureOfUser.fulfilled, (state, action: PayloadAction<string>) => {
                state.user.picture = action.payload;
            })
            .addCase(updateTimeZoneOfUser.fulfilled, (state, action: PayloadAction<string>) => {
                state.user.userTimeZone = action.payload;
            })
            .addCase(updateLanguageOfUser.fulfilled, (state, action: PayloadAction<string>) => {
                state.user.language = action.payload;
                state.updateLocaleState = 'success';
            })
            .addCase(updateFirstTime.fulfilled, (state, action: PayloadAction<boolean>) => {
                state.user.isFirstTime = action.payload;
            })
            .addCase(updateLanguageOfUser.rejected, (state) => {
                state.updateLocaleState = "error";
            })
            .addCase(updateLanguageOfUser.pending, (state) => {
                state.updateLocaleState = "loading";
            })
    }
});

export const refrashBalance = createAsyncThunk('user/balance',
    async (data: { authToken: string }, thunkApi) => {
        const { authToken } = data;
        const responce = await userApi.getUserInfo(authToken);
        return responce.wallets;
    }
)

export const updatePlayerNameOfUser = createAsyncThunk('user/update-player-name',
    async (data: { playerName: string, authToken: string }, thunkApi) => {
        const { authToken, playerName } = data;
        try {
            const responce = await userApi.updatePlayerName(playerName, authToken);
            return responce.playerName;
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 401) {
                    thunkApi.dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                }
            }
        }
    }
)

export const updatePictureOfUser = createAsyncThunk('user/update-picture',
    async (data: { picture: string, authToken: string }, thunkApi) => {
        const { picture, authToken } = data;
        try {
            const responce = await userApi.changePicture(picture, authToken);
            return responce.picture;
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 401) {
                    thunkApi.dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                }
            }
        }
    }
)

export const updateTimeZoneOfUser = createAsyncThunk('user/update-timezone',
    async (data: { userTimeZone: string, authToken: string }, thunkApi) => {
        const { userTimeZone, authToken } = data;
        try {
            const responce = await userApi.changeUserTimeZone(userTimeZone, authToken);
            return responce.userTimeZone;
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 401) {
                    thunkApi.dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                }
            }
        }
    }
)

export const updateFirstTime = createAsyncThunk('user/first-time',
    async (data: { authToken: string }, thunkApi) => {
        const { authToken } = data;
        try {
            const responce = await userApi.changeUserFirst(authToken);
            return responce.isFirstTime;
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 401) {
                    thunkApi.dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                }
            }
        }
    }
)

export const updateLanguageOfUser = createAsyncThunk('user/update-locale',
    async (data: { language: string, authToken: string, onClose?: () => void }, thunkApi) => {
        const { language, authToken, onClose } = data;
        try {
            const responce = await userApi.changeUserLanguage(language, authToken);
            if(onClose !== undefined) onClose();
            return responce.language;
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 401) {
                    thunkApi.dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                }
            }
        }
    }
)

export const getUserInfoAction = createAsyncThunk('user/user-info',
    async (data: { authToken: string }, thunkApi) => {
        const { authToken } = data;
        try {
            const responce = await userApi.getUserInfo(authToken)
            thunkApi.dispatch(userSlice.actions.setAuthTokenActionCreater(authToken));
            thunkApi.dispatch(userSlice.actions.setUserInfo(responce))
            thunkApi.dispatch(appStatusSlice.actions.setStatusApp({ status: "success" }));
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 401) {
                    thunkApi.dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                }
            }
        }
    }
)

export const getUserInfoActionCreater = (authToken: string) => {
    return (dispatch: AppDispatch) => {
        userApi.getUserInfo(authToken)
            .then(responce => {

                dispatch(userSlice.actions.setAuthTokenActionCreater(authToken));
                dispatch(userSlice.actions.enterIntoApp());


                dispatch(appStatusSlice.actions.setStatusApp({ status: "success" }));
            })
            .catch((err: AxiosError) => {
                dispatch(userSlice.actions.errorFetchUserInfoActionCreater({ value: 'Data error' }));
                if (err.response?.status === 401) {
                    dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }));
                }
                else if (err.response?.status === 403) {
                    dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }));
                }
                else if (err.response?.status === 404) {
                    dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }));
                }
                else if (err.response?.status === 500) {
                    dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }));
                }
            })
    }
}

export const logoutActionCreater = (authToken: string, onSuccessfully: () => void) => {
    return (dispatch: AppDispatch) => {
        localStorage.removeItem('authToken');
        userApi.logout(authToken)
            .then(responce => {
                onSuccessfully();
            })
            .catch((err) => {

            })
    }
}

export const getUserPnl = (authToken: string) => {
    return (dispatch: AppDispatch) => {
        dispatch(userSlice.actions.setStatePnlActionCreater('loading'));
        statisticsApi.getStatisticsByFilter(authToken, 'last24h')
            .then(data => {
                dispatch(userSlice.actions.setPnlUsrData(data.pnlUsdt));
                dispatch(userSlice.actions.setStatePnlActionCreater('success'));
            })
            .catch((err: AxiosError) => {
                dispatch(userSlice.actions.setStatePnlActionCreater('error'));
                if (err.response?.status === 401) {
                    dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }));
                }
            })
    }
}

export default userSlice.reducer;