import styled from "styled-components";

export const SubTask = styled.div`
    width: 100%;
    background: #171719;
    padding: 8px 13px;
    border-radius: 10px;
`;

export const CustomGrid = styled.div`
    display: grid;
    width: 100%;
    margin-top: -15px;
    justify-items: center;
    grid-template-columns: auto;
    padding: 25px 10px 10px 10px;
    border: 1px solid transparent;
    border-bottom: ridge rgba(186, 167, 255, 0.2);
    border-left: ridge rgba(186, 167, 255, 0.2);
    border-right: ridge rgba(186, 167, 255, 0.2);
    border-radius: 10px;
    transition: 2s;
    gap: 6px;
    z-index: 1;
`;
