import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../..";
import { refferalApi } from "../../../api/refferal-api";
import { appStatusSlice } from "../app-status-slice";

export type Benefit = {
    id: number,
    playerName: string,
    playerId: number,
    rootId: number,
    played: number,
    fee: number
}

export type ReferralInfo = {
    rootId: number,
    total: number,
    page: number,
    totalPages: number,
    totalElements: number,
    benefits: Benefit[]
}

export type ReferralState = {
    referral: ReferralInfo;
    loadingReferralInfo: "loading" | "success" | "error";
    errorloadingReferralInfo: string | null;
}

const initialState: ReferralState = {
    referral: {
        rootId: 0,
        total: 0,
        page: 0,
        totalPages: 4,
        totalElements: 0,
        benefits: []
    },
    loadingReferralInfo: 'loading',
    errorloadingReferralInfo: null,
}


export const refferalSlice = createSlice({
    name: 'referral',
    initialState: initialState,
    reducers: {
        setReferralData(state, action: PayloadAction<ReferralInfo>) {
            state.errorloadingReferralInfo = null;
            state.referral = action.payload;
        },
        incrementPageBenefits(state) {
            state.referral.page = state.referral.page + 1;
        },
        addBenefitsActionCreater(state, action: PayloadAction<Benefit[]>) {
            state.errorloadingReferralInfo = null;
            state.referral.benefits = [...state.referral.benefits, ...action.payload];
        },
        setLoadingReferralInfo(state, action: PayloadAction<"loading" | "success" | "error">) {
            state.loadingReferralInfo = action.payload;
        },
        setErrorLoadingReferralInfo(state, action: PayloadAction<{ value: string | null }>) {
            state.errorloadingReferralInfo = action.payload.value;
        }
    }
});


export const getNextBenefitsActionCreater = (page: number,totalPages: number, authToken: string) => {
    return (dispatch: AppDispatch) => {
        if(page >= totalPages || totalPages === 0) return;

        refferalApi.getRefferalsInfo(page, authToken)
            .then(responce => {
                dispatch(refferalSlice.actions.addBenefitsActionCreater(responce.benefits));
            })
            .catch((err) => {
                dispatch(refferalSlice.actions.setErrorLoadingReferralInfo({value: 'error'}));
                if(err?.responce.status === 401){
                    dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                }
            })

        dispatch(refferalSlice.actions.incrementPageBenefits());
        
    }
}

export const getRefferalInfoActionCreater = (authToken: string) => {
    return (dispatch: AppDispatch) => {
        dispatch(refferalSlice.actions.setLoadingReferralInfo('loading'));
        refferalApi.getRefferalsInfo(0,authToken)
            .then(responce => {
                dispatch(refferalSlice.actions.setReferralData(responce));
                dispatch(refferalSlice.actions.incrementPageBenefits());
                dispatch(refferalSlice.actions.setLoadingReferralInfo('success'));
            })
            .catch((err) => {
                dispatch(refferalSlice.actions.setErrorLoadingReferralInfo({value: 'error'}));
                if(err?.responce.status === 401){
                    dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                }
            })
    }
}

export default refferalSlice.reducer;

