
import { FC, memo } from 'react';
import { EndView } from '../../end-view/end.view';
import { PlayerFinish } from '../../../../api/sit-go/game-api';

export type EndGameViewProps = {
  back: () => void;
  leaders: PlayerFinish[];
  time: string;
  rate: number;
};
export const EndGameView: FC<EndGameViewProps> = memo(({ back, leaders, time, rate }) => {
  return (
   <EndView back={back} topThree={leaders.length > 2 ? leaders.slice(0, 3) : [leaders[0]]} leaders={leaders} time={time} rate={rate} />
);
});
