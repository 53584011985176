import { QueryClient } from '@tanstack/react-query'
import { ErrorResponseDto } from '../api/types'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      retry: 1,
      gcTime: 24 * 60 * 60 * 1000,
      staleTime: 5000,
    },
    mutations: {
      retry: 1,
      onError: (error) => {
        // eslint-disable-next-line no-throw-literal
        throw error as unknown as ErrorResponseDto
      },
    },
  },
})

export default queryClient