
import { useNavigate } from 'react-router';

export type PersonalAccountProps = {};
export const usePersonalAccount = () => {

    const navigate = useNavigate();
    const personalAccount = (props?: PersonalAccountProps) => {
        navigate('/personal-account', { state: props });
    };

    return personalAccount;
};