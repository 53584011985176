import styled from "styled-components";
import { themes } from "../themes/theme";

export const HeaderBackground = styled.div`
    display: flex;
    width: 100%;
    height: 45px;
    z-index: 100;
    align-items: center;
    background: rgba(28, 28, 28, 0.2);
    background: rgba(28, 28, 28, 0.8);
    box-shadow: 0px -5px 15px rgba(186, 167, 255, 0.1);
    backdrop-filter: blur(5px);
    border-radius: 50px;
    padding-left: 7px;
`;

export const WalletBackground = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid  rgba(255,255,255, 0.5);
    border-radius: 20px;
    width: 165px;
    position: relative;
    height: 30px;
`

export const Circle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 30px;
    width: 30px;
    box-shadow: inset 0px 0px 4px 2px rgba(255,255,255, 0.1);
    border-radius: 50%;
    border: 1px solid rgba(255,255,255, 0.5);
`;

export const CircleOnline = styled.div`
    position: absolute;
    width: 10px;
    border-radius: 50%;
    height: 10px;
    bottom: 0;
    right: 0;
    background: ${themes.colors.primary};
`