import axios from "axios"

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
});


export const statisticsApi = {
    getGamesHistory(authToken: string) {
        return instance.get('/history/games-history', {
            headers: { 'Authorization': `Bearer ${authToken}` }
        })
            .then((response) => response.data)
            .catch((err) => err)

    },
    getEndGame(authToken: string, roomId: number) {
        return instance.get('/game-result/result', {
            params: {
                roomId: roomId,
            },
            headers: { 'Authorization': `Bearer ${authToken}` }
        })
            .then((response) => response.data)
            .catch((err) => err)
    },
    getCalendarPnL(from: string, to: string, authToken: string) {
        return instance.get('/pnl/calendar', {
            params: {
                from: from,
                to: to
            },
            headers: { 'Authorization': `Bearer ${authToken}` }
        })
            .then((response) => {
                return response.data;
            })
    },
    getStatisticsByFilter(authToken: string, filter: 'lasth' | 'last3d' | 'last7d' | 'last24h' | 'all' | 'lastmonth') {
        return instance.get('/pnl/pnl-all-data', {
            params: {
                calendarFilter: filter
            },
            headers: { 'Authorization': `Bearer ${authToken}` }
        })
            .then((response) => {
                return response.data;
            })
    },
    getStatisticsFromTo(authToken: string, from: string, to: string) {
        return instance.get('/pnl/pnl-all-data', {
            params: {
                from: from,
                to: to
            },
            headers: { 'Authorization': `Bearer ${authToken}` }
        })
            .then((response) => {
                return response.data;
            })
    },
}