const names = [
    "",
    "K",
    "M",
    "B",
    "T"
]

export const FormatNumber = (n : number) => {
    if (n === 0) return "0";

    let i = 0;
    let num = Math.round(n);
    while(i + 1 < names.length && num >= 1000)
    {
        num /= 1000;
        i++;
    }


    
    return (num.toFixed(0)).toString() + names[i];
}
