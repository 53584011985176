export const termsAndConditionsTextRu = [
  {
    header: <>Условия участия</>,
    text: <>
      Получая доступ или используя услуги Bitrate, вы подтверждаете, что достигли 
      законного возраста для участия в азартных играх в вашей юрисдикции, который, как правило, 
      составляет не менее 18 лет. Bitrate оставляет за собой право проверять возраст 
      и соответствие участников требованиям и может запросить дополнительную информацию 
      для этой цели.
    </>
  },
  {
    header: <>Процесс регистрации</>,
    text: <>
      Для участия в турнирах Bitrate пользователи должны предоставить актуальную и 
      точную контактную информацию, включая действующий номер телефона, адрес электронной почты 
      или Telegram. Непредоставление точной информации может привести к приостановке или 
      закрытию аккаунта.
    </>
  },
  {
    header: <>Правила турниров</>,
    text: <>
      Все участники обязаны ознакомиться с правилами и положениями, изложенными в 
      руководствах по турнирам Bitrate. Участвуя, пользователи соглашаются соблюдать эти правила, 
      которые могут включать руководства по предсказаниям, начислению очков, срокам и запрещенным 
      действиям.
    </>
  },
  {
    header: <>Призы и вознаграждения</>,
    text: <>
      Победители турниров Bitrate получат часть призового фонда, с процентом, выделенным 
      на операционные расходы, Bitrols и будущее развитие. Специфика распределения призов 
      будет изложена в окне каждого турнира.
    </>
  },
  {
    header: <>Вступительные взносы и платежи</>,
    text: <>
      Bitrate принимает депозиты в криптовалюте для пополнения баланса игрока. Пользователи 
      несут ответственность за покрытие любых комиссий за транзакции, связанных с их депозитами и 
      выводами. Участвуя, пользователи признают, что транзакции в криптовалюте необратимы, и Bitrate 
      не предоставляет возврат средств по любой причине.
      <br/><br/>
      В случае запроса на крупный вывод средств Bitrate оставляет за собой право инициировать 
      дополнительные процедуры проверки, включая, но не ограничиваясь, проверками Know Your Customer 
      (KYC). В рамках этого процесса пользователям может быть предложено предоставить дополнительные 
      документы для проверки их личности и законности транзакции. Обратите внимание, что время обработки 
      таких выводов может составлять от 6 до 24 часов в зависимости от объема запросов и сложности 
      процесса проверки. Bitrate приложит разумные усилия для ускорения этих процедур и минимизации 
      неудобств для пользователя.
    </>
  },
  {
    header: <>Конфиденциальность данных и безопасность</>,
    text: <>
      Bitrate уважает конфиденциальность своих пользователей и обязуется защищать их 
      личную информацию. Данные пользователей и файлы cookie собираются исключительно для 
      облегчения игрового процесса и не передаются третьим лицам без явного согласия. Bitrate 
      использует стандартные меры безопасности для защиты информации пользователей.
    </>
  },
  {
    header: <>Права на интеллектуальную собственность</>,
    text: <>
      Все права на интеллектуальную собственность, связанные с платформой Bitrate, включая, 
      но не ограничиваясь, товарные знаки, логотипы и собственные алгоритмы, принадлежат исключительно 
      Bitrate. Пользователи соглашаются не воспроизводить, распространять или изменять любые материалы 
      без предварительного письменного согласия Bitrate.
    </>
  },
  {
    header: <>Ответственность и отказ от обязательств</>,
    text: <>
      Хотя Bitrate стремится обеспечить надежный и честный игровой процесс, пользователи признают, что 
      участие в деятельности на основе криптовалют связано с присущими рисками. Bitrate не несет ответственности 
      за любые убытки, понесенные в результате сбоев системы, технических неисправностей, хакерских атак или 
      колебаний на рынках криптовалют. Участвуя, пользователи принимают на себя полную ответственность за свои 
      действия и инвестиции.
    </>
  },
  {
    header: <>Кодекс поведения</>,
    text: <>
      От пользователей ожидается, что они будут вести себя в соответствии с этическими и честными игровыми 
      практиками. Любая попытка манипулировать или использовать платформу Bitrate, включая, но не ограничиваясь, 
      мошенничеством, жульничеством или сговором, приведет к немедленной приостановке или закрытию аккаунта. 
      Bitrate оставляет за собой право предпринять юридические действия против нарушителей.
    </>
  },
  {
    header: <>Изменения и прекращение обслуживания</>,
    text: <>
      Bitrate оставляет за собой право изменять, приостанавливать или прекращать свои услуги или турниры 
      в любое время без предварительного уведомления. В случае прекращения обслуживания Bitrate приложит 
      разумные усилия для уведомления пользователей и содействия выводу оставшихся средств с их счетов.
    </>
  },
  {
    header: <>Соблюдение закона</>,
    text: <>
      Пользователи несут ответственность за соблюдение всех применимых законов и нормативных актов в 
      своей юрисдикции в отношении деятельности на основе криптовалют и онлайн-игр. Bitrate не поддерживает 
      и не способствует незаконному или неэтичному поведению и оставляет за собой право сообщать о подозрительной 
      деятельности в соответствующие органы.
      <br/><br/>
      Получая доступ или используя услуги Bitrate, вы подтверждаете, что прочли, поняли и согласились соблюдать 
      данные условия. Если вы не согласны с какой-либо частью этих условий, вы не можете получить доступ или 
      использовать наши услуги.
    </>
  }
];
