import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
});

export const appSecurityApi = {
    initApp(authToken: string) {
        return instance.post('/m2fa/init2fa', null, { 
            headers: {'Authorization' : `Bearer ${authToken}`} })
            .then((response) => {
                return response.data;
        })
    },
    confirmApp(code: string,authToken: string) {
        return instance.post('/m2fa/confirm2fa', null, { 
            params: {
                code: code
            },
            headers: {'Authorization' : `Bearer ${authToken}`} })
            .then((response) => {
                return response.data;
        })
    },
    disableApp(authToken: string) {
        return instance.get('/m2fa/disable2fa', { 
            headers: {'Authorization' : `Bearer ${authToken}`} })
            .then((response) => {
                return response.data;
        })
    },
}