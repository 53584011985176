import { useCallback, useMemo } from "react";
import { useAppDispatch, useTypedSelector } from "../use-typed-selector";
import { useUser } from "../user-hook";
import { Leader, getLeaderboardAction } from "../../store/reducers/swiper/swiper-leaderboard-slice";

export type Leaderboard = {
    leaders: Leader[];
    top: Leader[];
    onFilter: (filter: "PLAYERS" | "FARMERS") => void;
}

export const useLeaderboard = (): Leaderboard => {
    const { authToken } = useUser();
    const { leaders } = useTypedSelector(state => state.swiperLeaderboard);
    const dispatch = useAppDispatch();

    const onFilter = useCallback((filter: "PLAYERS" | "FARMERS") => {
        dispatch(getLeaderboardAction({ authToken: authToken, filter: filter }));
    }, [authToken, dispatch])

    const topThree = useMemo(() => {
        return leaders.slice(0, 3);
    }, [leaders]);

    return {
        top: topThree,
        leaders: leaders,
        onFilter: onFilter,
    }
}