export const theme = {
    colors: {
        background: '#131328',
        primary: '#FFEEAA',
        secondary: '#FDFDCE',
        foreground: '#1D1D38',
        surface: '#FCFCFC',
        grey: '#1E1E1E',
        error: '#AA0020',
        surfaceGray: "#7B7B7B",
        success: "#77CE41"
    },
    fonts: {
        h1: {
            family: "Montserrat-Bold",
            desktopSize: "39px",
            mobileSize: "31px",
            weight: "700",
        },
        h2: {
            family: "Montserrat-Bold",
            desktopSize: "31px",
            mobileSize: "20px",
            weight: "700",
        },
        b: {
            family: "Montserrat-Bold",
            desktopSize: "21px",
            mobileSize: "18px",
            weight: "700",
        },
        ht1: {
            family: "Inter-SemiBold",
            desktopSize: "16px",
            mobileSize: "14px",
            weight: "600",
        },
        ht2: {
            family: "Inter-SemiBold",
            desktopSize: "15px",
            mobileSize: "12px",
            weight: "600",
        },
        t: {
            family: "OpenSans-Regular",
            desktopSize: "14px",
            mobileSize: "12px",
            weight: "400",
        },
        m: {
            family: "Inter-Medium",
            desktopSize: "16px",
            mobileSize: "16px",
            weight: "500",
        },
    },
    toMobileSize: 1200
}

export type FontProps = {
    family: string;
    desktopSize: string;
    mobileSize: string;
    weight: string;
};

export const themes = {
    colors: {
       background: "#1C1C1C",
       primary: "#0DC6A0",
       secondary: "#BAA7FF",
       foreground: "#FFFFFF",
       success: "#0DC6A0",
       gray: "rgba(255, 255, 255, .7);",
       lightSurface: '#BAA7FF33',
       surface: "linear-gradient(128.69deg, rgba(183, 94, 213, 0.2) 39.51%, rgba(13, 198, 160, 0.2) 141.45%), #1C1C1C;",
       gradient: "linear-gradient(90deg, #0DC6A0 0%, #D238B0 100%);",
       gradients: {
        primary: 'linear-gradient(140deg, #0DC6A0, #1D0E0E)',
        secondary: 'linear-gradient(140deg, #BAA7FF, #1D0E0E)',
        foreground: 'linear-gradient(177deg, rgba(186, 167, 255, 0.4) -10%, rgba(13, 198, 160, 0.4) 300%)'
       }
    },
    fonts: {
        h1: {
            family: "Druk Wide",
            desktopSize: "21px",
            mobileSize: "21px",
            weight: "700",
        },
        h2: {
            family: "Druk Wide",
            desktopSize: "16px",
            mobileSize: "16px",
            weight: "700",
        },
        h3: {
            family: "Druk Wide",
            desktopSize: "12px",
            mobileSize: "12px",
            weight: "700",
        },
        h4: {
            family: "Druk Wide",
            desktopSize: "10px",
            mobileSize: "10px",
            weight: "700",
        },
        h5: {
            family: "Druk Wide",
            desktopSize: "9px",
            mobileSize: "9px",
            weight: "700",
        },
        b: {
            family: "Druk Wide",
            desktopSize: "14px",
            mobileSize: "14px",
            weight: "700",
        },
        ht1: {
            family: "Andale Mono",
            desktopSize: "14px",
            mobileSize: "14px",
            weight: "600",
        },
        ht2: {
            family: "Andale Mono",
            desktopSize: "10px",
            mobileSize: "10px",
            weight: "600",
        },
        t: {
            family: "Andale Mono",
            desktopSize: "8px",
            mobileSize: "8px",
            weight: "400",
        },
        m: {
            family: "Andale Mono",
            desktopSize: "6px",
            mobileSize: "6px",
            weight: "600",
        },
    },
}