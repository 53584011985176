import { Root } from "./Root";



function App() {
  
  return (
    <Root isTelegram={false} />
  );
}

export default App;
