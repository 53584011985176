import styled from "styled-components";
import { theme } from "../../themes/theme";

export const LogoStyled = styled.img`
width: 300px;
height: 28px;
@media(max-width: ${theme.toMobileSize+"px"}){
    width: 190px;
    height: 18px;
}
@media(max-width: ${theme.toMobileSize+"px"}){
    width: 150px;
    height: 15px;
}
`;