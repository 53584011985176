import styled from "styled-components";
import { theme } from "../../themes/theme";

export const InputContainer = styled.div<{ width?: number | string, maxWidth?: number }>`
  position: relative;
  max-width: ${props => props.maxWidth? props.maxWidth + 'px' : '420px'};
  width: ${props => props.width? typeof props.width === "number" ? props.width + 'px' : props.width : '420px'};
  box-sizing: border-box;
  padding: 0px 0px;
  @media (max-width: ${theme.toMobileSize+'px'}){
    width: 100%;
  }
`;


export const InputStyled = styled.input`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid;
  padding: 14px 35px 14px 12px;
  border-color: ${theme.colors.grey};
  border-radius: 5px;
  background-color: ${theme.colors.foreground};
  transition: border-color 0.3s;
  font-family: ${theme.fonts.t.family};
  font-size: ${theme.fonts.t.desktopSize};
  font-weight: ${theme.fonts.t.weight};
  outline: 0;
  outline-offset: 0;
  color: ${theme.colors.surface};
  &::placeholder {
    color: transparent;
  }

  &:focus{
    border-color:  ${theme.colors.surface};
      & + label {
        top: -25px;
        color: ${theme.colors.surface};
      }
  }
    @media(max-width: ${theme.toMobileSize+'px'}){
      padding: 10px 35px 10px 12px;
      &::placeholder {
        color: ${theme.colors.surfaceGray};
  }

    }
`;

export const Label = styled.label`
  width: 100%;
  align-items: flex-start;
  margin-top: -5px;
  font-family: ${theme.fonts.ht2.family};
  font-size: ${theme.fonts.ht2.desktopSize};
  color: ${theme.colors.surface};
  transition: all 0.3s;
  text-align: center;

  @media (max-width: ${theme.toMobileSize+'px'}){
    display: none;
  }
  
`;

export const InputButton = styled.button<{ src: string, placeholder: string | undefined }>`
position: absolute;
width: 20px;
height: 20px;
right: 10px;
top: ${({placeholder}) => (placeholder !== undefined ? '44px' : '17px')};
border: 0px;
background: url(${props => props.src});
background-repeat: no-repeat;
@media (max-width: ${theme.toMobileSize+'px'}){
  right: 8px;
  top: 13px;
  }

`;
export const ErrorChild = styled.div`
  position: absolute;
`;