import styled from "styled-components";

export const WrapperPopap = styled.div<{
    isActive: boolean;
}>`
    width: 100vw;
    height: 100vh;
    overflow: auto;
    top: 0;
    z-index: 100;
    left: 0;
    background-color: rgba(0,0,0,0.7);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    display: ${({ isActive }) => isActive? "flex" : "none"};
    pointer-events: ${({ isActive }) => isActive? "all" : "none"};
    transition: 0.5s;
`