import axios from "axios"

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
});


export const walletApi = {
    getHistory(authToken: string) {
        return instance.get('/wallet/v1/history', { 
            headers: {'Authorization' : `Bearer ${authToken}`} })
            .then((response) => {
                return response.data;
            })
    },
    getDepositAddress(currency: string, amount: string,authToken: string) {
        return instance.post('/wallet/v1/deposit',null, { 
                params:{
                    currency: currency,
                    amount: amount,
                },
                headers: {'Authorization' : `Bearer ${authToken}`} 
            })
            .then((response) => {
                return response.data;
            })
    },
    getMinAmountOfDeposit(currency_from: string, authToken: string) {
        return instance.get('/wallet/v1/deposit-min-amount', { 
            params: {
                currency_from: currency_from 
            },
            headers: {'Authorization' : `Bearer ${authToken}`} })
            .then((response) => {
                return response.data;
            })
    },
    validateAddressWithdrawal(address: string, currency: string, authToken: string) {
        return instance.get('/wallet/v1/validate-address', { 
            params: {
                address: address,
                currency: currency,
            },
            headers: {'Authorization' : `Bearer ${authToken}`} })
            .then((response) => {
                return response.data;
            })
    },
    preWithdrawal(amount: string,currency: string, address: string, authToken: string) {
        return instance.post('/wallet/v1/pre-withdraw', null,{ 
            params: {
                amount: amount,
                currency: currency,
                address: address,
            },
            headers: {'Authorization' : `Bearer ${authToken}`} })
            .then((response) => {
                return response.data;
            })
    },
    verifyWithdraw(code: string, authToken: string) {
        return instance.post('/wallet/v1/verify-withdraw', null, { 
            params: {
                code: code
            },
            headers: {'Authorization' : `Bearer ${authToken}`} })
            .then((response) => {
                return response.data;
            })
    },
    getFeeWithdraw(amount: string, currency: string, authToken: string) {
        return instance.get('/wallet/v1/fee', { 
            params: {
                amount: amount,
                currency: currency
            },
            headers: {'Authorization' : `Bearer ${authToken}`} })
            .then((response) => {
                return response.data;
            })
    }
    ,
    getEstimatePrice(amount: string, currencyFrom: string, currencyTo: string, authToken: string) {
        return instance.get('/wallet/v1/deposit-estimate-price', { 
            params: {
                amount: amount,
                currencyFrom: currencyFrom,
                currencyTo: currencyTo
            },
            headers: {'Authorization' : `Bearer ${authToken}`} })
            .then((response) => {
                return response.data;
            })
    }
}