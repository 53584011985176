import styled from "styled-components";

export const StyledImage = styled.img<{ size?: Number,width: Number, height: Number, themeColor?: string }>`
  width: ${props => {
    if(props.size) return `${props.size}px`
    else  return `${props.width}px`
  }};
  height: ${props => {
    if(props.size) return `${props.size}px`
    else return `${props.height}px`
  }};
  & path {
    fill: ${props => `${props.themeColor}`};
  }
`;
