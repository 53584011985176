import { useCallback, useEffect } from "react";
import { Room, getActiveRooms, getBitrollsRoom, getRoomInfo, roomSitSlice } from "../store/reducers/sit-and-go/rooms-slice";
import { useAppDispatch, useTypedSelector } from "./use-typed-selector";
import { useUser } from "./user-hook";

export type GameRooms = {
    rooms: Room[];
    state: "loading" | "error" | "idle" | "success";
    getRooms: () => void;
}


export const useBitrollsRooms = (authToken: string): GameRooms => {
    const { bitrollsRooms, stateBitrollsRooms } = useTypedSelector(state => state.roomSit);
    const dispatch = useAppDispatch();

    const getBitrollRooms = useCallback(() => {
        dispatch(getBitrollsRoom(authToken));
    }, [authToken, dispatch])

    useEffect(() => {
        getBitrollRooms();
    }, [getBitrollRooms])

    return { rooms: bitrollsRooms, state: stateBitrollsRooms, getRooms: getBitrollRooms };
}

export const useActiveRooms = () => {
    const { activeRooms, stateActiveRooms } = useTypedSelector(state => state.roomSit);
    const { authToken } = useUser();
    const dispatch = useAppDispatch();

    const getActiveRoom = useCallback(() => {
        dispatch(getActiveRooms({ authToken: authToken }));
    }, [authToken, dispatch])

    useEffect(() => {
        getActiveRoom();
        return () => { dispatch(roomSitSlice.actions.clearActiveRoom()); }
    }, [dispatch, getActiveRoom])

    return {
        rooms: activeRooms,
        getRooms: getActiveRoom,
        state: stateActiveRooms
    }
}

export const useRoomInfo = (roomId: number) => {
    const { roomInfo } = useTypedSelector(state => state.roomSit);
    const { authToken } = useUser();
    const dispatch = useAppDispatch();

    const getRoom = useCallback(() => {
       dispatch(getRoomInfo({ authToken: authToken, roomId: roomId  }));
    }, [authToken, dispatch, roomId])

    useEffect(() => {
      getRoom();
      return () => { dispatch(roomSitSlice.actions.clearRoomInfo()); }
    }, [dispatch, getRoom])

    return { room : roomInfo };
}