import { FC, memo, useCallback, useState } from "react";
import { Swiper } from "../swiper/swiper";
import { SwiperTask } from "../swiper-task";
import { SwiperLeaderboard } from "../swiper-leaderboard";
import { Column } from "../../../../сomponents/column";
import { Footer } from "../../../../ui-kit/footer";
import { SwiperParentProps } from "./swiper-parent.props";
import { Lobby } from "../lobby";
import { useNavigate } from "react-router";
import { useTelegramBack } from "../../../../hooks/telegram-button";
import { Header } from "../../../../ui-kit/header";
import { usePersonalAccount } from "../../personal-account/personal-account.props";
import { useUser } from "../../../../hooks/user-hook";
import { SwiperFortune } from "../swiper-fortune";
import { useSwiperFortune } from "../../../../hooks/swiper/swiper-fortune";
import { useWallet } from "../../wallet/wallet.props";

export const SwiperParent: FC<SwiperParentProps> = memo(() => {
  const [type, setType] = useState<
    "footer.farm" | "footer.tasks" | "footer.top" | "footer.games"
  >("footer.farm");
  const navigate = useNavigate();
  const { user, getUSDTBalance } = useUser();
  const onBack = useCallback(() => {
    navigate("/");
  }, [navigate]);
  useTelegramBack(onBack);
  const presonalAccount = usePersonalAccount();
  const onPersonalAccount = useCallback(() => {
    presonalAccount({});
  }, [presonalAccount]);

  const { initDataFortune, getAccessibleSpins } = useSwiperFortune();

  const [isOpenFortune, setIsOpenFortune] = useState<boolean>(false);
  const openFortune = useCallback(() => {
    getAccessibleSpins();
    initDataFortune();
    setIsOpenFortune(true);
  }, [initDataFortune, getAccessibleSpins]);
  const closeFortune = useCallback(() => {
    setIsOpenFortune(false);
  }, []);

  const wallet = useWallet();
  const onWallet = useCallback(() => {
    wallet({});
  }, [wallet]);

  return (
    <Column horizontalAlign="center" style={{ height: "100vh" }}>
      {type !== "footer.tasks" && (
        <Column padding={[15, 25, 0, 25]} horizontalAlign="center">
          <Header
            picture={user.picture}
            onProfile={onPersonalAccount}
            onWallet={onWallet}
            balance={getUSDTBalance}
          />
        </Column>
      )}
      <SwiperParentView type={type} />
      <SwiperFortune isShow={isOpenFortune} closeFortune={closeFortune} />
      <Footer
        type={type}
        onFortune={openFortune}
        onFarm={() => setType("footer.farm")}
        onTask={() => setType("footer.tasks")}
        onLeaderboard={() => setType("footer.top")}
        onGames={() => setType("footer.games")}
      />
    </Column>
  );
});

export type SwiperParentViewProps = {
  type: "footer.farm" | "footer.tasks" | "footer.top" | "footer.games";
};
export const SwiperParentView: FC<SwiperParentViewProps> = memo(({ type }) => {
  switch (type) {
    case "footer.farm":
      return <Swiper />;
    case "footer.tasks":
      return <SwiperTask />;
    case "footer.top":
      return <SwiperLeaderboard />;
    case "footer.games":
      return <Lobby />;
  }
});
