import { FC, memo, useCallback } from "react";
import { Text } from "../../../../ui-kit/text";

import { Image } from "../../../../ui-kit/image";
import { Row } from "../../../../ui-kit/row";
import { Spacing } from "../../../../ui-kit/spacing";
import durationIcon from "../../../../ui-kit/assets/duration.svg";
import playersIcon from "../../../../ui-kit/assets/players.svg";
import { Pressable } from "../../../../ui-kit/pressable";
import {
  Blur,
  GridContainer,
  MobilerButton,
  VerticalLine,
} from "./sit-and-go-btrt-rooms.styled";
import { Surface } from "../../../../ui-kit/surface";

import { RoomCard } from "../../../../ui-kit/room-card/room-card";
import { Column } from "../../../../ui-kit/column";
import { themes } from "../../../../themes/theme";
import { Room } from "../../../../store/reducers/sit-and-go/rooms-slice";
import { getNumberFromRoomType } from "../../../../data/room-info";
import { Header } from "../../../../ui-kit/header";
import { ScrollContainer } from "../../swiper-parent/swiper-leaderboard/swiper-leaderboard.styled";
import { FormatNumber } from "../../../../data/format-numbers";
import { CircleLoading } from "../../../../ui-kit/circle-loading";
import { Translate } from "../../../../ui-kit/translate";
import { useWallet } from "../../wallet/wallet.props";

type PlayerProps = "TWO" | "FOUR" | "SIX";
type TimeProps = "FIVE" | "FIFTEEN" | "HOUR" | "FOURHOURS";

export type SitAndGoBtrtRoomsViewProps = {
  rooms: Room[];
  stateRooms: "idle" | "loading" | "success" | "error";
  onClickRoom: (roomId: number, currency: string) => void;
  onProfile: () => void;
  duration: FilterProps[];
  players: FilterProps[];
  filter: "ALL" | "LIVE" | "Premium";
  onToggle: (state: "ALL" | "LIVE" | "Premium") => void;
  onFilter: (state: "Player" | "Time", filter: FilterProps[]) => void;
  balance: number;
  picture: string;
};
export const SitAndGoBtrtRoomsView: FC<SitAndGoBtrtRoomsViewProps> = memo(
  ({
    onClickRoom,
    stateRooms,
    onProfile,
    rooms,
    duration,
    players,
    filter,
    onToggle,
    onFilter,
    balance,
    picture,
  }) => {
    const resetHidden = useCallback((data: FilterProps[]) => {
      const resetData = data.map((item) => {
        return { ...item, hidden: false };
      });
      return resetData;
    }, []);

    const setNewDuration = useCallback(
      (data: FilterProps) => {
        const reset = resetHidden(duration);
        onFilter("Time", reset);

        const newDuration = duration.map((item) => {
          return {
            ...item,
            hidden: item.param === data.param ? true : false,
          };
        });
        onFilter("Time", newDuration);
      },
      [duration, onFilter, resetHidden]
    );

    const setNewPlayers = useCallback(
      (data: FilterProps) => {
        const reset = resetHidden(players);
        onFilter("Player", reset);

        const newPlayers = players.map((item) => {
          return {
            ...item,
            hidden: item.param === data.param ? true : false,
          };
        });
        onFilter("Player", newPlayers);
      },
      [onFilter, players, resetHidden]
    );

    const wallet = useWallet();
    const onWallet = useCallback(() => {
      wallet({});
    }, [wallet]);

    return (
      <Column style={{ height: "100dvh" }} horizontalAlign="center">
        <Blur />
        <Column horizontalAlign="center" padding={[13, 24, 0, 24]}>
          <Header
            balance={balance}
            picture={picture}
            onProfile={onProfile}
            onWallet={onWallet}
          />
        </Column>
        <Spacing themeSpace={20} variant="Column" />
        <Row
          style={{ position: "relative", height: 75 }}
          width="100%"
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Row
            style={{ position: "absolute", width: "100%" }}
            horizontalAlign="center"
            verticalAlign="flex-start"
          >
            <Row verticalAlign="center" style={{ position: "relative" }}>
              <Text
                themeFont={themes.fonts.h1}
                themeColor={themes.colors.surface}
              >
                SIT&GO
              </Text>
              <Surface
                style={{
                  marginTop: "3px",
                  position: "absolute",
                  right: -60,
                  padding: "3px 5px 3px 5px",
                }}
                width={"auto"}
                radius={5}
              >
                <Text
                  style={{ fontSize: 10 }}
                  themePadding={[5, 0]}
                  themeFont={themes.fonts.b}
                  themeColor={themes.colors.primary}
                >
                  BTRT
                </Text>
              </Surface>
            </Row>
          </Row>
        </Row>

        <>
          <Spacing themeSpace={10} variant="Column" />
          <ToggleMobile state={filter} onClick={onToggle} />
        </>
        <Spacing themeSpace={20} variant="Column" />
        <FilterParent
          players={players}
          duration={duration}
          setNewDuration={setNewDuration}
          setNewPlayers={setNewPlayers}
        />
        <Spacing themeSpace={20} variant="Column" />
        <ScrollContainer style={{ height: "100%", position: "relative" }}>
          <CircleLoading state={stateRooms} />
          <GridContainer>
            {rooms.map((item) => (
              <RoomCard
                id={item.id}
                currency={item.currencies}
                type={item.currencies}
                time={item.time}
                playersAll={item.all}
                playersWait={item.waiting}
                amount={FormatNumber(item.amount)}
                pictures={item.previewLogos}
                onClick={onClickRoom}
              />
            ))}
          </GridContainer>
        </ScrollContainer>
      </Column>
    );
  }
);

export type FilterProps = {
  zone?: "h" | "m";
  param: TimeProps | PlayerProps;
  hidden: boolean;
};
type FilterButtonsProps = {
  title: string;
  icon: string;
  data: FilterProps[];
  setInformation: (data: FilterProps) => void;
};

const FilterButtons: FC<FilterButtonsProps> = memo(
  ({ title, icon, data, setInformation }) => {
    return (
      <Surface radius={20} padding={[5, 10]}>
        <Row style={{ width: "100%" }} verticalAlign="center">
          <Image src={icon} size={30} />
          <Spacing themeSpace={10} variant="Row" />
          <Row
            style={{ width: "100%" }}
            horizontalAlign={data.length < 4 ? "flex-start" : "space-between"}
            verticalAlign="center"
          >
            {data.map((item, index) => (
              <>
                <Pressable
                  style={{ height: "100%" }}
                  onClick={() => setInformation(item)}
                >
                  <MobilerButton select={item.hidden}>
                    <Column
                      style={{ height: "100%" }}
                      verticalAlign="center"
                      horizontalAlign="center"
                    >
                      <Text
                        style={{ wordSpacing: "-4px" }}
                        align="center"
                        themeFont={themes.fonts.h3}
                        themeColor={
                          item.hidden ? themes.colors.background : "#BBBBBB"
                        }
                      >
                        {item.zone === undefined
                          ? getNumberFromRoomType.get(item.param)?.toString()
                          : `${getNumberFromRoomType
                              .get(item.param)
                              ?.toString()} ${item.zone}`}
                      </Text>
                    </Column>
                  </MobilerButton>
                </Pressable>
                {index !== data.length - 1 && data.length < 4 && (
                  <Spacing themeSpace={7} variant="Row" />
                )}
              </>
            ))}
          </Row>
        </Row>
      </Surface>
    );
  }
);

type FilterParentProps = {
  players: FilterProps[];
  duration: FilterProps[];
  setNewPlayers: (data: FilterProps) => void;
  setNewDuration: (data: FilterProps) => void;
};

const FilterParent: FC<FilterParentProps> = memo(
  ({ players, duration, setNewPlayers, setNewDuration }) => (
    <Column style={{ maxWidth: "290px" }} horizontalAlign="center">
      <FilterButtons
        setInformation={setNewPlayers}
        data={players}
        title="Number of players"
        icon={playersIcon}
      />
      <Spacing themeSpace={20} variant="Column" />
      <FilterButtons
        setInformation={setNewDuration}
        data={duration}
        title="Duration"
        icon={durationIcon}
      />
    </Column>
  )
);

type ToggleMobileProps = {
  state: "ALL" | "LIVE" | "Premium";
  onClick: (state: "ALL" | "LIVE" | "Premium") => void;
};

const ToggleMobile: FC<ToggleMobileProps> = memo(({ state, onClick }) => (
  <Row
    style={{ height: "43px" }}
    horizontalAlign="center"
    verticalAlign="center"
  >
    <Pressable onClick={() => onClick("ALL")}>
      <Text
        themeColor={
          state === "ALL" ? themes.colors.primary : themes.colors.gray
        }
        themeFont={themes.fonts.ht1}
      >
        <Translate keyWord="rooms.usdt.all" />
      </Text>
    </Pressable>
    <Spacing themeSpace={10} variant="Row" />
    <VerticalLine />
    <Spacing themeSpace={10} variant="Row" />
    <Pressable onClick={() => onClick("LIVE")}>
      <Text
        themeColor={
          state === "LIVE" ? themes.colors.primary : themes.colors.gray
        }
        themeFont={themes.fonts.ht1}
      >
        <Translate keyWord="rooms.usdt.live" />
      </Text>
    </Pressable>
    <Spacing themeSpace={10} variant="Row" />
    <VerticalLine />
    <Spacing themeSpace={10} variant="Row" />
    <Pressable onClick={() => onClick("Premium")}>
      <Text
        themeColor={
          state === "Premium" ? themes.colors.primary : themes.colors.gray
        }
        themeFont={themes.fonts.ht1}
      >
        <Translate keyWord="rooms.usdt.premium" />
      </Text>
    </Pressable>
  </Row>
));
