import { useMutation } from "@tanstack/react-query";
import { WalletPostPreWithdraw } from "../../api/wallet/dto/request";
import { queryKeys } from "../queryKeys";
import { apiPreWithdrawal } from "./lib/apiPreWithdrawal";

type Props = {
  amount: string;
  currency: string;
  address?: string;
  authToken: string;
};

export const usePreWithdrawal = ({
  amount,
  currency,
  address,
  authToken,
}: Props) => {
  const { data, isError, isPaused, isSuccess, error, mutate } = useMutation<
    void | null,
    WalletPostPreWithdraw
  >({
    mutationKey: queryKeys.wallet.preWithdraw(currency),
    mutationFn: async () =>
      apiPreWithdrawal({ amount, currency, address, authToken }),
  });

  return {
    data,
    isError,
    isPaused,
    isSuccess,
    error,
    mutate,
  };
};
