export const apiRoutes = {
    wallet : {
        verifyWithdraw: '/wallet/v1/verify-withdraw',
        preWithdraw: '/wallet/v1/pre-withdraw',
        deposit: '/wallet/v1/deposit',
        validateAddress: '/wallet/v1/validate-address',
        status: '/wallet/v1/status',
        history: '/wallet/v1/history',
        fee: '/wallet/v1/fee',
        depositMinAmount: '/wallet/v1/deposit-min-amount',
        depositEstimatePrice: '/wallet/v1/deposit-estimate-price'
    },
    payments: {
        network: '/payments/network',
    }
}