import styled from "styled-components";

export const StyledTouch = styled.button<{}>`
  display: inline-block;
  cursor: pointer;
  user-select: none;
  background: transparent;
  border: none;
  box-shadow: none;
  padding:0;
  margin: 0;
  background-color: transparent;
`;