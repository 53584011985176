import { FC, ReactNode, memo } from "react";
import { Slider } from "../slider";
import { Column } from "../column";
import { Row } from "../row";
import { Spacing } from "../spacing";

export type LevelProps = {
    start: ReactNode;
    end: ReactNode;
    precent: number;
    themeColor?: string;
}
export const Level: FC<LevelProps> = memo(({ start, end, precent, themeColor = '#BAA7FF' }) => {
    return (
        <Column style={{ paddingLeft: "5px" }} horizontalAlign="center">
            <Row style={{ width: "100%" }} verticalAlign="center" horizontalAlign="space-between">
                {start}
                {end}
            </Row>
            <Spacing themeSpace={7} variant="Column" />
            <Slider themeColorSlider={themeColor} precent={precent} />
        </Column>
    )
})