import axios from "axios"

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
});

export const lobbyApi = {
    getTimeOfCloseBitroll(authToken: string) {
        return instance.get('game/bitrate-info', { 
            headers: {'Authorization' : `Bearer ${authToken}`} })
            .then((response) => {
                return response.data;
            })
    }
}