import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "..";
import { twoFactorApi } from "../../api/two-factor-api";

type TwoFactorState = {
    appCode: string;
    smsCode: string;
    loading: "idle" | "error" | "loading" | "success";
    authToken: string | null;
    errorApp: null | string;
    errorSms: null | string;
}

const initialState: TwoFactorState = {
    appCode: '',
    smsCode: '',
    loading: "idle",
    authToken: '',
    errorApp: null,
    errorSms: null,
}

export const twoFactorSlice = createSlice({
    name: 'two-factor',
    initialState: initialState,
    reducers: {
        setAppCode(state, action: PayloadAction<string>) {
            state.appCode = action.payload;
        },
        setSmsCode(state, action: PayloadAction<string>) {
            state.smsCode = action.payload;
        },
        fetchTwoFactor(state) {
            state.loading = "loading";
        },
        fetchTwoFactorError(state, action: PayloadAction<{ variant: "errorApp" | "errorSms", value: string }>) {
            state[action.payload.variant] = action.payload.value;
            state.loading = "error";
        },
        fetchTwoFactorSuccess(state, action: PayloadAction<string>) {
            state.loading = "success";
            state.errorApp = null;
            state.errorSms = null;
            state.authToken = action.payload;
        }
    }
})

export const twoFactorAppActionCreater = (userName: string, code: number) => {
    return (dispatch: AppDispatch) => {
        dispatch(twoFactorSlice.actions.fetchTwoFactor());
        twoFactorApi.verifyApp(userName, code)
            .then(responce => {
                if (responce.verified === true) {
                    dispatch(twoFactorSlice.actions.fetchTwoFactorSuccess(responce.accessToken));
                }
                else {
                    dispatch(twoFactorSlice.actions.fetchTwoFactorError({ variant: "errorSms", value: "The code doesn’t work. Enter the correct code" }));
                }
            })
            .catch((error) => {
                dispatch(twoFactorSlice.actions.fetchTwoFactorError({ variant: "errorSms", value: "The code doesn’t work. Enter the correct code" }));
            })
    }
}

export const twoFactorSmsActionCreater = (userName: string, code: number, password: string) => {
    return (dispatch: AppDispatch) => {
        dispatch(twoFactorSlice.actions.fetchTwoFactor());
        twoFactorApi.verifySms(userName, code)
            .then(responce => {
                if (responce.verified === true) {
                    twoFactorApi.welcome(userName, password)
                        .then(responce => {
                            dispatch(twoFactorSlice.actions.fetchTwoFactorSuccess(responce.accessToken));
                        })
                }
                else {
                    dispatch(twoFactorSlice.actions.fetchTwoFactorError({ variant: "errorSms", value: "The code doesn’t work. Enter the correct code" }));
                }
            })
            .catch((error) => {
                dispatch(twoFactorSlice.actions.fetchTwoFactorError({ variant: "errorSms", value: "The code doesn’t work. Enter the correct code" }));
            })
    }
}

export default twoFactorSlice.reducer;