import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sitRoomApi } from "../../../api/sit-and-go/rooms-api";
import { appStatusSlice } from "../app-status-slice";
import axios from "axios";

type RoomsState = {
    stateRooms: "idle" | "loading" | "success" | "error";
    stateActiveRooms: "idle" | "loading" | "success" | "error";
    stateBitrollsRooms: "idle" | "loading" | "success" | "error";
    stateSitAndGoBtrt: "idle" | "loading" | "success" | "error";
    stateRoomInfo : "idle" | "loading" | "success" | "error";
    rooms: Room[];
    roomInfo: RoomInfo | null;
    bitrollsRooms: Room[];
    sitAndGoBtrt: Room[];
    activeRooms: ActiveRoom[];
}

type RoomInfoType = {
    authToken: string;
    roomId: number;
}

export type ActiveRoom = {
    recovery: number
} & Room;

export type Room = {
    id: number;
    type: string;
    gameType: number;
    time: "HOUR" | "FOURHOURS" | "FIVE" | "FIFTEEN";
    status: "NEW" | "WAITING" | "PRE_BET" | "SHOW_BETS" | "MAKE_BET" | "STARTED" | "ENDED";
    currencies: "USDTERC20" | "BTRT";
    created: Date;
    started: number[];
    stopped: Date;
    all: number;
    waiting: number;
    bitrollPrice: number;
    bitrollPrizePool: number;
    startPlan: number;
    timeToStart: number;
    amount: number;
    previewLogos: string[];
}

export type RoomInfo = Room & PrizePoolType;

export type PrizePoolType = {
    prizePool: {
        data: PrizePoolDataType[];
    }
}

export type PrizePoolDataType = {
    description: string;
    prize: string;
}

const initialState: RoomsState = {
    stateRooms: "idle",
    stateRoomInfo: "idle",
    stateSitAndGoBtrt: "idle",
    stateActiveRooms: "idle",
    stateBitrollsRooms: "idle",
    rooms: [],
    sitAndGoBtrt: [],
    bitrollsRooms: [],
    activeRooms: [],
    roomInfo: null,
}

export const roomSitSlice = createSlice({
    name: 'roomSit',
    initialState: initialState,
    reducers: {
        clearSITGORooms(state){
            state.stateRooms = "idle";
            state.rooms = [];
        },
        clearActiveRoom(state) {
            state.stateActiveRooms = "idle";
            state.activeRooms = [];
        },
        clearRoomInfo(state){
            state.roomInfo = null;
            state.stateRoomInfo = "idle";
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getRoomInfo.fulfilled, (state, action: PayloadAction<RoomInfo>) => {
                state.roomInfo = action.payload;
                state.stateRoomInfo = "success";
            })
            .addCase(getRoomInfo.rejected, (state) => {
                state.stateRoomInfo = "error";
            })
            .addCase(getRoomInfo.pending, (state) => {
                state.stateRoomInfo = "loading";
            })
            //BITROLL
            .addCase(getBitrollsRoom.fulfilled, (state, action: PayloadAction<Room[]>) => {
                state.stateBitrollsRooms = "success";
                state.bitrollsRooms = action.payload;
            })
            .addCase(getBitrollsRoom.rejected, (state) => {
                state.stateBitrollsRooms = "error";
            })
            .addCase(getBitrollsRoom.pending, (state) => {
                state.stateBitrollsRooms = "loading";
            })
            //SIT AND GO BTRT
            .addCase(getSitAndGoBTRTRooms.fulfilled, (state, action: PayloadAction<Room[]>) => {
                state.stateSitAndGoBtrt = "success";
                state.sitAndGoBtrt = action.payload;
            })
            .addCase(getSitAndGoBTRTRooms.rejected, (state) => {
                state.sitAndGoBtrt = [];
                state.stateSitAndGoBtrt = "error";
            })
            .addCase(getSitAndGoBTRTRooms.pending, (state) => {
                state.stateSitAndGoBtrt = "loading";
            })
            //ACTIVE ROOMS
            .addCase(getActiveRooms.fulfilled, (state, action: PayloadAction<ActiveRoom[]>) => {
                state.activeRooms = action.payload;
                state.stateActiveRooms = "success";
            })
            .addCase(getActiveRooms.pending, (state) => {
                state.stateActiveRooms = "loading";
            })
            .addCase(getActiveRooms.rejected, (state) => {
                state.stateActiveRooms = "error"
            })
            //SIT AND GO
            .addCase(getSITGORooms.fulfilled, (state, action: PayloadAction<Room[]>) => {
                state.stateRooms = "success";
                state.rooms = action.payload;
            })
            .addCase(getSITGORooms.rejected, (state) => {
                state.rooms = [];
                state.stateRooms = "error";
            })
            .addCase(getSITGORooms.pending, (state) => {
                state.stateRooms = "loading";
            })
    }
});

export const getActiveRooms = createAsyncThunk('roomSit/active-rooms',
    async (data: { authToken: string }, thunkAPI) => {
        const { authToken } = data;
        const responce = sitRoomApi.getActiveRooms(authToken);
        if (axios.isAxiosError(responce)) {
            thunkAPI.rejectWithValue(responce);
        }
        else {
            return responce;
        }
    }
);

export const getSITGORooms = createAsyncThunk('roomSit/sitgo',
    async (data: { authToken: string, players: string, filter: string, time: string }, thunkAPI) => {
        const { authToken, players, filter, time } = data;
        const responce = sitRoomApi.getRooms(authToken, players, filter, time);
        if (axios.isAxiosError(responce)) {
            thunkAPI.rejectWithValue(responce);
        }
        else {
            return responce;
        }
    }
)

export const getRoomInfo = createAsyncThunk('roomSit/room-info',
    async (data: RoomInfoType, thunkAPI) => {
        try {
            const room = await sitRoomApi.getRoomInfo(data.authToken, data.roomId);
            return room;
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 401) {
                    thunkAPI.dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                }
            }
        }
    }
)

export const getBitrollsRoom = createAsyncThunk('roomsSit/bitrolls',
    async (data: string, thunkAPI) => {
        try {
            const rooms = await sitRoomApi.getBitrollsRooms(data);
            return rooms;
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 401) {
                    thunkAPI.dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                }
            }
        }
    }
)

export const getSitAndGoBTRTRooms = createAsyncThunk('roomSit/sit-and-go-btrt',
    async (data: { authToken: string, players: string, filter: string, time: string }, thunkAPI) => {
        try {
            const rooms = await sitRoomApi.getSitAndGoBTRTRooms(data.authToken, data.players, data.filter, data.time);
            return rooms;
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 401) {
                    thunkAPI.dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                }
            }
        }
    }
)

export default roomSitSlice.reducer;