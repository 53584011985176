import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
});

export const passwordSecurityApi = {
    changePassword(oldPassword: string, newPassword: string, confirmPassword: string, authToken: string) {
        return instance.post('/user/change-password',{
                oldPassword: oldPassword,
                newPassword: newPassword,
                confirmPassword: confirmPassword
            }, { headers: {'Authorization' : `Bearer ${authToken}`} })
            .then((response) => {
                return response.data;
        })
    },
    addPassword(newPassword: string, confirmPassword: string, authToken: string) {
        return instance.post('/user/update',{
                newPassword: newPassword,
                confirmPassword: confirmPassword
            }, { headers: {'Authorization' : `Bearer ${authToken}`} })
            .then((response) => {
                return response.data;
        })
    },
}