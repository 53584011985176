import styled from "styled-components";
import { themes } from "../../../themes/theme";

export const BlurTopPersonalAccount = styled.div`
position: absolute;
width: 100%;
height: 310px;
left: 0px;
top: 0px;

background: linear-gradient(0deg, rgba(183, 94, 213, 0.1) 54%, rgba(34, 255, 54, 0.1) 150%);
filter: blur(45px);
border-radius: 170px;
`;

export const BlurMiddlePersonalAccount = styled.div`
position: absolute;
width: 100%;
height: 615px;
left: 0px;
top: 67px;

background: linear-gradient(0deg, rgba(183, 94, 213, 0.1) 53.97%, rgba(34, 255, 54, 0.1) 150%);
filter: blur(45px);
border-radius: 170px;
`;

export const PersonalCard = styled.div`
display: flex;
justify-content: center;
box-sizing: border-box;
width: 100%;
position: relative;
height: 250px;
padding: 0px 28px;
background: linear-gradient(128.69deg, rgba(183, 94, 213, 0.2) 39.51%, rgba(13, 198, 160, 0.2) 141.45%), #1C1C1C;
backdrop-filter: blur(2.5px);
border-radius: 20px;
&:before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 20px;
  border: 2px solid transparent;
  background: linear-gradient(180deg, #835C7C, #240203) border-box;
  mask: 
  linear-gradient(#fff 0 0) padding-box, 
  linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude;
    opacity: 0.5;
}
`;
export const TableRow = styled.div`
display: flex;
width: 100%;
flex-direction: row;
height: 38px;
align-items: center;
& > :nth-child(1) {
    justify-content: flex-start;
}
& > :nth-child(2) {
  justify-content: center;
}
& > :nth-child(3) {
  justify-content: flex-end;
}
`

export const TableColumn = styled.div`
display: flex;
flex: 1 1;
`

export const Line = styled.div`
    width: 100%;
    height: 1px;
    background: ${themes.colors.foreground};
`

