import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "..";

import IconLanguage from "../../images/language.svg";
import { SelectWithImageData } from "../../сomponents/select-with-image/select-with-image";
import { settingsApi } from "../../api/settings-api";
import { AxiosError } from "axios";
import { appStatusSlice } from "./app-status-slice";


export type SettingsInfo = {
    language: SelectWithImageData;
}

export type LogoInfo = {
    url: string;
    id: number;
}

export type SettingsState = {
    settings: SettingsInfo;
    isOpenActiveGames: boolean;
    languages: SelectWithImageData[];
    timeZones: string[];
    logos: LogoInfo[];
    loadingTimeZones: "idle" | "loading" | "success" | "error";
    loadingLanguages: "idle" | "loading" | "success" | "error";
    loadingLogos: "idle" | "loading" | "success" | "error";
}

const initialState: SettingsState = {
    settings: {
        language: { image: IconLanguage, text: 'English | USDT' },
    },
    isOpenActiveGames: false,
    languages: [{ image: IconLanguage, text: 'English | USDT' }],
    timeZones: ['(UTC+03:00) Москва, Санки-Петербург, Волгоград'],
    logos: [],
    loadingLanguages: 'loading',
    loadingTimeZones: 'loading',
    loadingLogos: 'loading'
}


export const settingsSlice = createSlice({
    name: 'settings',
    initialState: initialState,
    reducers: {
        setLanguage(state, action: PayloadAction<SelectWithImageData>) {
            state.settings.language = action.payload;
        },
        setTimeZones(state, action: PayloadAction<string[]>) {
            state.timeZones = action.payload;
        },
        setIsOpenActiveGames(state, action: PayloadAction<boolean>) {
            state.isOpenActiveGames = action.payload;
        },
        setLoadingTimeZones(state, action: PayloadAction<"idle" | "loading" | "success" | "error">) {
            state.loadingTimeZones = action.payload;
        },
        setLoadingLanguages(state, action: PayloadAction<"idle" | "loading" | "success" | "error">) {
            state.loadingLanguages = action.payload;
        },
        setLoadingLogos(state, action: PayloadAction<"idle" | "loading" | "success" | "error">) {
            state.loadingLogos = action.payload;
        },
        setLogos(state, action: PayloadAction<LogoInfo[]>) {
            state.logos = action.payload;
        }
    }
});

export const getTimeZonesActionCreater = (authToken: string) => {
    return (dispatch: AppDispatch) => {
        dispatch(settingsSlice.actions.setLoadingTimeZones('loading'));
        settingsApi.getTimeZones(authToken)
            .then(responce => {
                dispatch(settingsSlice.actions.setTimeZones(responce));
                dispatch(settingsSlice.actions.setLoadingTimeZones('success'));
            })
            .catch((err: AxiosError) => {
                dispatch(settingsSlice.actions.setLoadingTimeZones('error'));
                if (err.response?.status === 401) {
                    dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }));
                }
            })
    }
}

export const getLogosActionCreater = (authToken: string) => {
    return (dispatch: AppDispatch) => {
        dispatch(settingsSlice.actions.setLoadingLogos('loading'));
        settingsApi.getLogos(authToken)
            .then(responce => {
                dispatch(settingsSlice.actions.setLogos(responce));
                dispatch(settingsSlice.actions.setLoadingLogos('success'));
            })
            .catch((err) => {
                dispatch(settingsSlice.actions.setLoadingLogos('error'));
                if (err.response?.status === 401) {
                    dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }));
                }
            })
    }
}

export const goBackUrl = () => {
    console.log('backUrl');
}

export default settingsSlice.reducer;