import { FC, memo, useCallback, useEffect, useState } from "react";
import { RegistrationCreateView } from "./registration-create.view";
import { RegistrationCreateProps } from "./registration-create.props";
import { useRegistrationLogin } from "../registration-log-in/registration-log-in.props";
import { useAppDispatch, useTypedSelector } from "../../../hooks/use-typed-selector";
import { authActionCreater, registrationActionCreater, registrationSlice } from "../../../store/reducers/registration-slice";
import { verifySecuritySendActionCreater, verifySecuritySlice, verifySecurityVerifyActionCreater } from "../../../store/reducers/personal-account/security-verify";
import { UserTelegram, loginByTelegramActionCreater } from "../../../store/reducers/auth-slice";
import { useParams } from "react-router";

export const RegistrationCreate: FC<RegistrationCreateProps> = memo(() => {

    const state = useTypedSelector(state => state.registration);
    const { setConfirmPasswordActionCreater, setPassword, setUserName, setReferral } = registrationSlice.actions;
    const dispatch = useAppDispatch();
    const { cloud, isTelegram } = useTypedSelector(state => state.telegram);

    const refferalCodeParam = useParams().refferal;

    const onReferralCode = useCallback((value: string) => {
        dispatch(setReferral(value));
    }, [dispatch, setReferral]);
    
    //const dasboard = useLobby();
    const onDasboard = useCallback(() => {
        //dasboard({});
    }, [])

    useEffect(() => {
        if (localStorage.getItem("authToken") !== null) {
            onDasboard();
        }
    }, [onDasboard]);

    const resetData = useCallback(() => {
        dispatch(registrationSlice.actions.reset());
    }, [dispatch])

    const onRegister = useCallback((variant: "Email" | "Phone", onSuccessfully?: () => void) => {
        dispatch(registrationActionCreater(variant === 'Email' ? state.email : state.tel, state.password, state.confirmPassword, state.referralCode, variant, onSuccessfully));
    }, [dispatch, state.email, state.tel, state.password, state.confirmPassword, state.referralCode]);

    const onUserName = useCallback((variant: 'email' | 'tel', value: string) => {
        dispatch(setUserName({ variant: variant, value: value }));
    }, [dispatch, setUserName]);

    const onPassword = useCallback((value: string) => {
        dispatch(setPassword(value));
    }, [dispatch, setPassword]);

    const onConfirmPassword = useCallback((value: string) => {
        dispatch(setConfirmPasswordActionCreater(value));
    }, [dispatch, setConfirmPasswordActionCreater]);

    const registrationLogin = useRegistrationLogin();
    const onRegistrationLogin = useCallback(() => {
        registrationLogin();
    }, [registrationLogin]);

    const verifySecurityState = useTypedSelector(state => state.VerifySecurity);

    const sendCode = useCallback((value: 'app' | 'email' | 'sms' | 'none') => {
        dispatch(verifySecuritySendActionCreater(
            value === 'email' ? state.email : state.tel,
            state.tel,
            state.email,
            verifySecurityState.emailTimer,
            verifySecurityState.smsTimer,
            value,
            verifySecurityState.emailStatus,
            verifySecurityState.smsStatus));
    }, [
        dispatch,
        state.tel,
        state.email,
        verifySecurityState.emailTimer,
        verifySecurityState.smsTimer,
        verifySecurityState.emailStatus,
        verifySecurityState.smsStatus
    ])

    const setDigits = useCallback((value: [string, string, string, string, string, string]) => {
        dispatch(verifySecuritySlice.actions.setCodeActionCreater(value));
    }, [dispatch])

    const resetDataFromPopup = useCallback(() => {
        dispatch(verifySecuritySlice.actions.reset());
    }, [dispatch])

    const redirectAndReset = useCallback(() => {
        onDasboard();
        resetData();
    }, [onDasboard, resetData])

    const onSuccess = useCallback((value: 'sms' | 'email') => {
        dispatch(authActionCreater(value === 'sms' ? state.tel : state.email, state.password, redirectAndReset));
    }, [dispatch, redirectAndReset, state.tel, state.email, state.password])

    const confirmCodeClick = useCallback((value: 'app' | 'email' | 'sms' | 'none', onSuccessfully?: () => void) => {
        if (onSuccessfully) onSuccessfully();
        if (value === 'sms') {
            dispatch(verifySecurityVerifyActionCreater(
                state.tel,
                verifySecurityState.code,
                value,
                '',
                () => onSuccess('sms')));
        }
        if (value === 'email') {
            dispatch(verifySecurityVerifyActionCreater(
                state.email,
                verifySecurityState.code,
                value,
                '',
                () => onSuccess('email')));
        }
    }, [
        dispatch, onSuccess,
        state.tel, state.email,
        verifySecurityState.code
    ])

    const onAuthByTelegram = useCallback((user: UserTelegram) => {
        dispatch(loginByTelegramActionCreater(user,cloud,isTelegram,onDasboard));
    }, [dispatch,onDasboard,cloud,isTelegram]);

    const [tab, setTab] = useState<"Down" | "Left">("Down");
    const onReferalOpen = useCallback(() => {
        setTab(tab === "Down" ? "Left" : "Down");
    }, [tab])

    useEffect(() => {
        if(refferalCodeParam === undefined) return;
        onReferralCode(refferalCodeParam);
        setTab('Left');
    }, [onReferralCode,refferalCodeParam])

    return (
        <RegistrationCreateView
            tab={tab}
            onReferalOpen={onReferalOpen}
            verifySecurityState={verifySecurityState}
            sendCode={sendCode}
            onAuthByTelegram={onAuthByTelegram}
            setDigits={setDigits}
            confirmCodeClick={confirmCodeClick}
            onRegistrationLogin={onRegistrationLogin}
            state={state}
            resetData={resetData}
            onRegister={onRegister}
            resetDataFromPopup={resetDataFromPopup}
            onUserName={onUserName}
            onPassword={onPassword}
            onConfirmPassword={onConfirmPassword}
            onReferralCode={onReferralCode}
        />
    );
});