import styled from "styled-components";

export const StyledButton = styled.button<{}>`
  display: inline-block;
  cursor: pointer;
  user-select: none;
  background: transparent;
  border: none;
  box-shadow: none;
  padding:0;
  outline: none;
  background-color: transparent;
  transition: background-color 0.3s;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
`;