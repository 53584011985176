import styled from "styled-components";

export const StyledButton = styled.button<{}>`
  display: inline-block;
  cursor: pointer;
  user-select: none;
  background: transparent;
  border: none;
  box-shadow: none;
  padding:0;
  margin: 0;
  background-color: transparent;
  transition: background-color 0.3s;
`;