import styled from "styled-components";
import { themes } from "../../../themes/theme";


export const Blur = styled.div`
position: absolute;
width: 100%;
height: 615px;
left: 0px;
top: 67px;

background: linear-gradient(0deg, rgba(183, 94, 213, 0.1) 53.97%, rgba(34, 255, 54, 0.1) 150%);
filter: blur(45px);
border-radius: 170px;
`;

export const Line = styled.div`
    width: 33px;
    height: 1px;
    background: ${themes.colors.primary};
`

export const LineTable = styled.div`
    width: 100%;
    background: ${themes.colors.gray};
    height: 1px;
`