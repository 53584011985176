import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { swiperApi } from "../../../api/swiper-api";
import { appStatusSlice } from "../app-status-slice";
import axios from "axios";
import { swiperWheelApi } from "../../../api/swiper-wheel-api";


export type InitDataState = {
    win: null | SegmentState,
    count: number,
    all: SegmentState[]
}

export type SegmentState = {
    name: string,
    weight: number,
    prize: number,
    sector: number
}

export type SpinState ={
    id: number,
    description: string,
    cost: number,
    type: string,
    count: number,
    created: number[]
}

export type SwiperWheelState = {
    currentBuyInSpin: SpinState | null,
    accessibleSpins: SpinState[]
    currentAngle: number;
    segments: SegmentState[],
    costSpin: number,
    countOfSpins: number,
    loadingBuy: "idle" | "loading" | "success" | "error";
    loadingSpin: "idle" | "loading" | "success" | "error";
    loadingAllData: "idle" | "loading" | "success" | "error";
}

const initialState: SwiperWheelState = {
    currentBuyInSpin: null,
    accessibleSpins: [],
    currentAngle: 330,
    segments: [],
    costSpin: 0,
    countOfSpins: 0,
    loadingBuy: 'idle',
    loadingSpin: 'idle',
    loadingAllData: 'idle'
}

export const swiperWheelSlice = createSlice({
    name: 'swiper-wheel',
    initialState: initialState,
    reducers: {
        setCurrentAngle(state, action: PayloadAction<number>) {
            state.currentAngle = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(initWheelDataAction.fulfilled, (state, action: PayloadAction<InitDataState>) => {
                state.segments = action.payload.all.sort((a, b) => a.sector - b.sector);
                state.countOfSpins = action.payload.count;
                state.loadingAllData = "success";
            })
            .addCase(initWheelDataAction.pending, (state) => {
                state.loadingAllData = "loading";
            })
            .addCase(initWheelDataAction.rejected, (state) => {
                state.loadingAllData = "error";
            })
            .addCase(buySpinAction.fulfilled, (state, action: PayloadAction<InitDataState | null>) => {
                if(action.payload !== null){
                    state.segments = action.payload.all.sort((a, b) => a.sector - b.sector);
                    state.countOfSpins = action.payload.count;
                }
            })
            .addCase(spinWheelAction.fulfilled, (state, action: PayloadAction<InitDataState>) => {
                state.segments = action.payload.all.sort((a, b) => a.sector - b.sector);
                state.countOfSpins = action.payload.count;
            })
            .addCase(getAccessibleSpinsAction.fulfilled, (state, action: PayloadAction<SpinState[]>) => {
                state.currentBuyInSpin = findCheapestSpinState(action.payload || []) || null;
                state.accessibleSpins = action.payload;
            })
    }
});

export const initWheelDataAction = createAsyncThunk('swiper-wheel/init',
    async (data: { authToken: string }, thunkApi) => {
        const { authToken } = data;
        try {
            const responce = await swiperWheelApi.initData(authToken);
            return responce;
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 401) {
                    thunkApi.dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }));
                }
            }
        }
    }
)

export const buySpinAction = createAsyncThunk('swiper-wheel/buy-spin',
    async (data: { 
      authToken: string, 
      refreshUserBalance: () => void, 
      currentBuyInSpin: SpinState | null,
      getAccessibleSpins: () => void }, thunkApi) => {
        const { authToken, refreshUserBalance, currentBuyInSpin, getAccessibleSpins } = data;
        try {
            if(currentBuyInSpin === null) return;
            if(await swiperWheelApi.buySpinForWheel(authToken, currentBuyInSpin.id)){
                refreshUserBalance();
                getAccessibleSpins();
                return await swiperWheelApi.initData(authToken);
            };
            return null;
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 401) {
                    thunkApi.dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }));
                }
            }
        }
    }
)

export const getAccessibleSpinsAction = createAsyncThunk('swiper-wheel/accessible',
    async (data: { authToken: string }, thunkApi) => {
        const { authToken } = data;
        try {
            const responce = await swiperWheelApi.accessibleSpinsData(authToken);
            return responce;
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 401) {
                    thunkApi.dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }));
                }
            }
        }
    }
)

export const spinWheelAction = createAsyncThunk('swiper-wheel/spin',
    async (data: { 
        authToken: string, 
        rotateWheel: (sector: number) => void 
        refreshUserBalance: () => void, 
    }, thunkApi) => {
        const { authToken, rotateWheel, refreshUserBalance } = data;
        try {
            const responce = await swiperWheelApi.spinWheel(authToken);
            rotateWheel(responce.win.sector);
            refreshUserBalance();
            return responce;
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 401) {
                    thunkApi.dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }));
                }
            }
        }
    }
)

export default swiperWheelSlice.reducer;

export const findCheapestSpinState = (spinStates: SpinState[]): SpinState | undefined => {
    const validSpinStates = spinStates.filter(spinState => spinState.count !== 0);
  
    if (validSpinStates.length === 0) return undefined;
  
    return validSpinStates.reduce((minCostSpinState, currentSpinState) => {
      return currentSpinState.cost < minCostSpinState.cost ? currentSpinState : minCostSpinState;
    }, validSpinStates[0]);
  };