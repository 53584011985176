import styled, { css, keyframes } from "styled-components";
import { theme } from "../../themes/theme";

export const ButtonStyled = styled.button<{ width?: number | string, height?: number, variant: 'primary' | 'secondary' | 'tertiary' }>`
  width: ${({ width }) => width === undefined ? "auto" : typeof width === "number" ? `${width}px` : width};
  height: ${({ height }) => height ? height + "px" : "auto"};
  box-sizing: border-box;
  border-radius: 5px;
  max-width: 420px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;

  ${({ variant }) =>
    variant === 'primary' &&
    css`
      background-color: ${theme.colors.primary};
      border: 1px solid ${theme.colors.primary};
    `}

  ${({ variant }) =>
    variant === 'secondary' &&
    css`
      background-color: ${theme.colors.secondary};
      border: 1px solid ${theme.colors.secondary};
    `}

  ${({ variant }) =>
    variant === 'tertiary' &&
    css`
      background-color: transparent;
      border: 2px solid ${theme.colors.primary};
    `}
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const CircleLoading = styled.div<{ variant: 'primary' | 'secondary' | 'tertiary' }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  animation: ${rotate} 1s linear infinite;

  ${({ variant }) =>
    variant === 'primary' &&
    css`
      border: 2px solid transparent; /* Установите нужную толщину границы */
      border-top-color: ${theme.colors.background}; /* Цвет верхней части границы */
    `}

  ${({ variant }) =>
    variant === 'secondary' &&
    css`
      border: 2px solid transparent; /* Установите нужную толщину границы */
      border-top-color: ${theme.colors.secondary}; /* Цвет верхней части границы */
    `}

  ${({ variant }) =>
    variant === 'tertiary' &&
    css`
      border: 2px solid transparent;
      border-top-color: ${theme.colors.primary};
    `}
`;