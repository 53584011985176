export const termsAndConditionsTextPortuguese = [
  {
    header: <>Elegibilidade do Participante</>,
    text: <>
      Ao acessar ou utilizar os serviços da Bitrate, você afirma que é maior de idade
      em sua jurisdição, geralmente com no mínimo 18 anos de idade. A Bitrate se reserva
      o direito de verificar a idade e elegibilidade dos participantes e pode solicitar
      informações adicionais para esse fim.
    </>
  },
  {
    header: <>Processo de Registro</>,
    text: <>
      Para participar dos torneios da Bitrate, os usuários devem fornecer informações de 
      contato precisas e atualizadas, incluindo um número de telefone válido, endereço 
      de e-mail ou identificação no Telegram. A falta de informações corretas pode 
      resultar na suspensão ou encerramento da conta.
    </>
  },
  {
    header: <>Regras do Torneio</>,
    text: <>
      Todos os participantes devem se familiarizar e aderir às regras e regulamentos 
      descritos nas diretrizes do torneio da Bitrate. Ao participar, os usuários concordam
      em seguir essas regras, que podem incluir diretrizes de previsões, pontuações, prazos 
      e atividades proibidas.
    </>
  },
  {
    header: <>Prêmios e Recompensas</>,
    text: <>
      Os vencedores dos torneios da Bitrate receberão uma parte do prêmio, com uma 
      porcentagem alocada para a Bitrate para custos operacionais e desenvolvimento 
      futuro. A distribuição específica dos prêmios será detalhada na janela de cada 
      torneio.
    </>
  },
  {
    header: <>Taxas de Entrada e Pagamentos</>,
    text: <>
      A Bitrate aceita depósitos em criptomoeda para recarga de saldo do jogador. 
      Os usuários são responsáveis por cobrir qualquer taxa de transação associada 
      aos depósitos e retiradas. Ao participar, os usuários reconhecem que as 
      transações em criptomoeda são irreversíveis e a Bitrate não oferece reembolso 
      por qualquer motivo.
      <br/><br/>
      Em caso de solicitação de retirada em grande valor, a Bitrate se reserva o 
      direito de iniciar procedimentos de verificação adicionais, incluindo, mas 
      não se limitando, a verificações de Conheça Seu Cliente (KYC). Como parte 
      desse processo, os usuários podem ser solicitados a fornecer documentos 
      adicionais para verificar sua identidade e a legitimidade da transação. 
      Note que o processamento dessas retiradas pode levar de 6 a 24 horas para 
      ser concluído, dependendo do volume de solicitações e da complexidade do 
      processo de verificação. A Bitrate fará esforços razoáveis para agilizar 
      esses procedimentos e minimizar qualquer inconveniente ao usuário.
    </>
  },
  {
    header: <>Privacidade e Segurança de Dados</>,
    text: <>
      A Bitrate respeita a privacidade de seus usuários e está comprometida com 
      a proteção de suas informações pessoais. Dados de usuários e cookies são 
      coletados exclusivamente para facilitar o jogo e não são compartilhados com 
      terceiros sem consentimento explícito. A Bitrate emprega medidas de segurança 
      padrão da indústria para proteger as informações dos usuários.
    </>
  },
  {
    header: <>Direitos de Propriedade Intelectual</>,
    text: <>
      Todos os direitos de propriedade intelectual associados à plataforma da Bitrate, 
      incluindo, mas não se limitando, a marcas registradas, logotipos e algoritmos 
      proprietários, pertencem exclusivamente à Bitrate. Os usuários concordam em não 
      reproduzir, distribuir ou modificar qualquer conteúdo ou material sem o consentimento 
      prévio por escrito da Bitrate.
    </>
  },
  {
    header: <>Responsabilidade e Avisos Legais</>,
    text: <>
      Embora a Bitrate se esforce para fornecer uma experiência de jogo confiável e justa, 
      os usuários reconhecem que a participação em atividades baseadas em criptomoedas 
      envolve riscos inerentes. A Bitrate não é responsável por quaisquer perdas resultantes 
      de falhas de sistema, falhas técnicas, ataques de hackers ou flutuações nos mercados 
      de criptomoedas. Ao participar, os usuários assumem total responsabilidade por suas 
      ações e investimentos.
    </>
  },
  {
    header: <>Código de Conduta</>,
    text: <>
      Espera-se que os usuários ajam de forma consistente com práticas de jogo éticas e 
      justas. Qualquer tentativa de manipular ou explorar a plataforma da Bitrate, 
      incluindo, mas não se limitando a trapaças, fraudes ou conluio, resultará em suspensão 
      imediata ou encerramento da conta. A Bitrate se reserva o direito de tomar ações legais 
      contra os infratores.
    </>
  },
  {
    header: <>Modificação e Encerramento</>,
    text: <>
      A Bitrate se reserva o direito de modificar, suspender ou encerrar seus serviços ou 
      torneios a qualquer momento, sem aviso prévio. Em caso de encerramento dos serviços, 
      a Bitrate fará esforços razoáveis para notificar os usuários e facilitar a retirada 
      dos fundos restantes de suas contas.
    </>
  },
  {
    header: <>Conformidade Legal</>,
    text: <>
      Os usuários são responsáveis por cumprir todas as leis e regulamentos relevantes 
      em sua jurisdição em relação a atividades baseadas em criptomoedas e jogos online. 
      A Bitrate não aprova ou facilita comportamentos ilegais ou antiéticos e se reserva 
      o direito de relatar atividades suspeitas às autoridades competentes.
      <br/><br/>
      Ao acessar ou usar os serviços da Bitrate, você reconhece que leu, compreendeu e 
      concorda em cumprir estes termos e condições. Se você não concorda com qualquer parte 
      destes termos, não poderá acessar ou usar nossos serviços.
    </>
  }
]
