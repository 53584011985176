import { FC, memo } from "react";
import { Row } from "../row";
import { themes } from "../themes/theme";
import { Spacing } from "../spacing";
import { Text } from "../text";
import { Pressable } from "../pressable";
import { Line } from "./tab.styled";

export enum TabType {
  LEFT = "left",
  RIGHT = "right",
}

export type TabProps = {
  state: TabType;
  values: {
    left: { text: string; onClick: () => void };
    right: { text: string; onClick: () => void };
  };
};

export const Tab: FC<TabProps> = memo(({ state, values }) => {
  return (
    <Row style={{ width: "100%" }} horizontalAlign="center">
      <Pressable style={{ width: "auto" }} onClick={values.left.onClick}>
        <Text
          themeFont={themes.fonts.ht1}
          themeColor={
            state === TabType.LEFT
              ? themes.colors.primary
              : themes.colors.foreground
          }
        >
          {values.left.text}
        </Text>
        <Spacing variant="Column" themeSpace={10} />
        {state === TabType.LEFT && <Line />}
      </Pressable>
      <Spacing themeSpace={50} variant="Row" />
      <Pressable style={{ width: "auto" }} onClick={values.right.onClick}>
        <Text
          themeFont={themes.fonts.ht1}
          themeColor={
            state === TabType.RIGHT
              ? themes.colors.primary
              : themes.colors.foreground
          }
        >
          {values.right.text}
        </Text>
        <Spacing variant="Column" themeSpace={10} />
        {state === TabType.RIGHT && <Line />}
      </Pressable>
    </Row>
  );
});
