import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../../..";
import { appSecurityApi } from "../../../../api/security/app-security-api";
import { getUserInfoActionCreater } from "../../user-slice";
import { appStatusSlice } from "../../app-status-slice";

export type AppSecurityState = {
    authKey: string;
    qrCode: string;
    code: string;
    error: string | null;
    errorVerify: string | null;
    loading:  "idle" | "loading" | "success" | "error";
    isVerify: "idle" | "loading" | "success" | "error";
};

const initialState: AppSecurityState = {
    authKey: '',
    qrCode: '',
    code: '',
    error: null,
    loading: 'loading',
    errorVerify: null,
    isVerify: 'idle'
};


export const appSecuritySlice = createSlice({
    name: "app-security",
    initialState: initialState,
    reducers: {
        fetchErrorLoadingActionCreater(state, action: PayloadAction<string>) {
            state.error = action.payload;
            state.loading = 'error';
        },
        fetchSuccessLoadingActionCreater(state) {
            state.loading = 'success';
            state.error = null;
        },
        fetchLoadingActionCreater(state) {
            state.loading = "loading";
        },
        setQrCodeActionCreater(state, action: PayloadAction<string>) {
            state.qrCode = action.payload;
        },
        setAuthKeyActionCreater(state, action: PayloadAction<string>) {
            state.authKey = action.payload;
        },
        setCodeActionCreater(state, action: PayloadAction<string>) {
            state.code = action.payload;
        },
        setErrorIsVerifyActionCreater(state, action: PayloadAction<string>) {
            state.errorVerify = action.payload;
        },
        setIsVerifyActionCreater(state, action: PayloadAction<"idle" | "loading" | "success" | "error">) {
            state.isVerify = action.payload;
        },
        setSuccessVerifyActionCreater(state) {
            state.errorVerify = null;
        },
        
        reset(state) {
            state.authKey = '';
            state.qrCode = '';
            state.code = '';
            state.error = null;
            state.loading = 'loading';
            state.errorVerify = null;
        }
    }
});

export const getAuthKeySecurityActionCreater = (authToken: string) => {
    return (dispatch: AppDispatch) => {
        dispatch(appSecuritySlice.actions.fetchLoadingActionCreater());
        appSecurityApi.initApp(authToken)
            .then(responce => {
                dispatch(appSecuritySlice.actions.setAuthKeyActionCreater(responce.code));
                dispatch(appSecuritySlice.actions.setQrCodeActionCreater(responce.qr));
                dispatch(appSecuritySlice.actions.fetchSuccessLoadingActionCreater());
            })
            .catch((err) => {
                dispatch(appSecuritySlice.actions.fetchErrorLoadingActionCreater('error loading'));
                if(err?.responce.status === 401){
                    dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                }
            })
    }
}

export const confirmCodeAppActionCreater = (code: string, authToken: string,onSuccessfully?: () => void) => {
    return (dispatch: AppDispatch) => {
        dispatch(appSecuritySlice.actions.setIsVerifyActionCreater('loading'));
        appSecurityApi.confirmApp(code,authToken)
            .then(responce => {
                if(responce){
                    dispatch(appSecuritySlice.actions.setSuccessVerifyActionCreater());
                    if(onSuccessfully !== undefined) onSuccessfully();
                    dispatch(getUserInfoActionCreater(authToken));
                    dispatch(appSecuritySlice.actions.reset());
                    dispatch(appSecuritySlice.actions.setIsVerifyActionCreater('success'));
                } else {
                    dispatch(appSecuritySlice.actions.setErrorIsVerifyActionCreater('The code doesn’t work. Try again'));
                    dispatch(appSecuritySlice.actions.setIsVerifyActionCreater('error'));
                }
            })
            .catch((err) => {
                dispatch(appSecuritySlice.actions.setIsVerifyActionCreater('error'));
                dispatch(appSecuritySlice.actions.setErrorIsVerifyActionCreater('The code doesn’t work. Try again'));
                if(err?.responce.status === 401){
                    dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                }
            })
    }
}

export const disableTwoFactorApp = (authToken: string) => {
    return (dispatch: AppDispatch) => {
        appSecurityApi.disableApp(authToken)
            .then(responce => {
                dispatch(getUserInfoActionCreater(authToken));
                dispatch(appSecuritySlice.actions.reset());
            })
            .catch((err) => {
                dispatch(appSecuritySlice.actions.setErrorIsVerifyActionCreater('The code doesn’t work. Try again'));
                if(err?.responce.status === 401){
                    dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                }
            })
    }
}

export default appSecuritySlice.reducer;