import { walletApi } from "../../../api/wallet";

type Props = {
    authToken: string;
    currency_from: string;
}

export const apiGetMinAmountOfDeposit = async ({
    authToken,
    currency_from
}: Props) => {
    const response = await walletApi(authToken).getMinAmountOfDeposit(currency_from);
    if(response.error) throw response;

    return response.data;
}