
import { useNavigate } from 'react-router';

export type SitAndGoBtrtRoomsProps = {};
export const useSitAndGoBtrtRooms = () => {

    const navigate = useNavigate();
    const sitAndGoBtrtRooms = (props?: SitAndGoBtrtRoomsProps) => {
        navigate('/sit-and-go-btrt-rooms', { state: props });
    };

    return sitAndGoBtrtRooms;
};