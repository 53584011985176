import styled from "styled-components";


export const Blur = styled.div<{ top?: number, bottom?: number, left?: number, right?: number }>`
position: absolute;
width: 156px;
height: 156px;

left: ${({ left }) => `${left}px`};
top:  ${({ top }) => `${top}px`};
right:  ${({ right }) => `${right}px`};
bottom:  ${({ bottom }) => `${bottom}px`};

background: linear-gradient(90deg, rgba(13, 198, 160, 0.15) 0%, rgba(210, 56, 176, 0.15) 100%);
filter: blur(62px);
`

export const SliderWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

export const SliderContainer = styled.div<{ currentIndex: number }>`
  display: flex;
  width: 100vw;
  height: 100%;
  transition: transform 0.5s ease-in-out;
  transform: ${({ currentIndex }) => `translateX(-${currentIndex * 100}vw)`};
`;

export const Slide = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  font-size: 2rem;
`;
