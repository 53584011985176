import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { swiperApi } from "../../../api/swiper-api";
import axios from "axios";
import { appStatusSlice } from "../app-status-slice";

export type SwiperLeaderboard = {
    leaders: Leader[];
    loading: "idle" | "loading" | "success" | "error";
}

export type Leader = {
    img: string;
    name: string;
    amount: string;
    place: number;
    currency: string;
}

const initialState: SwiperLeaderboard = {
    leaders: [],
    loading: "idle",
}

export const swiperLeaderboardSlice = createSlice({
    name: 'swiper-leaderboard',
    initialState: initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getLeaderboardAction.fulfilled, (state, action: PayloadAction<Leader[]>) => {
                state.leaders = action.payload;
                state.loading = "success";
            })
            .addCase(getLeaderboardAction.pending, (state) => {
                state.loading = "loading";
            })
            .addCase(getLeaderboardAction.rejected, (state) => {
                state.loading = "error";
            })
    }
})


export const getLeaderboardAction = createAsyncThunk('swiper-leaderboard/leaders',
    async (data: { authToken: string, filter: "PLAYERS" | "FARMERS" }, thunkApi) => {
        const { authToken, filter } = data;
        try {
            const responce = await swiperApi.getLeaderboard(authToken, filter);
            return responce;
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 401) {
                    thunkApi.dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }));
                }
            }
        }
    }
)

export default swiperLeaderboardSlice.reducer;

