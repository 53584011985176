import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
});

export const emailSecurityApi = {
    sendCodeOnEmail(userName: string, email: string, authToken: string) {
        return instance.post('/m2fa-email/send-code-email', null, { 
                params: {
                    email: email,
                    userName: userName
                },
                headers: {'Authorization' : `Bearer ${authToken}`}
            })
            .then((response) => {
                return response.data;
        })
    },
    verifySendedCodeOnEmail(userName: string, code: string, authToken: string) {
        return instance.post('/m2fa-email/verifym2fa', null, { 
                params: {
                    userName: userName,
                    code: code
                },
                headers: {'Authorization' : `Bearer ${authToken}`} 
            })
            .then((response) => {
                return response.data;
        })
    },
    updateEmail(email: string, authToken: string) {
        return instance.post('/user/update', {
            email: email
        }, { 
                headers: {'Authorization' : `Bearer ${authToken}`} 
            })
            .then((response) => {
                return response.data;
        })
    },
    disableEmail(authToken: string) {
        return instance.get('/m2fa-email/disable2fa-email', { 
            headers: {'Authorization' : `Bearer ${authToken}`} 
        })
            .then((response) => {
                return response.data;
        })
    },
}