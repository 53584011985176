import styled, { keyframes } from "styled-components";

export const LoadingScreen = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const logoAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.07);
  }
  100% {
    transform: scale(1);
  }
`;

export const StyledLogoLoading = styled.div<{ 
    width?: number, 
    height?: number,
    src: string
  }>`
    width: 400px;
    height: 400px;
    background-image: url(${props => props.src});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    animation: ${logoAnimation} 2s linear infinite;
    @media (max-width: ${800}px) {
        width: 220px;
        height: 220px;
    }
    @media (max-width: ${320}px) {
        width: 180px;
        height: 180px;
    }
`;