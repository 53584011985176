import { FC, HtmlHTMLAttributes, memo } from "react";
import { SurfaceStyled } from "./surface.styled";

export type SurfaceProps = {
    radius?: number | string;
    themeColor?: string;
    themeColorBorder? : string;
    width? : number | string;
    padding?: number | [number?, number?, number?, number?];
    height? : number;
    borderWidth?: number;
} & HtmlHTMLAttributes<HTMLElement>;

export const Surface : FC<SurfaceProps> = memo(({ 
  radius = 10,
  themeColor = '#1C1C1C',
  width,
  themeColorBorder = 'linear-gradient(180deg, #835C7C, #240203) border-box',
  height,
  padding,
  borderWidth,
  ...rest 
}) => {
  return(
    <SurfaceStyled borderWidth={borderWidth} padding={padding} borderColor={themeColorBorder} radius={radius} color={themeColor} width={width} height={height} {...rest}/>
  )
})