
import { FC, memo } from 'react';
import { Column } from '../../../../сomponents/column';
import taskBackground from '../../../../images/task-background.png';
import { BackgroundPanel, Blur, DescriptionSection, CustomGrid, ScrollContainer } from './swiper-task.styled';
import { Spacing } from '../../../../ui-kit/spacing';
import { Row } from '../../../../ui-kit/row';
import { Text } from '../../../../ui-kit/text';
import { Image } from '../../../../ui-kit/image';
import coin from "../../../../images/coin.svg";
import telegram from '../../../../images/telegram.png';
import { themes } from '../../../../themes/theme';
import { SimpleTask } from '../../../../ui-kit/simple-task';
import { Task } from '../../../../ui-kit/task';
import { DailyTask, SubTask } from '../../../../store/reducers/swiper/swiper-tasks-slice';
import { Translate } from '../../../../ui-kit/translate';

export type SwiperTaskViewProps = {
  tasks: DailyTask[];
  checkTask: (url: string, type: "SOCIAL" | "ACHIEVEMENTS") => void;
  claimSimpleTask: (id: number) => void;
  claimSectionTask: (id: number) => void;
  btrt: number;
  mainTask: SubTask[];
};
export const SwiperTaskView: FC<SwiperTaskViewProps> = memo(({ tasks, checkTask, btrt, mainTask, claimSimpleTask, claimSectionTask }) => {
  return (
    <Column style={{ height: "100vh" }} horizontalAlign="center">
      <Blur />
      <Panel
        btrt={btrt}
      />
      <ScrollContainer>
        <Column horizontalAlign="center" padding={[0, 24]}>
          <Text style={{ width: "100%" }} align="left" themeFont={themes.fonts.h2}>Bitrate</Text>
          <Spacing themeSpace={10} variant="Column" />
          <CustomGrid>
            {mainTask.map((item) =>
              <SimpleTask
                key={item.id}
                id={item.id}
                url={item.url}
                type={item.dailyTaskType}
                icon={telegram}
                bonus={item.bonus}
                title={item.title}
                isCompleted={item.completed}
                checkTask={checkTask}
                claim={claimSimpleTask}
              />
            )}
          </CustomGrid>
          <Spacing themeSpace={35} variant="Column" />
          <Text style={{ width: "100%" }} align="left" themeFont={themes.fonts.h2}><Translate keyWord='tasks.campaigns' /></Text>
          <Spacing themeSpace={10} variant="Column" />
          <CustomGrid>
            {tasks.map((item) =>
              <Task
                key={item.id}
                icon={telegram}
                value={item.value}
                maxValue={item.maxValue}
                title={item.title}
                subTask={item.subTask}
                checkTask={checkTask}
                claim={claimSectionTask}
              />
            )}
          </CustomGrid>
        </Column>
      </ScrollContainer>
    </Column>
  );
});


type PanelProps = {
  btrt: number;
}

const Panel: FC<PanelProps> = memo(({ btrt }) => {
  return (
    <Column horizontalAlign="center" style={{ position: "relative", height: 204, flexShrink: 0 }}>
      <BackgroundPanel src={taskBackground} />
      <Spacing themeSpace={45} variant="Column" />
      <Row verticalAlign="center" horizontalAlign="center" style={{ width: "100%" }}>
        <Image src={coin} size={20} />
        <Spacing themeSpace={10} variant="Row" />
        <Text themeFont={themes.fonts.h2}>{`${btrt} BTRT`}</Text>
      </Row>
      <Spacing themeSpace={5} variant="Column" />
      <Text themeFont={themes.fonts.ht1} themeColor={themes.colors.gray}>
        <Translate keyWord='tasks.coins' />
      </Text>
      <Spacing themeSpace={25} variant="Column" />
      <DescriptionSection>
        <Column horizontalAlign="center">
          <Spacing themeSpace={15} variant="Column" />
          <Text themeFont={themes.fonts.h2}>
            <Translate keyWord='tasks.title' />
          </Text>
          <Spacing themeSpace={6} variant="Column" />
          <Text align="center" themeFont={themes.fonts.ht1}>
            <Translate keyWord='tasks.subtitle.part1' />
            <br></br>
            <Translate keyWord='tasks.subtitle.part2' />
          </Text>
        </Column>
      </DescriptionSection>
    </Column>
  )
})


