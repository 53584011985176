export const privacyPolicyTextSpain = [
  {
    header: <>Introducción</>,
    text: <>
      Bitrate se compromete a proteger la privacidad de sus usuarios y cumple con
      todas las leyes, principios y normativas aplicables en materia de protección de datos.
      Esta Política de Privacidad describe los métodos de administración de información de
      Bitrate y los servicios relacionados.
    </>
  },
  {
    header: <>Consentimiento</>,
    text: <>
      Al utilizar los servicios de Bitrate, incluyendo el sitio web y la aplicación móvil,
      los usuarios aceptan los términos de esta Política de Privacidad y los métodos de
      administración de información. Bitrate puede proporcionar notificaciones adicionales
      relacionadas con prácticas de privacidad que complementarán esta Política de Privacidad.
    </>
  },
  {
    header: <>Cambios en la Política de Privacidad</>,
    text: <>
      Bitrate se reserva el derecho de modificar o actualizar esta Política de Privacidad
      en cualquier momento. Se recomienda a los usuarios revisar periódicamente esta política
      para conocer los posibles cambios. Se notificará a los usuarios sobre cambios significativos
      por correo electrónico.
    </>
  },
  {
    header: <>Aplicabilidad</>,
    text: <>
      Esta Política de Privacidad se aplica a todos los usuarios de los servicios de Bitrate,
      incluyendo personas físicas, comerciantes y entidades jurídicas. Los usuarios incluyen
      aquellos que usan y/o adquieren los servicios de Bitrate o los servicios relacionados.
    </>
  },
  {
    header: <>Información personal que recopilamos</>,
    text: <>
      Bitrate recopila solo la información necesaria para la prestación de sus servicios.
      Esto puede incluir:
      <ul style={{paddingLeft: 30, textIndent: 0, marginTop: 8}}>
        <li>Información de identificación: como correo electrónico, número de teléfono y datos de Telegram.
          En caso de ser necesario pasar por un proceso de verificación KYC, se puede requerir y recopilar
          información adicional de identificación.</li>
        <li>Información financiera: historial de transacciones y datos de pago.</li>
        <li>Cualquier otra información necesaria en base a la interacción del usuario con Bitrate.</li>
      </ul>
    </>
  },
  {
    header: <>Propósito de la recopilación de información</>,
    text: <>
      Bitrate recopila información personal para los siguientes fines:
      <ul style={{paddingLeft: 30, textIndent: 0, marginTop: 8}}>
        <li>Para mejorar la experiencia del usuario y personalizar los servicios.</li>
        <li>Para proteger a los usuarios contra actividades fraudulentas y garantizar la seguridad.</li>
        <li>Para cumplir con los requisitos normativos, incluyendo las normas de “Conozca a su cliente”
          (KYC) y contra el lavado de dinero (AML).</li>
        <li>Para ejecutar los términos y acuerdos con los usuarios.</li>
      </ul>
    </>
  },
  {
    header: <>Transferencia de información personal</>,
    text: <>
      Bitrate puede transferir información personal a:
      <ul style={{paddingLeft: 30, textIndent: 0, marginTop: 8}}>
        <li>Proveedores de servicios y socios involucrados en la prestación de servicios de Bitrate.</li>
        <li>Autoridades reguladoras para cumplir con obligaciones legales.</li>
        <li>Organizaciones que participan en transacciones comerciales o fusiones con Bitrate.</li>
      </ul>
    </>
  },
  {
    header: <>Protección de la información personal</>,
    text: <>
      Bitrate implementa medidas de seguridad para proteger los datos de los usuarios
      contra el acceso no autorizado, modificación o divulgación.
      Estas medidas incluyen medidas de seguridad físicas, electrónicas y procedimentales.
    </>
  },
  {
    header: <>Uso de cookies</>,
    text: <>
      Bitrate puede utilizar cookies para mejorar la experiencia del usuario
      y analizar el tráfico del sitio web. Los usuarios pueden gestionar
      la configuración de cookies a través de las opciones de su navegador.
    </>
  },
  {
    header: <>Derechos de los usuarios</>,
    text: <>
      Los usuarios tienen derecho a acceder, rectificar, oponerse y eliminar
      la información personal almacenada por Bitrate. Las solicitudes pueden enviarse
      a través de la información de contacto proporcionada.
    </>
  },
  {
    header: <>Contáctenos</>,
    text: <>
      Para preguntas relacionadas con esta Política de Privacidad o información personal,
      los usuarios pueden ponerse en contacto con Bitrate a través del centro de soporte.
    </>
  }
];
