import { walletApi } from "../../../api/wallet";

type Props = {
    amount: string;
    currency: string;
    address?: string;
    authToken: string;
}

export const apiPreWithdrawal = async ({
    amount,
    currency,
    address,
    authToken
}: Props) => {
    if (!address) return null;

    const response = await walletApi(authToken).preWithdrawal(amount, currency, address);

    if (response.error) throw response;
    return response.data;
}