import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type AppStatus = {
    status: "success" | "no-autorizate" | "idle";
}


const initialState : AppStatus = {
    status: "idle",
}

export const appStatusSlice = createSlice({
    name: 'app-status',
    initialState: initialState,
    reducers: {
        setStatusApp(state, action: PayloadAction<{ status: "success" | "no-autorizate" | "idle" }>){
             state.status = action.payload.status;
        },
        clearStatus(state){
            state.status = "idle";
        }
    }
})

export default appStatusSlice.reducer;