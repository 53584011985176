import { FC, ReactNode, memo } from "react";
import { LinkButtonStyled } from "./link-button.styles";
import { Text } from "../text/"
import { theme } from "../../themes/theme";


export type LinkButtonProps = {
    text: ReactNode;
    onClick?: () => void;
    themeColor?: string;
}

export const LinkButton : FC<LinkButtonProps> = memo(({ text, onClick, themeColor }) => 
    <LinkButtonStyled onClick={onClick}>
       <Text themeColor={themeColor !== undefined ? themeColor : theme.colors.primary} transform="uppercase" themeFont={theme.fonts.t}>{text}</Text>
    </LinkButtonStyled>
);