import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../..";
import { VerifySecurityApi } from "../../../api/verify-security-api";
import { walletApi } from "../../../api/wallet-api";
import { getNameCurrencyWithNetwork, walletSlice } from "./wallet-slice";
import { appStatusSlice } from "../app-status-slice";

export type VerifySecurityState = {
    code: [string,string,string,string,string,string];
    typeVerify: 'app' | 'sms' | 'email' | 'none';
    emailTimer: number;
    smsTimer: number;
    emailStatus: 'started' | 'waited' | 'sended';
    smsStatus: 'started' | 'waited' | 'sended';
    errorApp: null | string;
    errorSms: null | string;
    errorEmail: null | string; 
    intervalSms: NodeJS.Timer | null;
    intervalEmail: NodeJS.Timer | null;
    isVerify: "idle" | "loading" | "success" | "error";
}

const initialState: VerifySecurityState = {
    code: ['','','','','',''],
    typeVerify: 'none',
    emailTimer: 0,
    smsTimer: 0,
    emailStatus: 'waited',
    smsStatus: 'waited',
    errorApp: null,
    intervalSms: null,
    intervalEmail: null,
    errorSms: null,
    errorEmail: null,
    isVerify: 'idle'
}

export const verifySecuritySlice = createSlice({
    name: 'two-factor-security',
    initialState: initialState,
    reducers: {
        setCodeActionCreater(state, action: PayloadAction<[string,string,string,string,string,string]>) {
            state.code = action.payload;
        },
        setEmailTimerActionCreater(state, action: PayloadAction<{ time: number,interval: NodeJS.Timer | null}>) {
            if(action.payload.interval ===  null) {
                state.emailTimer = action.payload.time;
                return;
            }
            if(action.payload.time !== 0){
                state.emailTimer = action.payload.time;
            } else {
                state.emailTimer = 0;
                clearInterval(action.payload.interval);
                state.emailStatus = 'started';
            };
        },
        setSmsTimerActionCreater(state, action: PayloadAction<{ time: number,interval: NodeJS.Timer | null}>) {
            if(action.payload.interval ===  null) {
                state.smsTimer = action.payload.time;
                return;
            }
            if(action.payload.time !== 0){
                state.smsTimer = action.payload.time;
            } else {
                state.smsTimer = 0;
                clearInterval(action.payload.interval);
                state.smsStatus = 'started';
            };
        },
        setEmailStatusActionCreater(state, action: PayloadAction<'started' | 'waited' | 'sended'>) {
            state.emailStatus = action.payload;
        },
        setIntervalSms(state, action: PayloadAction<NodeJS.Timer | null>) {
            state.intervalSms = action.payload;
        },
        setIntervalEmail(state, action: PayloadAction<NodeJS.Timer | null>) {
            state.intervalEmail = action.payload;
        },
        setSmsStatusActionCreater(state, action: PayloadAction<'started' | 'waited' | 'sended'>) {
            state.smsStatus = action.payload;
        },
        setTypeVerifyActionCreater(state, action: PayloadAction<'app' | 'sms' | 'email' | 'none'>) {
            state.typeVerify = action.payload;
            state.code = ['','','','','',''];
            state.errorApp = null;
            state.errorEmail = null;
            state.errorSms = null;
        },
        setErrorSmsActionCreater(state, action: PayloadAction<string | null>) {
            state.errorSms = action.payload;
        },
        setErrorEmailActionCreater(state, action: PayloadAction<string | null>) {
            state.errorEmail = action.payload;
        },
        setErrorAppActionCreater(state, action: PayloadAction<string | null>) {
            state.errorApp = action.payload;
        },
        setIsVerifyActionCreater(state, action: PayloadAction<"idle" | "loading" | "success" | "error">) {
            state.isVerify = action.payload;
        },
        reset(state) {
            state.code = ['','','','','',''];
            state.typeVerify = 'none';
            state.emailTimer = 0;
            state.smsTimer = 0;
            state.emailStatus = 'waited';
            state.smsStatus = 'waited';
            state.errorApp = null;
            state.errorSms = null;
            state.errorEmail = null;
            state.isVerify = 'idle';
            if(state.intervalEmail !==  null){
                clearInterval(state.intervalEmail);
            }
            if(state.intervalSms !==  null){
                clearInterval(state.intervalSms);
            }
            
        },
    }
})

export const verifySecuritySendActionCreater = (
    userName: string,
    phone: string | null,
    email: string | null,
    emailTimer: number,
    smsTimer: number,
    typeVerify: 'app' | 'sms' | 'email' | 'none',
    emailStatus: 'started' | 'waited' | 'sended',
    smsStatus: 'started' | 'waited' | 'sended',
    ) => {
    return (dispatch: AppDispatch) => {
        if(typeVerify === 'none' || typeVerify === 'app') return;

        switch(typeVerify){
            case 'email': 
                if(emailTimer !== 0 || email === null || email === 'null' || emailStatus === 'sended') return;
                dispatch(verifySecuritySlice.actions.setEmailStatusActionCreater('sended'));

                VerifySecurityApi.sendEmail(userName, email)
                    .then(responce => {
                        dispatch(verifySecuritySlice.actions.setErrorEmailActionCreater(null));

                        dispatch(verifySecuritySlice.actions.setEmailTimerActionCreater({time: 30, interval: null}));     

                        let secound: number = 30;
                        let intervalId: NodeJS.Timer = setInterval(() => {
                        if (secound > 0) { 
                            dispatch(verifySecuritySlice.actions.setEmailTimerActionCreater({time: --secound, interval: intervalId}))} else { clearInterval(intervalId); }
                        }, 1000);
                        dispatch(verifySecuritySlice.actions.setIntervalEmail(intervalId));
                    })
                    .catch((error) => {
                        dispatch(verifySecuritySlice.actions.setEmailTimerActionCreater({time: 0, interval: null}));
                        dispatch(verifySecuritySlice.actions.setErrorEmailActionCreater('Code not sended'));
                    })
                break;
            case 'sms': 
                if(smsTimer !== 0 || phone === null || phone === 'null' || smsStatus === 'sended') return;
                dispatch(verifySecuritySlice.actions.setSmsStatusActionCreater('sended'));

                VerifySecurityApi.sendSms(userName ,phone)
                    .then(responce => {
                        dispatch(verifySecuritySlice.actions.setErrorSmsActionCreater(null));

                        dispatch(verifySecuritySlice.actions.setSmsTimerActionCreater({time: 30, interval: null}));     

                        let secound: number = 30;
                        let intervalId: NodeJS.Timeout = setInterval(() => {
                        if (secound > 0) { 
                            dispatch(verifySecuritySlice.actions.setSmsTimerActionCreater({time: --secound, interval: intervalId}))} else { clearInterval(intervalId); }
                        }, 1000);
                        dispatch(verifySecuritySlice.actions.setIntervalSms(intervalId));
                    })
                .catch((error) => {
                    dispatch(verifySecuritySlice.actions.setSmsTimerActionCreater({time: 0, interval: null}));
                    dispatch(verifySecuritySlice.actions.setErrorSmsActionCreater('Code not sended'));
                    if(error?.responce.status === 401){
                        dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                    }
                })
                break;
        }
    }
}

export const verifySecurityVerifyActionCreater = (
    userName: string,
    code: string[],
    typeVerify: 'app' | 'sms' | 'email' | 'none',
    authToken: string,
    onSuccessfully?: () => void
    ) => {
    return (dispatch: AppDispatch) => {
        dispatch(verifySecuritySlice.actions.setIsVerifyActionCreater('loading'));
        let combinedCode: string = code.join('');
        switch(typeVerify){
            case 'email': 
                VerifySecurityApi.verifyEmail(userName, combinedCode)
                    .then(responce => {
                        if(responce.verified === true){
                            if(onSuccessfully !== undefined) onSuccessfully();
                            dispatch(verifySecuritySlice.actions.setIsVerifyActionCreater('success'));
                        } else {
                            dispatch(verifySecuritySlice.actions.setIsVerifyActionCreater('error'));
                            dispatch(verifySecuritySlice.actions.setErrorEmailActionCreater('The code doesn’t work. Try again'));
                        }
                    })
                    .catch((error) => {
                        dispatch(verifySecuritySlice.actions.setErrorEmailActionCreater('The code doesn’t work. Try again'));
                        dispatch(verifySecuritySlice.actions.setIsVerifyActionCreater('error'));
                        return false;
                    })
                break;
            case 'sms': 
                VerifySecurityApi.verifySms(userName, combinedCode)
                .then(responce => {
                    if(responce.verified === true){
                        if(onSuccessfully !== undefined) onSuccessfully();
                        dispatch(verifySecuritySlice.actions.setIsVerifyActionCreater('success'));
                    } else {
                        dispatch(verifySecuritySlice.actions.setIsVerifyActionCreater('error'));
                        dispatch(verifySecuritySlice.actions.setErrorSmsActionCreater('The code doesn’t work. Try again'));
                    }
                })
                .catch((error) => {
                    dispatch(verifySecuritySlice.actions.setErrorSmsActionCreater('The code doesn’t work. Try again'));
                    dispatch(verifySecuritySlice.actions.setIsVerifyActionCreater('error'));
                    return false;
                })
                break;
            case 'app': 
                VerifySecurityApi.verifyApp(userName, combinedCode,authToken)
                .then(responce => {
                    if(responce.verified === true){
                        if(onSuccessfully !== undefined) onSuccessfully();
                        dispatch(verifySecuritySlice.actions.setIsVerifyActionCreater('success'));
                    } else {
                        dispatch(verifySecuritySlice.actions.setIsVerifyActionCreater('error'));
                        dispatch(verifySecuritySlice.actions.setErrorAppActionCreater('The code doesn’t work. Try again'));
                    }
                })
                .catch((error) => {
                    dispatch(verifySecuritySlice.actions.setErrorEmailActionCreater('The code doesn’t work. Try again'));
                    dispatch(verifySecuritySlice.actions.setIsVerifyActionCreater('error'));
                    if(error?.responce.status === 401){
                        dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                    }
                })
                break;
            default:
                dispatch(verifySecuritySlice.actions.setErrorEmailActionCreater('The code doesn’t work. Try again'));
                dispatch(verifySecuritySlice.actions.setIsVerifyActionCreater('error'));
        }
    }
}

export const sendPreWithdrawalActionCreater = (
    amount: string,
    address: string,
    authToken: string,
    emailTimer: number,
    smsTimer: number,
    typeVerify: 'app' | 'sms' | 'email' | 'none',
    emailStatus: 'started' | 'waited' | 'sended',
    smsStatus: 'started' | 'waited' | 'sended',
    currency: string,
    network: string,
    onSuccess?: () => void
    ) => {
    return (dispatch: AppDispatch) => {
        dispatch(verifySecuritySlice.actions.setTypeVerifyActionCreater(typeVerify));

        let currencyWithNetwork = getNameCurrencyWithNetwork(currency,network);

        if(typeVerify === 'none' || typeVerify === 'app') return;

        switch(typeVerify){
            case 'email': 
                if(emailTimer !== 0 || emailStatus === 'sended') return;
                dispatch(verifySecuritySlice.actions.setEmailStatusActionCreater('sended'));

                walletApi.preWithdrawal(amount, currencyWithNetwork, address,authToken)
                    .then(responce => {
                        if(onSuccess) onSuccess();
                        dispatch(walletSlice.actions.setStateConfrimWithdrawal('success'));

                        dispatch(verifySecuritySlice.actions.setErrorEmailActionCreater(null));

                        dispatch(verifySecuritySlice.actions.setEmailTimerActionCreater({time: 30, interval: null}));     

                        let secound: number = 30;
                        let intervalId: NodeJS.Timer = setInterval(() => {
                        if (secound > 0) { 
                            dispatch(verifySecuritySlice.actions.setEmailTimerActionCreater({time: --secound, interval: intervalId}))} else { clearInterval(intervalId); }
                        }, 1000);

                        dispatch(verifySecuritySlice.actions.setIntervalEmail(intervalId));
                    })
                    .catch((error) => {
                        dispatch(verifySecuritySlice.actions.setEmailTimerActionCreater({time: 0, interval: null}));
                        dispatch(walletSlice.actions.setStateConfrimWithdrawal('error'));
                        dispatch(verifySecuritySlice.actions.setErrorEmailActionCreater('Code not sended'));
                        if(error?.responce.status === 401){
                            dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                        }
                    })
                break;
            case 'sms': 
                if(smsTimer !== 0 || smsStatus === 'sended') return;
                dispatch(verifySecuritySlice.actions.setSmsStatusActionCreater('sended'));

                walletApi.preWithdrawal(amount,currencyWithNetwork, address,authToken)
                    .then(responce => {
                        if(onSuccess) onSuccess();
                        dispatch(walletSlice.actions.setStateConfrimWithdrawal('success'));

                        dispatch(verifySecuritySlice.actions.setErrorSmsActionCreater(null));

                        dispatch(verifySecuritySlice.actions.setSmsTimerActionCreater({time: 30, interval: null}));     

                        let secound: number = 30;
                        let intervalId: NodeJS.Timeout = setInterval(() => {
                        if (secound > 0) { 
                            dispatch(verifySecuritySlice.actions.setSmsTimerActionCreater({time: --secound, interval: intervalId}))} else { clearInterval(intervalId); }
                        }, 1000);

                        dispatch(verifySecuritySlice.actions.setIntervalSms(intervalId));
                    })
                .catch((error) => {
                    dispatch(walletSlice.actions.setStateConfrimWithdrawal('error'));

                    dispatch(verifySecuritySlice.actions.setSmsTimerActionCreater({time: 0, interval: null}));
                    dispatch(verifySecuritySlice.actions.setErrorSmsActionCreater('Code not sended'));
                    if(error?.responce.status === 401){
                        dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                    }
                })
                break;
        }
    }
}

export default verifySecuritySlice.reducer;