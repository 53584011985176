import { FC, memo, useCallback, useEffect, useMemo } from "react";
import { PersonalAccountProps } from "./personal-account.props";
import { PersonalAccountView } from "./personal-account.view";
import { useNavigate } from "react-router";
import { useUser } from "../../../hooks/user-hook";
import {
  useAppDispatch,
  useTypedSelector,
} from "../../../hooks/use-typed-selector";
import {
  getLogosActionCreater,
  personalAccountSlice,
  updatePlayerNameActionCreater,
} from "../../../store/reducers/new/personal-account-slice";
import {
  updateLanguageOfUser,
  updatePictureOfUser,
} from "../../../store/reducers/user-slice";
import { useActiveRooms } from "../../../hooks/rooms-hook";
import { useTelegramBack } from "../../../hooks/telegram-button";
import { usePnlUser } from "../../../hooks/pnl.hook";
import { useWallet } from "../wallet/wallet.props";

export const PersonalAccount: FC<PersonalAccountProps> = memo(() => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { user, authToken, updateLocaleState } = useUser();
  const personalAccountState = useTypedSelector(
    (state) => state.personalAccount
  );
  const { rooms, getRooms } = useActiveRooms();
  const { pnlUserInfo } = usePnlUser();
  const sitAndGoRooms = useMemo(
    () =>
      rooms.filter(
        (item: { type: string; status: string }) =>
          item.type === "SITGO" &&
          item.status !== "NEW" &&
          item.status !== "ENDED"
      ),
    [rooms]
  );
  const bitrollRooms = useMemo(
    () =>
      rooms.filter(
        (item) =>
          item.type !== "SITGO" &&
          item.status !== "NEW" &&
          item.status !== "ENDED"
      ),
    [rooms]
  );

  const getLogos = useCallback(() => {
    dispatch(getLogosActionCreater(authToken));
  }, [dispatch, authToken]);

  const updateUserAvatar = useCallback(
    (value: string) => {
      dispatch(updatePictureOfUser({ picture: value, authToken: authToken }));
    },
    [dispatch, authToken]
  );

  const updatePlayerName = useCallback(
    (onSuccess?: () => void) => {
      dispatch(
        updatePlayerNameActionCreater(
          personalAccountState.playerName,
          authToken,
          onSuccess
        )
      );
    },
    [dispatch, personalAccountState.playerName, authToken]
  );

  const setPlayerName = useCallback(
    (value: string) => {
      dispatch(personalAccountSlice.actions.setPlayerNameActionCreater(value));
    },
    [dispatch]
  );

  const initPlayerName = useCallback(() => {
    setPlayerName(user.playerName);
  }, [setPlayerName, user.playerName]);

  const { getBTRTBalance } = useUser();

  useEffect(() => {
    initPlayerName();
  }, [initPlayerName]);

  const openPage = useCallback(
    (url: string) => {
      navigate(url);
    },
    [navigate]
  );

  const updateLanguage = useCallback(
    (value: string, onClose?: () => void) => {
      dispatch(
        updateLanguageOfUser({ language: value, authToken: authToken, onClose })
      );
    },
    [dispatch, authToken]
  );

  const onGoRoom = useCallback(
    (type: string, roomId: number, currency: string) => {
      if (type === "SITGO") {
        navigate(`/sit-and-go/${roomId}/${currency}`);
      } else {
      }
    },
    [navigate]
  );

  const onBack = useCallback(() => {
    navigate("/");
  }, [navigate]);
  useTelegramBack(onBack);

  const walletRoute = useWallet();
  const onWallet = useCallback(() => {
    walletRoute();
  }, [walletRoute]);

  return (
    <PersonalAccountView
      btrt={getBTRTBalance}
      locale={user.language}
      user={user}
      updateLocaleState={updateLocaleState}
      setPlayerName={setPlayerName}
      updateUserAvatar={updateUserAvatar}
      loadingLogos={personalAccountState.loadingLogos}
      logos={personalAccountState.logos}
      updatePlayerName={updatePlayerName}
      getLogos={getLogos}
      updateLanguage={updateLanguage}
      playerName={personalAccountState.playerName}
      sitgoRooms={sitAndGoRooms}
      bitrollRooms={bitrollRooms}
      openPage={openPage}
      onGoRoom={onGoRoom}
      getRooms={getRooms}
      amountGame={pnlUserInfo?.allCount || 0}
      amountWin={pnlUserInfo?.winCount || 0}
      onWallet={onWallet}
    />
  );
});
