import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
});

export const sitRoomApi = {
    getRooms(authToken: string, players: string, filter: string, time: string) {
        return instance.post('/game/lobby2', {
            time: time,
            filter: filter,
            players: players,
        },
            {
                headers: { 'Authorization': `Bearer ${authToken}` },
            }
        )
            .then((responce) => {
                return responce.data;
            })
    },
    getRoomInfo(authToken: string, roomId: number) {
        return instance.get('/game/room', {
            headers: { 'Authorization': `Bearer ${authToken}` },
            params: {
                roomId: roomId
            }
        }).then((responce) => responce.data);
    },
    getActiveRooms(authToken: string) {
        return instance.get('/game/active-rooms', {
            headers: { 'Authorization': `Bearer ${authToken}` },
        })
            .then((responce) => responce.data)
    },
    getBitrollsRooms(authToken: string) {
        return instance.post('/game/lobby-bitrolls', {}, {
            headers: { 'Authorization': `Bearer ${authToken}` },
        }).then(responce => responce.data);
    },
    getSitAndGoBTRTRooms(authToken: string, players: string, filter: string, time: string) {
        return instance.post('/game/lobby-sgo-btrt', {
            time: time,
            filter: filter,
            players: players,
        },
            {
                headers: { 'Authorization': `Bearer ${authToken}` },
            })
            .then(responce => responce.data);
    }
}