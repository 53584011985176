import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { InitDataParsed, CloudStorage, Utils, HapticFeedback, BackButton } from "@telegram-apps/sdk-react";
import { authApi } from "../../api/auth-api";
import { getUserInfoAction } from "./user-slice";

type TelegramState = {
    initData: InitDataParsed | undefined
    initDataRaw: string;
    loadingTelegram: "idle" | "loading" | "success" | "error" | "no-user";
    chatId: number;
    authToken: string;
    cloud: CloudStorage | undefined;
    isTelegram: boolean;
    utils: Utils | undefined
    hapticFeedback : HapticFeedback | undefined;
    backButton: BackButton | null;
};

const initialState: TelegramState = {
    initData: undefined,
    initDataRaw: "",
    chatId: 0,
    loadingTelegram: "idle" ,
    authToken: "",
    cloud: undefined,
    isTelegram: false,
    utils : undefined,
    hapticFeedback: undefined,
    backButton: null
};


export const telegramSlice = createSlice({
    name: "telegram",
    initialState: initialState,
    reducers: {
        setInitDatas(state, action: PayloadAction<{initData: InitDataParsed; initDataRaw: string, cloud: CloudStorage, chatId: number, utils: Utils, hapticFeedback: HapticFeedback, backButton: BackButton}>){
            state.initData = action.payload.initData;
            state.initDataRaw = action.payload.initDataRaw;
            state.cloud = action.payload.cloud;
            state.chatId = action.payload.chatId;
            state.hapticFeedback = action.payload.hapticFeedback;
            state.backButton = action.payload.backButton;
        },
        reset(state){
            state.loadingTelegram = "idle";
            state.authToken = "";
        },
        fetchErrorTelegramAuth(state){
            state.loadingTelegram = "error";
        },
        fetchNoUserTelegramAuth(state){
            state.loadingTelegram = "no-user";
        },
        isTelegram(state, action: PayloadAction<boolean>){
            state.isTelegram = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(loginTelegram.fulfilled, (state, action: PayloadAction<string>) => {
            state.authToken = action.payload;
            state.loadingTelegram = "success"
        })
        .addCase(loginTelegram.pending, (state) => {
            state.loadingTelegram = "loading"
        })
        .addCase(loginTelegram.rejected, (state) => {
            state.loadingTelegram = "error"
        })

    }
});

export const loginTelegram = createAsyncThunk('telegram/telegram-login',
    async(data: LoginTelegram, thunkApi) => {
      const { initData } = data;

      const responce = await authApi.loginTelegramAsync(initData);
      const authToken : string = responce.accessToken;

      thunkApi.dispatch(getUserInfoAction({ authToken: authToken }));

      return responce.accessToken;
    }
)

type LoginTelegram = {
    initData: string;
    chatId: number;
}

export default telegramSlice.reducer;