import axios from "axios"

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
});


export const refferalApi = {
    getRefferalsInfo(page: number,authToken: string) {
        return instance.post('/history/benefits', {
            page: page,
            size: 20
        },{ 
            headers: {'Authorization' : `Bearer ${authToken}`} })
            .then((response) => {
                return response.data;
            })
    },
}