
import { FC, memo, useEffect } from 'react';
import { SwiperTaskProps } from './swiper-task.props';
import { SwiperTaskView } from './swiper-task.view';
import { useTask } from '../../../../hooks/swiper/swiper-task.hook';
import { useUser } from '../../../../hooks/user-hook';

export const SwiperTask: FC<SwiperTaskProps> = memo(() => {
  const { getBTRTBalance } = useUser();
  const { tasks, resetCheck, checkTask, mainTask, claimSimpleTask, claimSectionTask, btrt } = useTask();

  useEffect(() => {
    return(() => resetCheck())
 }, [resetCheck])

  return (
    <SwiperTaskView
       mainTask={mainTask}
       tasks={tasks}
       checkTask={checkTask}
       btrt={btrt > 0 ? btrt : getBTRTBalance}
       claimSimpleTask={claimSimpleTask}
       claimSectionTask={claimSectionTask}
    />
    );
});
