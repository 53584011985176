import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
});

export const phoneSecurityApi = {
    sendCodeOnPhone(userName: string, phone: string, authToken: string) {
        return instance.post('/m2fa-sms/send-code-sms', null, { 
                params: {
                    phone: phone,
                    userName: userName
                },
                headers: {'Authorization' : `Bearer ${authToken}`}
            })
            .then((response) => {
                return response.data;
        })
    },
    verifySendedCodeOnPhone(userName: string, code: string, authToken: string) {
        return instance.post('/m2fa-sms/verifym2fa', null, { 
                params: {
                    userName: userName,
                    code: code
                },
                headers: {'Authorization' : `Bearer ${authToken}`} 
            })
            .then((response) => {
                return response.data;
        })
    },
    updatePhone(phone: string, authToken: string) {
        return instance.post('/user/update',{
            phone: phone 
            }, { headers: {'Authorization' : `Bearer ${authToken}`} })
            .then((response) => {
                return response.data;
            })
    },
    disablePhone(authToken: string) {
        return instance.get('/m2fa-sms/disable2fa-sms', { 
            headers: {'Authorization' : `Bearer ${authToken}`} 
        })
            .then((response) => {
                return response.data;
        })
    }
}