import styled, { css } from "styled-components";
import { FontProps, themes } from "../../../../ui-kit/themes/theme";
import { Column } from "../../../../ui-kit/column";

export const Blur = styled.div`
position: absolute;
width: 100%;
height: 100%;

background: linear-gradient(0deg, rgba(183, 94, 213, 0.1) 53.97%, rgba(34, 255, 54, 0.1) 150.62%);
filter: blur(45px);
transform: rotate(-180deg);
`;

export const GameParent = styled(Column)`
  height: 100dvh;
  overflow: hidden;
  position: relative;
`

export const Circle = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 40px;
height: 40px;
background-color: #24212B;
border: 1px solid #919095;
border-radius: 50%;
`;

export const VerticalLine = styled.div`
  height: 33px;
  width: 1px;
  background-color: ${themes.colors.foreground};
  opacity: 0.1;
`;

export const Grid = styled.div`
display: grid;
height: 100%;
gap: 40px;
justify-items: center;
width: 100%;
grid-template-rows: 1fr 3fr 1fr;
`;

export const GridRow = styled.div`
   display: grid;
   width: 100%;
   max-width: 230px;
   align-self: center;
   justify-content: space-around;
   grid-template-columns: repeat(auto-fit, 50px);;
   gap: 30px;
`

export const FooterGameBackground = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    width: 100%;
    max-width: 300px;
    padding: 0px 7px 0px 20px;
    height: 50px;
    align-items: center;
    box-shadow: 0 -3px 15px 0 rgba(186, 167, 255, 0.25);
    background: rgba(28, 28, 28, 0.6);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 50px;
    z-index: 100;
`;

type InputStyledProps = {
  font: FontProps;
  placeholderFont: FontProps;
  placeholderTextColor: string;
}

export const InputStyled = styled.input<InputStyledProps>`
  width: 100%;
  height: 100%;
  background: transparent;
  font-family: ${props => props.font.family};
  font-size:  ${props => props.font.desktopSize};
  font-weight: ${props => props.font.weight};
  color: ${props => props.color !== undefined ? props.color : themes.colors.foreground};
  border: 0px;
  &:focus {
        outline: none;
    }
  &::placeholder{
    font-family: ${props => props.placeholderFont.family};
    font-size:  ${props => props.font.desktopSize};
    font-weight: ${props => props.placeholderFont.weight};
  }
`

export const AccentBlock = styled.div<{isActive: boolean}>`
  width: 100%;
  display: flex;
  border-radius: 30px;

  ${({ isActive }) =>
    isActive
      ? css`
          animation: pulse 1.5s ease-in-out infinite;
        `
      : css`
          animation: none;
        `}

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(187, 187, 187, 0.4);
    }
    50% {
      box-shadow: 0 0 0 10px rgba(0, 0, 255, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(0, 0, 255, 0);
    }
  }

`