import { initBackButton, initHapticFeedback, initUtils, retrieveLaunchParams, useClosingBehavior, useCloudStorage, useSwipeBehavior, useViewport } from "@telegram-apps/sdk-react";
import { useEffect } from "react";
import { useAppDispatch } from "./hooks/use-typed-selector";
import { telegramSlice } from "./store/reducers/telegram-slice";
import { Root } from "./Root";

function App() {
  const dispatch = useAppDispatch();

  const { initData, initDataRaw } = retrieveLaunchParams();

  const cloud = useCloudStorage();
  const viewport = useViewport();
  const [backButton] = initBackButton();
  const closingBehavior = useClosingBehavior();
  const utils = initUtils();
  const hapticFeedback = initHapticFeedback();
  const swiper = useSwipeBehavior();

  useEffect(() => {
    viewport?.expand();
    closingBehavior.enableConfirmation();
    swiper.disableVerticalSwipe();
    if (initData && initDataRaw && hapticFeedback && cloud)
      dispatch(telegramSlice.actions.setInitDatas({
        initData: initData,
        initDataRaw: initDataRaw,
        cloud: cloud,
        chatId: initData.chat?.id || 0,
        utils: utils,
        hapticFeedback: hapticFeedback,
        backButton: backButton,
      }));
  }, [cloud, closingBehavior, dispatch, initData, initDataRaw, viewport, utils, hapticFeedback, backButton, swiper])

  return (
    <Root isTelegram={true} />
  );
}

export default App;
