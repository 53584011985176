export const gamesRulesTextPortuguese = [
  {
    header: <>Participação</>,
    text: <>
      A participação nos torneios está disponível apenas para usuários registrados,
      que tenham alcançado a maioridade de acordo com as leis e regulamentos de seus países.
    </>
  },
  {
    header: <>Torneios</>,
    text: <>
      Os torneios são realizados no formato SIT&GO e podem incluir 2, 4 ou 6 participantes,
      dependendo da categoria escolhida. Os torneios são filtrados pela duração e pelo valor
      da taxa de inscrição. A duração de um torneio pode ser de 5 minutos, 15 minutos, 1 hora
      ou 4 horas, e a taxa de inscrição varia de $2 a $100.
    </>
  },
  {
    header: <>Entrada no Torneio</>,
    text: <>
      Ao entrar em um torneio, o valor da taxa de inscrição é debitado do usuário, formando
      o prêmio do torneio. Os usuários podem sair do torneio e receber o reembolso da taxa de
      inscrição até que o número necessário de participantes seja atingido para o início do jogo.
    </>
  },
  {
    header: <>Previsões no Torneio</>,
    text: <>
      Assim que o número necessário de participantes é atingido, cada participante do torneio
      tem 30 segundos para fazer uma previsão sobre o preço do Bitcoin no final do cronômetro
      do torneio. Se o usuário não fizer uma previsão dentro desse tempo, sua previsão padrão
      será igual ao preço do Bitcoin no momento em que o cronômetro terminar.
    </>
  },
  {
    header: <>Determinação do Vencedor</>,
    text: <>
      O vencedor é o participante cujas previsões estiverem mais próximas do preço real
      do Bitcoin no final do cronômetro do torneio. Caso vários participantes façam a mesma
      previsão mais precisa, o prêmio do torneio será dividido igualmente entre eles.
      Os prêmios são indicados na janela do torneio.
    </>
  },
  {
    header: <>Jogo Justo</>,
    text: <>
      É proibido aos usuários utilizar métodos fraudulentos ou manipulações para melhorar suas
      chances de vitória. Qualquer tentativa de fraude ou violação das regras pode resultar
      em desqualificação e perda de acesso à conta.
    </>
  },
  {
    header: <>Resolução de Conflitos</>,
    text: <>
      Em caso de disputas ou mal-entendidos relacionados ao jogo, a administração da Bitrate
      tomará a decisão, que será final e obrigatória para todos os participantes.
      <br/><br/>
      <div style={{textIndent: 30}}>
        Estas regras garantem um ambiente de jogo justo e agradável para todos os
        participantes dos torneios em nosso aplicativo.
      </div>
    </>
  }
];
