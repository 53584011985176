import { FC, memo, useCallback, useState } from "react";
import { Surface } from "../surface";
import { Row } from "../row";
import { Spacing } from "../spacing";
import { Column } from "../column";
import { Pressable } from "../pressable";
import { Image } from "../image";
import { Text } from '../text';
import { themes } from "../themes/theme";
import completed from '../assets/completed.svg';
import { Button } from "../button";

export type SimpleTaskProps = {
  id: number;
  type: "SOCIAL" | "ACHIEVEMENTS";
  isCompleted: boolean;
  bonus: number;
  icon: string;
  title: string;
  url: string;
  checkTask: (url: string, type: "SOCIAL" | "ACHIEVEMENTS") => void;
  claim: (id: number) => void;
}
export const SimpleTask: FC<SimpleTaskProps> = memo(({ isCompleted, icon, bonus, title, checkTask, id, type, url, claim }) => {
  const [state, setState] = useState<boolean>(false);
  const onClick = useCallback(() => {
    setState(true);
    checkTask(url, type)
  }, [checkTask, type, url])

  const onClaim = useCallback(() => {
    if (state) {
      claim(id);
    }
  }, [claim, id, state])

  return (
    <Surface padding={[8, 13]}>
      <Row horizontalAlign="space-between" verticalAlign="center" style={{ height: "100%", width: "100%" }}>
        <Row style={{ width: "100%" }} verticalAlign="center">
          <Pressable style={{ width: "100%", zIndex: 10 }} onClick={onClick}>
            <Row verticalAlign="center">
              <Image src={icon} size={35} />
              <Spacing themeSpace={15} variant="Row" />
              <Column>
                <Text align="left" themeFont={themes.fonts.h3}>{title}</Text>
                <Spacing themeSpace={2} variant="Column" />
                <Text themeColor={themes.colors.secondary} themeFont={themes.fonts.ht2}>{isCompleted ? `${bonus} BTRT earned` : `+ ${bonus} BTRT`}</Text>
              </Column>
            </Row>
          </Pressable>
          {
            isCompleted ? <Image src={completed} size={16} />
              :
              <Button style={{ zIndex: 10 }} onClick={onClaim} variant="secondary" width={50} height={30}>
                <Text themeColor={themes.colors.background} themeFont={themes.fonts.ht2}>Claim</Text>
              </Button>
          }
        </Row>
      </Row>
    </Surface>
  )
})
