import React, { HtmlHTMLAttributes, ReactNode } from 'react';
import { StyledButton } from './pressable.styles';

type PressableProps = {
  onPress?: () => void;
  disabled?: boolean;
  children?: ReactNode;
} & HtmlHTMLAttributes<HTMLElement>;

export const Pressable: React.FC<PressableProps> = ({ onPress, children, disabled, ...rest }) => {
  
  return (
    <StyledButton
       onClick={onPress}
       disabled={disabled}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};
