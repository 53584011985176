import axios from "axios"

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
});


export const swiperWheelApi = {
    spinWheel(authToken: string) {
        return instance.post('/wheel/spin', null, {
            headers: { 'Authorization': `Bearer ${authToken}` }
        })
            .then((response) => {
                return response.data;
            })
    },
    buySpinForWheel(authToken: string, spinSettingId: number) {
        return instance.post('/wheel/buy-spin', null, {
            headers: { 'Authorization': `Bearer ${authToken}` },
            params:{
                spinSettingId: spinSettingId
            }
        })
            .then((response) => {
                return response.data;
            })
    },
    initData(authToken: string) {
        return instance.post('/wheel/init', null, {
            headers: { 'Authorization': `Bearer ${authToken}` }
        })
            .then((response) => {
                return response.data;
            })
    },
    accessibleSpinsData(authToken: string) {
        return instance.get('/wheel/accessible', {
            headers: { 'Authorization': `Bearer ${authToken}` }
        })
            .then((response) => {
                return response.data;
            })
    },
    getAccessible(authToken: string) {
        return instance.get('/wheel/accessible', {
            headers: { 'Authorization': `Bearer ${authToken}` }
        })
            .then((response) => {
                return response.data;
            })
    }
}