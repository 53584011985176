import styled from "styled-components";

export const BlockScreen = styled.div<{ isVisible: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    z-index: 1600;
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`;