import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notifiactionApi } from "../../api/notifiaction-api";
import axios from "axios";

export type NotifiactionItem = {
    id: number;
    type: string;
    description: string;
    time: string;
    read: boolean;
}

export type NotifiactionData = {
    content: NotifiactionItem[];
    pageable: {
        sort: {
            empty: boolean;
            sorted: boolean;
            unsorted: boolean;
        };
        offset: number;
        pageNumber: number;
        pageSize: number;
        paged: boolean;
        unpaged: boolean;
    };
    totalPages: number;
    totalElements: number;
    last: boolean;
    size: number;
    number: number;
    sort: {
        empty: boolean;
        sorted: boolean;
        unsorted: boolean;
    };
    numberOfElements: number;
    first: boolean;
    empty: boolean;
}

export type NotifiactionState = {
    error: string | null,
    loading: "idle" | "loading" | "success" | "error",
    data: NotifiactionData
}

const initialState: NotifiactionState = {
    loading: 'loading',
    error: null,
    data: {
        content: [],
        pageable: {
            sort: {
                empty: false,
                sorted: false,
                unsorted: false
            },
            offset: 0,
            pageNumber: 0,
            pageSize: 0,
            paged: false,
            unpaged: false
        },
        totalPages: 0,
        totalElements: 0,
        last: false,
        size: 0,
        number: 0,
        sort: {
            empty: false,
            sorted: false,
            unsorted: false,
        },
        numberOfElements: 0,
        first: false,
        empty: false,
    }
}

export const notifiactionSlice = createSlice({
    name: 'notifiaction',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getNotifiaction.fulfilled, (state, action: PayloadAction<NotifiactionData>) => {
                state.data = action.payload;
                state.loading = "success"
            })
            .addCase(getNotifiaction.rejected, (state) => {
                state.loading = "error"
            })
            .addCase(getNotifiaction.pending, (state) => {
                state.loading = "loading"
            })
    }
});

export const getNotifiaction = createAsyncThunk('notifiaction/all',
    async (data: { authToken: string }, thunkApi) => {
        const { authToken } = data;
        const responce = notifiactionApi.getNotifiaction(authToken);
        if (axios.isAxiosError(responce)) thunkApi.rejectWithValue(responce);
        else return responce;
    }
)

export default notifiactionSlice.reducer;