
import { FC, memo, useCallback } from 'react';
import { LobbyProps } from './lobby.props';
import { LobbyView } from './lobby.view';
import { useReferralHook } from '../../../../hooks/referral-hook';
import { useSitAndGoBtrtRooms } from '../../rooms/sit-and-go-btrt-rooms/sit-and-go-btrt-rooms.props';
import { useSitAndGoRooms } from '../../rooms/sit-and-go-rooms/sit-and-go-rooms.props';

export const Lobby: FC<LobbyProps> = memo(() => {
  const {
    refCode,
    onShare } = useReferralHook();

  const sitAndGoBtrtRooms = useSitAndGoBtrtRooms();
  const onSitAndGoBtrtRooms = useCallback(() => {
    sitAndGoBtrtRooms({});
  }, [sitAndGoBtrtRooms])

  const sitAndGoRooms = useSitAndGoRooms();
  const onSitAndGoRooms = useCallback(() => {
    sitAndGoRooms({});
  }, [sitAndGoRooms])

  return (
    <LobbyView
      refCode={refCode}
      onSitAndGoBtrtRooms={onSitAndGoBtrtRooms}
      onSitAndGoRooms={onSitAndGoRooms}
      onShare={onShare}
    />
  );
});
