export const gamesRulesTextSpain = [
  {
    header: <>Participación</>,
    text: <>
      La participación en los torneos está disponible solo para usuarios registrados
      que hayan alcanzado la mayoría de edad según las leyes y regulaciones de su país.
    </>
  },
  {
    header: <>Torneos</>,
    text: <>
      Los torneos se llevan a cabo en formato SIT&GO y pueden incluir 2, 4 o 6 participantes,
      dependiendo de la categoría elegida. Los torneos se filtran por duración y por el tamaño
      de la tarifa de entrada. La duración de un torneo puede ser de 5 minutos, 15 minutos, 
      1 hora o 4 horas, y la tarifa de entrada varía entre $2 y $100.
    </>
  },
  {
    header: <>Entrada al Torneo</>,
    text: <>
      Al ingresar a un torneo, se deduce del usuario el monto de la tarifa de entrada, 
      que forma el premio del torneo. Los usuarios pueden salir del torneo y recibir el 
      reembolso de la tarifa de entrada hasta que se alcance la cantidad necesaria de 
      participantes para comenzar el juego.
    </>
  },
  {
    header: <>Pronósticos en el Torneo</>,
    text: <>
      Una vez que se alcanza la cantidad necesaria de participantes, cada participante 
      del torneo tiene 30 segundos para hacer un pronóstico sobre el precio del Bitcoin 
      al final del temporizador del torneo. Si el usuario no realiza un pronóstico en 
      este tiempo, su pronóstico predeterminado será igual al precio del Bitcoin en el 
      momento en que el temporizador termine.
    </>
  },
  {
    header: <>Determinación del Ganador</>,
    text: <>
      Gana el participante cuyas predicciones estén más cerca del precio real del Bitcoin 
      al final del temporizador del torneo. En caso de que varios participantes hagan la 
      misma predicción más precisa, el premio del torneo se dividirá equitativamente entre ellos.
      Los premios se muestran en la ventana del torneo.
    </>
  },
  {
    header: <>Juego Justo</>,
    text: <>
      A los usuarios se les prohíbe utilizar métodos fraudulentos o manipulaciones para 
      mejorar sus posibilidades de ganar. Cualquier intento de fraude o violación de 
      las reglas puede resultar en la descalificación y pérdida de acceso a la cuenta.
    </>
  },
  {
    header: <>Resolución de Disputas</>,
    text: <>
      En caso de disputas o malentendidos relacionados con el juego, la administración 
      de Bitrate tomará la decisión, que será final y obligatoria para todos los participantes.
      <br/><br/>
      <div style={{textIndent: 30}}>
        Estas reglas garantizan un ambiente de juego justo y agradable para todos los
        participantes de los torneos en nuestra aplicación.
      </div>
    </>
  }
];
