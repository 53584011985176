
import { FC, memo, useCallback } from 'react';
import { EndGameProps } from './end-game.props';
import { EndGameView } from './end-game.view';
import { useParams } from 'react-router';
import { useGameResult } from '../../../../hooks/personal-account/statistics.hook';
import { useStatistics } from '../../statistics/statistics.props';
import { useTelegramBack } from '../../../../hooks/telegram-button';

export const EndGame: FC<EndGameProps> = memo(() => {
  const roomId = Number(useParams().roomId);
  const { gameResult } = useGameResult(roomId);

  const statistics = useStatistics();
  const back = useCallback(() => {
    statistics({});
  }, [statistics])
  useTelegramBack(back);

  return (
    <EndGameView back={back} time={gameResult?.timestamp || ''} rate={gameResult?.rate || 0} leaders={gameResult?.leaders || []} />
  );
});
