import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { pnlApi } from "../../api/pnl.api";
import axios from "axios";


export type PnlUserInfo = {
    pnlUsdt: string | null;
    pnlPercent: string | null;
    winCount: number;
    looseCount: string | null;
    allCount: number;
}

export type PnlState = {
    pnlUserInfo: PnlUserInfo | null;
    state: "idle" | "loading" | "success" | "error",
}

const initialState: PnlState = {
    pnlUserInfo: null,
    state: "idle"
}

export const pnlSlice = createSlice({
    name: 'pnl',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPnlUserInfo.fulfilled, (state, action: PayloadAction<PnlUserInfo>) => {
                state.pnlUserInfo = action.payload;
                state.state = "success"
            })
            .addCase(getPnlUserInfo.pending, (state) => {
                state.state = "loading";
            })
            .addCase(getPnlUserInfo.rejected, (state) => {
                state.state = "error"
            })
    }
})

export const getPnlUserInfo = createAsyncThunk('pnl/user-info',
    async (data: { authToken: string }, thunkApi) => {
        const { authToken } = data;
        const responce = await pnlApi.getPnlUserInfo(authToken);

        if (axios.isAxiosError(responce)) thunkApi.rejectWithValue(responce);
        else return responce;
    }
)

export default pnlSlice.reducer;

