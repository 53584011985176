import { useCallback, useEffect, useRef, useState } from "react"
import { useTypedSelector } from "./use-typed-selector"

export const useTimer = (callback: (state: boolean) => void, dailyMaxValue: number, interval = 1000) => {
    const { farm } = useTypedSelector(state => state.swiperFarm);
    const [time, setTime] = useState<number>(5000);
    const [recovery, setRecovery] = useState<number>(0);

    const setOldTime = useCallback(() => {
        if (farm.energy !== null) {

            if (-farm.energy > 0) callback(true);

            setTime((farm.energy === 0 ? 0 : 5 - (-farm.energy)) * 1000);
        }
    }, [callback, farm.energy])

    useEffect(() => {
        setOldTime();
    }, [setOldTime])

    const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null)

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            if (time > 0) {
                setTime((prev) => prev - interval)
            }
            else clearInterval(intervalRef.current as NodeJS.Timeout);
        }, interval);

        if (time === 0) callback(false);

        return () => clearInterval(intervalRef.current as NodeJS.Timeout);
    }, [callback, interval, time]);

    const setStartRecovery = useCallback(() => {
        if (farm.recovery !== null) {
            setRecovery(farm.recovery);
        }
    }, [farm.recovery])

    useEffect(() => {
        setStartRecovery();
    }, [setStartRecovery])

    const intervalRecoveryRef = useRef<ReturnType<typeof setInterval> | null>(null)

    useEffect(() => {
        intervalRecoveryRef.current = setInterval(() => {
            if (recovery < dailyMaxValue) {
                setRecovery((prev) => prev + 1)
            }
            else clearInterval(intervalRecoveryRef.current as NodeJS.Timeout);
        }, interval);

        return () => clearInterval(intervalRecoveryRef.current as NodeJS.Timeout);
    }, [recovery,interval,dailyMaxValue]);

    const reset = useCallback(() => {
        callback(true)
        setTime(5000);
    }, [callback])

    return {
        time: time,
        recovery: recovery,
        reset: reset,
    }
}