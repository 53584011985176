import styled from "styled-components";
import { themes } from "../themes/theme";

export const StyledRoomCardBackground = styled.div`
    max-width: 290px;
    width: 100%;
    background-color: ${themes.colors.background};
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
`

export const StyledRoomCardInfo = styled.div<{ borderRadius: string }>`
  position: relative;
  background-color: ${themes.colors.background};
  width: 70px;
  height: 44px;
  border-radius: ${(({ borderRadius }) => borderRadius)};
  &:before {
  content: "";
  position: absolute;
  inset: 0px;
  border-radius: ${(({ borderRadius }) => borderRadius)};
  border: 2px solid transparent;
  background: linear-gradient(180deg, #835C7C, #240203) border-box;
  mask: 
  linear-gradient(#fff 0 0) padding-box, 
  linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude;
    opacity: 0.5;
}
`;

export const StyledImageRoom = styled.img<{ zIndex: number }>`
    width: 50px;
    height: 50px;
    border-radius: 50%;
`