import { useTelegram } from "./telegram-hook";
import { useUser } from "./user-hook";
import { useCallback, useEffect } from "react";
import { useTypedSelector } from "./use-typed-selector";

export const useAuthentication = () => {
    const { getPWAUser, appStatus, redirectToLogin, loading } = useUser();
    const { getTelegramUser } = useTelegram();
    const { isTelegram } = useTypedSelector(state => state.telegram);

    const getUser = useCallback(() => {
        if (appStatus !== "success")
            if (isTelegram) {
                getTelegramUser();
            }
            else {
                getPWAUser();
            }
    }, [appStatus, getPWAUser, getTelegramUser, isTelegram]);

    const onRedirect = useCallback(() => {
        if (appStatus === "no-autorizate") {
            if (!isTelegram) {
                redirectToLogin();
            }
            else {
                getTelegramUser();
            }
        }
    }, [appStatus, getTelegramUser, isTelegram, redirectToLogin])

    useEffect(() => {
        getUser()
    }, [getUser]);

    useEffect(() => {
        onRedirect();
    }, [onRedirect])

    return {
        loading: loading,
        appStatus: appStatus
    }
}