import { FC, HTMLAttributes, memo } from "react";
import { BackgroundCircle } from "./circle.styled";

export type CircleProps = {
    size: number;
} & HTMLAttributes<HTMLDivElement>

export const Circle : FC<CircleProps> = memo(({ size, ...rest }) => {
    return(
        <BackgroundCircle size={size} {...rest} />
    )
})