import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
});

export const twoFactorApi = {
    verifyApp(userName: string, code: number) {
        return instance.post('/m2fa/verifym2fa', null, {
            params: {
                code: code,
                userName: userName
              }
        })
            .then(responce => responce.data);
    },
    verifySms(userName: string, code: number) {
        return instance.post('/m2fa-sms/verifym2fa', null, {
            params: {
                code: code,
                userName: userName
              }
        })
            .then(responce => responce.data);
    },
    welcome(userName: string, password: string){
        return instance.post('/auth/welcome', { userName: userName, password: password })
        .then(responce => responce.data);
    }
}