
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { SwiperProps } from './swiper.props';
import { SwiperView } from './swiper.view';
import { useTimer } from '../../../../hooks/timer.hook';
import { useFarm } from '../../../../hooks/swiper/swiper-farm.hook';
import { useTelegram } from '../../../../hooks/telegram-hook';
import { useUser } from '../../../../hooks/user-hook';
import { useTask } from '../../../../hooks/swiper/swiper-task.hook';
import { useLeaderboard } from '../../../../hooks/swiper/swiper-leaderboard';

export const Swiper: FC<SwiperProps> = memo(() => {
  const { refreshUserBalance } = useUser();
  const { farm, tap, intervalTap } = useFarm();
  const { hapticFeedback } = useTelegram();
  const { getMainTasks, getTasks } = useTask();
  const { onFilter } = useLeaderboard();

  const dailyMaxValue = farm.dailyMaxValue || 10000;

  const [disable, setDisable] = useState<boolean>(true);

  const onDisable = useCallback((state: boolean) => {
    setDisable(state);
  }, [])

  const vibrate = useCallback(() => {
    hapticFeedback?.impactOccurred("light");
  }, [hapticFeedback])

  const { time, reset, recovery } = useTimer(onDisable, dailyMaxValue)

  const swap = useCallback((callback: () => void) => {
    if (recovery - 10 > 0) {
      reset();
      vibrate();
      tap();
      callback();
      setDisable(true);
    }
  }, [recovery, reset, tap, vibrate]);

  useEffect(() => {
    return (() => refreshUserBalance())
  }, [refreshUserBalance])

  const onIntervalClick = useCallback(() => {
    if (recovery - 1 > 0) {
      intervalTap();
    }
  }, [recovery, intervalTap])

  useEffect(() => {
    getMainTasks();
    getTasks();
  }, [getMainTasks, getTasks]);

  useEffect(() => { 
    onFilter("FARMERS");
  }, [onFilter])

  return (
    <SwiperView
      onIntervalClick={onIntervalClick}
      daily={recovery || 0}
      time={time}
      onClick={swap}
      disable={disable}
      btrt={farm?.amountBtrt || 0}
      dailyMaxValue={dailyMaxValue}
    />
  );
});
