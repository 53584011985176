import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { lobbyApi } from "../../api/lobby-api";
import axios from "axios";
import { appStatusSlice } from "./app-status-slice";

export type Lobby = {
    timer: number;
}

const initialState : Lobby = {
    timer: 0
}

export const lobbySlice = createSlice({
    name: 'lobby',
    initialState: initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchTimerLobby.fulfilled, (state, action: PayloadAction<number>) => {
            state.timer = action.payload;
        })
        .addCase(fetchTimerLobby.rejected, (state) => {
            state.timer = 0;
        })
    }
})

export const fetchTimerLobby = createAsyncThunk('lobby/timer', 
    async(data: TimerLobby, thunkApi) => {
        const { authToken } = data;
        try{
            const responce = await lobbyApi.getTimeOfCloseBitroll(authToken);
            return responce;
        }
        catch(e){
            if(axios.isAxiosError(e)){
                if(e.response?.status === 401){
                    thunkApi.dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                }
            }
        }
    }
)

type TimerLobby = {
    authToken: string;
}

export default lobbySlice.reducer;