import { useCallback, useEffect, useRef, useState } from "react";
import { RoomInfo } from "../store/reducers/sit-and-go/rooms-slice";


type GameTimer = {
    difference: number;
    roomInfo: RoomInfo | null;
    status?: "NEW" | "WAITING" | "PRE_BET" | "SHOW_BETS" | "MAKE_BET" | "STARTED" | "ENDED"
}

export const useGameTimer = (props: GameTimer) => {

    const { roomInfo, status, difference } = props;

    const [time, setTime] = useState<number>(0);
    const timerRef = useRef<NodeJS.Timeout>();
    const startTimer = useCallback(() => {
        timerRef.current = setInterval(() => { setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0)); }, 1000);
    }, [timerRef]);

    const clearTimer = useCallback(() => clearInterval(timerRef.current), [timerRef]);

    useEffect(() => {
        switch (status) {
            case 'NEW':
                clearTimer();
                const newTime = roomInfo?.timeToStart !== undefined ? -roomInfo.timeToStart : 0;
                setTime(newTime > 0 ? newTime : 0);
                startTimer();
                break;
            case 'WAITING':
                clearTimer();
                const waitTime = roomInfo?.timeToStart !== undefined ? -roomInfo.timeToStart : 0;
                setTime(waitTime > 0 ? waitTime : 0);
                startTimer();
                break;
            case 'PRE_BET':
                clearTimer();
                setTime(10);
                startTimer();
                break;
            case 'MAKE_BET':
                clearTimer();
                const makeTime = difference === 0 ? 30 : -Number(difference);
                setTime(makeTime > 0 ? makeTime : 0);
                startTimer();
                break;
            case 'STARTED':
                clearTimer();
                const startedTimer = difference === 0 ? 14400 : (-Number(difference) + 30);
                setTime(startedTimer > 0 ? startedTimer : 0);
                startTimer();
                break;
            case 'ENDED':
                clearTimer();
                break;
        }
    }, [clearTimer, difference, roomInfo?.timeToStart, startTimer, status]);

    return { time }
}