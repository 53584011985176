import { walletApi } from "../../../api/wallet"

type Props = {
    authToken: string,
    address?: string,
    currency: string,
}

export const apiValidateAddressWithdrawal = async ({
    authToken,
    address,
    currency,
} : Props ) => {
    if(address) {
        const response = await walletApi(authToken).validateAddressWithdrawal(address, currency);

        if(response.error) throw response
        return response.data;
    }

    return null;
}