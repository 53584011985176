import styled from "styled-components";

export const RegistrationContainer = styled.div<{width?: Number}>`
position: relative;
width: 420px;
box-sizing: border-box;
padding: 0px 0px;
@media (max-width: 440px){
    width: 100%;
    padding: 0px 16px;
}
`