import axios from "axios"

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
});

export const notifiactionApi = {
    getNotifiaction(authToken: string) {
        return instance.get('/notification/all', { 
            headers: {'Authorization' : `Bearer ${authToken}`} })
            .then((response) => {
                return response.data;
            })
            .catch((err) => err)
    }
}