
import { FC, memo, useCallback } from 'react';
import { PrivacyPolicyProps } from './privacy-policy.props';
import { PrivacyPolicyView } from './privacy-policy.view';
import { usePersonalAccount } from '../personal-account/personal-account.props';
import { useTelegramBack } from '../../../hooks/telegram-button';
import { useUser } from '../../../hooks/user-hook';


export const PrivacyPolicy: FC<PrivacyPolicyProps> = memo(() => {
  const {language} = useUser();
  
  const personal = usePersonalAccount();
  const onBack = useCallback(() => {
     personal({});
  }, [personal])
  useTelegramBack(onBack);
  return (
    <PrivacyPolicyView 
      language={language}/>
  );
});
