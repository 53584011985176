export const getNumberFromRoomType = new Map([
    ["HOUR", 1],
    ["TWO", 2],
    ["FOURHOURS", 4],
    ["FOUR", 4],
    ["FIVE", 5],
    ["SIX", 6],
    ["FIFTEEN", 15],
    ["FIFTY", 50],
    ["HUNDRED", 100],
    ["TWOHUNDRED", 100]
  ]);

export const getTimeFromRoomType = new Map([
    ["HOUR", "1h"],
    ["FOURHOURS", "4h"],
    ["FIVE", "5min"],
    ["FIFTEEN", "15min"],
    ["TWO", "2"],
    ["FOUR", "4"],
    ["SIX", "6"]
  ]);

  export const formatTime = (time: number) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  export const correctTime = (time: number) => {
     return time > 9 ? `${time}` : `0${time}`;
  }

  export const getRoute = (type: string) => {
    switch(type){
      case "SITGO":
        return 'sit-and-go';
      case "BITROLL":
        return 'bitroll';
    }
  }