import styled from "styled-components";
import { themes } from "../themes/theme";

export const ContentButtonStyled = styled.div<{ 
    variant: 'primary' | 'secondary' | 'foreground';
    width?: number | string;
    height?: number | string;
    radius: number;
    padding?: number | [number?, number?, number?, number?];
}>`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: ${({ width }) => typeof width === 'number' ? `${width}px` : width || 'auto'};
    height: ${({ height }) => typeof height === 'number' ? `${height}px` : height || 'auto'};
    backdrop-filter: blur(5px);
    border-radius:  ${({ radius }) => radius + 'px' };

    padding: ${({ padding }) => {
      if (typeof padding === "number") {
        return `${padding}px`;
      }
      if (Array.isArray(padding)) {
        if (padding.length === 1) {
          return `${padding[0]}px`;
        }
        if (padding.length === 2) {
          return `${padding[0]}px ${padding[1]}px`;
        }
        if (padding.length === 4) {
          return `${padding[0]}px ${padding[1]}px ${padding[2]}px ${padding[3]}px`;
        }
      }
      return "0";
    }};

    background: ${({ variant }) => {
      switch(variant){
        case 'foreground':
          return 'rgba(0,0,0,0.2)';
        case 'secondary':
          return themes.colors.secondary;
        case 'primary':
          return themes.colors.primary;
        default:
          return themes.colors.primary;
      }
    }};

    &:before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius:  ${({ radius }) => radius + 'px' };
        border: 2px solid transparent;


        background: ${({ variant }) => {
          switch(variant){
            case 'foreground':
              return `${themes.colors.gradients.foreground} border-box`;
            case 'secondary':
              return `${themes.colors.gradients.secondary} border-box`;
            case 'primary':
              return `${themes.colors.gradients.primary} border-box`;
            default:
              return `${themes.colors.gradients.primary} border-box`;
          }
        }};
        mask: 
        linear-gradient(#fff 0 0) padding-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude;
        opacity: 0.5;
    }
`;
