export const gamesRulesTextEng = [
    {
      header: <>Participation</>,
      text: <>
        Participation in the tournaments is available only to registered 
        users who have reached the age of majority in accordance with 
        the laws and regulations of their country.
      </>
    },
    {
      header: <>Tournaments</>,
      text: <>
        Tournaments are conducted in a SIT&GO format and may include 
        2, 4, or 6 participants depending on the chosen category. 
        Tournaments are filtered by duration and buy-in amount. 
        Tournament durations can range from 5 minutes, 15 minutes, 
        1 hour, or 4 hours, and the buy-in amount can vary from $2 
        to $100.
      </>
    },
    {
      header: <>Entry into Tournament</>,
      text: <>
        Upon entering a tournament, the user's buy-in amount is 
        deducted, forming the tournament's prize pool. Users may 
        exit the tournament and receive a refund of the buy-in amount 
        until the required number of participants is reached to start the game.
      </>
    },
    {
      header: <>Predictions in Tournament</>,
      text: <>
        Once the required number of participants is reached, each tournament 
        participant has 30 seconds to make their prediction on the price of 
        Bitcoin at the end of the tournament timer. If a user fails to make a 
        prediction within this time frame, their prediction will default to the 
        Bitcoin rate at the end of the countdown period.
      </>
    },
    {
      header: <>Determining the Winner</>,
      text: <>
        The participant whose prediction is closest to the actual price of 
        Bitcoin at the end of the tournament timer wins. In the event that 
        multiple participants make the same prediction and it appears to be 
        the closest, the tournament prize pool is divided equally among them. 
        Prizes are indicated in the tournament window. 
      </>
    },
    {
      header: <>Fair Play</>,
      text: <>
        Users are prohibited from using fraudulent methods or manipulations 
        to improve their chances of winning. Any attempts at fraud or rule 
        violations may result in disqualification and loss of access to 
        the account.
      </>
    },
    {
      header: <>Dispute Resolution</>,
      text: <>
        In case of disputes or misunderstandings related to the game, the 
        decision is made by the Bitrate administration and is final and 
        binding on all participants.
        <br/><br/>
        <div style={{textIndent: 30}}>
          These rules ensure a fair and engaging gaming environment 
          for all tournament participants in our app.
        </div>
      </>
    }
  ]