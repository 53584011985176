
import { FC, memo, useCallback } from 'react';
import { RulesProps } from './rules.props';
import { RulesView } from './rules.view';
import { usePersonalAccount } from '../personal-account/personal-account.props';
import { useTelegramBack } from '../../../hooks/telegram-button';
import { useUser } from '../../../hooks/user-hook';


export const Rules: FC<RulesProps> = memo(() => {
  const {language} = useUser();
  
  const personal = usePersonalAccount();
  const onBack = useCallback(() => {
     personal({});
  }, [personal])
  useTelegramBack(onBack);
  return (
      <RulesView
      language={language}/>
    );
});
