import styled from "styled-components";
import { theme } from "../../themes/theme";

export const StyledLineTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  @media(max-width: 420px) {
    padding: 0 16px;
  }
`;

export const StyledLineText = styled.div`
  width: 50%;
  flex-grow: 1;
  height: 1px;
  background-color: ${theme.colors.primary};
`;

export const StyledText = styled.span`
  margin: 0 5px;
  color: ${theme.colors.primary};
`;
