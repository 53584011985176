import { FC, HTMLAttributes, ReactNode, memo } from "react";
import { ButtonStyled, CircleLoading } from "./rectangle-button.styles";

export type ButtonProps = {
    variant: 'primary' | 'secondary' | 'tertiary';
    state?: "idle" | "loading" | "success" | "error";
    children?: ReactNode;
    onClick?: () => void;
    width? : string | number;
    height? : number;
} & HTMLAttributes<HTMLButtonElement>;


export const RectangleButton: FC<ButtonProps> = memo(({state = 'idle', variant, children, width, height, onClick, ...rest }) =>
    <ButtonStyled disabled={state === 'loading'} width={width} height={height} onClick={onClick} variant={variant} {...rest} >
        {
            state === 'loading'? <CircleLoading variant={variant}/> : children
        } 
    </ButtonStyled>
);
