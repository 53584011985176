import { walletApi } from "../../../api/wallet";

type Props = {
    currency: string;
    amount?: string;
    authToken: string;
}

export const apiDepositAddress = async ({
    currency,
    amount,
    authToken,
}: Props) => {
   if(amount){
    const response = await walletApi(authToken).depositAddress(currency, amount);
    if (response.error) throw response;

    return response.data;
   }
   return null;
}