export const privacyPolicyTextRu = [
  {
    header: <>Введение</>,
    text: <>
      Bitrate стремится защищать конфиденциальность своих пользователей и соблюдает 
      все применимые законы, принципы и правила, связанные с защитой данных. 
      Данная Политика конфиденциальности описывает методы администрирования 
      информации Bitrate и связанных с ним услуг.
    </>
  },
  {
    header: <>Согласие</>,
    text: <>
      Используя услуги Bitrate, включая веб-сайт и мобильное приложение, 
      пользователи соглашаются с условиями данной Политики конфиденциальности 
      и методами администрирования информации. Bitrate может предоставлять 
      дополнительные уведомления, связанные с практиками конфиденциальности, 
      которые будут дополнять настоящую Политику конфиденциальности.
    </>
  },
  {
    header: <>Изменения в Политике конфиденциальности</>,
    text: <>
      Bitrate оставляет за собой право изменять или обновлять данную Политику 
      конфиденциальности в любое время. Пользователям рекомендуется 
      периодически просматривать данную политику на предмет изменений. 
      О значительных изменениях пользователи будут уведомлены по электронной почте.
    </>
  },
  {
    header: <>Применимость</>,
    text: <>
      Данная Политика конфиденциальности распространяется на всех пользователей 
      услуг Bitrate, включая физических лиц, торговцев и юридические лица. 
      Пользователи включают тех, кто использует и/или покупает услуги Bitrate 
      или связанные с ним услуги.
    </>
  },
  {
    header: <>Персональная информация, которую мы собираем</>,
    text: <>
      Bitrate собирает только необходимую информацию, которая требуется для 
      предоставления услуг. Это может включать:
      <ul style={{paddingLeft: 30, textIndent: 0, marginTop: 8}}>
        <li>Идентификационная информация: например, электронная почта, номер телефона, данные Telegram. 
          В случае необходимости прохождения проверки KYC может потребоваться и собираться 
          дополнительная идентификационная информация.</li>
        <li>Финансовая информация: история транзакций, платёжные данные.</li>
        <li>Любая другая информация, необходимая на основании взаимодействия пользователя с Bitrate.</li>
      </ul>
    </>
  },
  {
    header: <>Цель сбора информации</>,
    text: <>
      Bitrate собирает персональную информацию для следующих целей:
      <ul style={{paddingLeft: 30, textIndent: 0, marginTop: 8}}>
        <li>Для улучшения пользовательского опыта и персонализации услуг.</li>
        <li>Для защиты пользователей от мошеннической деятельности и обеспечения безопасности.</li>
        <li>Для соблюдения нормативных требований, включая правила «Знай своего клиента» 
          (KYC) и противодействие отмыванию денег (AML).</li>
        <li>Для исполнения условий и соглашений с пользователями.</li>
      </ul>
    </>
  },
  {
    header: <>Передача персональной информации</>,
    text: <>
      Bitrate может передавать персональную информацию:
      <ul style={{paddingLeft: 30, textIndent: 0, marginTop: 8}}>
        <li>Поставщикам услуг и партнёрам, участвующим в предоставлении услуг Bitrate.</li>
        <li>Регулирующим органам для соблюдения юридических обязательств.</li>
        <li>Организациям, участвующим в бизнес-сделках или слияниях с Bitrate.</li>
      </ul>
    </>
  },
  {
    header: <>Защита персональной информации</>,
    text: <>
      Bitrate применяет меры безопасности для защиты данных пользователей 
      от несанкционированного доступа, изменения или раскрытия. 
      Эти меры включают физические, электронные и процедурные меры безопасности.
    </>
  },
  {
    header: <>Использование cookies</>,
    text: <>
      Bitrate может использовать cookies для улучшения пользовательского 
      опыта и анализа трафика на сайте. Пользователи могут управлять 
      настройками cookies через параметры своего браузера.
    </>
  },
  {
    header: <>Права пользователей</>,
    text: <>
      Пользователи имеют право на доступ, исправление, возражение и удаление 
      персональной информации, хранящейся у Bitrate. Запросы могут быть 
      отправлены через предоставленную контактную информацию.
    </>
  },
  {
    header: <>Свяжитесь с нами</>,
    text: <>
      По вопросам, связанным с данной Политикой конфиденциальности или персональной 
      информацией, пользователи могут связаться с Bitrate через окно поддержки.
    </>
  }
];
