import { useNavigate } from "react-router";

export type RegistrationCreateProps = {

};

export const useRegistrationCreate = () => {
    const navigate = useNavigate();
    const registrationCreate = (props?: RegistrationCreateProps) => {
        navigate('/sign-up', props);
    }

    return registrationCreate;
}