import { walletApi } from "../../../api/wallet";
import { Currency } from "../../../api/wallet/dto/common";

export const apiGetFeeWithdraw = async (
    amount: string,
    currency: Currency,
    authToken: string
) => {
    const response = await walletApi(authToken).getFeeWithdraw(amount, currency);
    if (response.error) throw response;

    return response.data;
}