import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ChartDataProps, SSEStatus, chartApi } from "../../api/chart-api"
import { AppDispatch } from "..";

type ChartDataSliceProps = {
    data: ChartDataProps[];
    status: SSEStatus;
}

const initialState: ChartDataSliceProps = {
    data: [],
    status: "pending",
}

export const chartSlice = createSlice({
    name: 'chart',
    initialState: initialState,
    reducers: {
        dataReceived(state, action: PayloadAction<ChartDataProps[]>) {
            state.data = [...action.payload];
        },
        setStatus(state, action: PayloadAction<SSEStatus>) {
            state.status = action.payload;
        },
        dataClear(state) {
            state.data = [];
            state.status = "pending";
        }
    }
})

let _newDataHandler: ((data: ChartDataProps[]) => void) | null = null;

const newDataHandlerCreator = (dispatch: AppDispatch) => {
    if (_newDataHandler === null) {
        _newDataHandler = (data) => {
            dispatch(chartSlice.actions.dataReceived(data))
        }
    }
    return _newDataHandler;
}

let _statusChangedHandler: ((status: SSEStatus) => void) | null = null;
const statusChangedHandlerCreator = (dispatch: AppDispatch) => {
    if (_statusChangedHandler === null) {
        _statusChangedHandler = (status) => {
            dispatch(chartSlice.actions.setStatus(status))
        }
    }
    return _statusChangedHandler;
}

export const startDataListening = (authToken: string) => async (dispatch: AppDispatch) => {
    chartApi.start(authToken);
    chartApi.subscribe("messages-received" ,newDataHandlerCreator(dispatch));
    chartApi.subscribe("status-changed", statusChangedHandlerCreator(dispatch));
}

export const stopDataListening = () => async (dispatch: AppDispatch) => {
    chartApi.unsubscribe("messages-received", newDataHandlerCreator(dispatch));
    chartApi.unsubscribe("status-changed", statusChangedHandlerCreator(dispatch));
    chartApi.stop();
}

export default chartSlice.reducer;