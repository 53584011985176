import { useCallback } from "react";
import { useAppDispatch, useTypedSelector } from "./use-typed-selector";
import { loginTelegram } from "../store/reducers/telegram-slice";
import { CloudStorage, Utils, HapticFeedback, BackButton } from "@telegram-apps/sdk-react";

export type TelegramAuth = {
    tgState: "idle" | "loading" | "success" | "error" | "no-user";
    cloud: CloudStorage | undefined;
    isTelegram: boolean;
    getTelegramUser: () => void;
    utils: Utils | undefined;
    hapticFeedback: HapticFeedback | undefined;
    backButton: BackButton | null;
}

export const useTelegram = (): TelegramAuth => {

    const { initDataRaw, loadingTelegram, cloud, isTelegram, chatId, utils, hapticFeedback, backButton } = useTypedSelector(state => state.telegram);

    const dispatch = useAppDispatch();

    const getTelegramUser = useCallback(() => {
        if (initDataRaw !== "") dispatch(loginTelegram({ initData: initDataRaw, chatId: chatId }));
    }, [chatId, dispatch, initDataRaw]);

    return {
        tgState: loadingTelegram,
        cloud,
        isTelegram,
        getTelegramUser: getTelegramUser,
        utils: utils,
        hapticFeedback: hapticFeedback,
        backButton: backButton,
    }
}