import { keyframes, styled } from "styled-components"

const shimmer = keyframes`
  0% {
    background-position: 200% 0; /* Начальное положение градиента */
  }
  100% {
    background-position: -200% 0; /* Конечное положение градиента */
  }
`;

export const StyledSkeleton = styled.div<{
  width: string;
  height: string;
  maxWidth: string;
  variant: 'background' | 'foreground';
  maxHeight: string;
}>`
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  max-height: ${({ maxHeight }) => maxHeight};
  height: ${({ height }) => height};
  box-sizing: border-box;
  z-index: 5;
  background-size: 200% 100%;
  background-image: linear-gradient(-90deg,#27272A 15%, #473640 50%, #27272A 85%);
  animation: ${shimmer} 2s linear infinite;
`;