
import { FC, memo, useCallback, useState } from 'react';
import { SwiperLeaderboardProps } from './swiper-leaderboard.props';
import { SwiperLeaderboardView } from './swiper-leaderboard.view';
import { useLeaderboard } from '../../../../hooks/swiper/swiper-leaderboard';

export const SwiperLeaderboard: FC<SwiperLeaderboardProps> = memo(() => {
  const [filter] = useState<"PLAYERS" | "FARMERS">("FARMERS")
  const { top, leaders } = useLeaderboard();

  const changeFilter = useCallback(() => {
    //setFilter((prev) => prev === "PLAYERS" ? "FARMERS" : "PLAYERS");
  }, [])

  return (
    <SwiperLeaderboardView
      changeFilter={changeFilter}
      topThree={top}
      leaders={leaders}
      filter={filter}
    />
  );
});
