import styled from "styled-components";
import { themes } from "../../../themes/theme";

export const BlurTopPersonalAccount = styled.div`
position: absolute;
width: 100%;
height: 310px;
left: 0px;
top: 0px;

background: linear-gradient(0deg, rgba(183, 94, 213, 0.1) 54%, rgba(34, 255, 54, 0.1) 150%);
filter: blur(45px);
border-radius: 170px;
`;

export const BlurMiddlePersonalAccount = styled.div`
position: absolute;
width: 100%;
height: 615px;
left: 0px;
top: 67px;
z-index: -1;
background: linear-gradient(0deg, rgba(183, 94, 213, 0.1) 53.97%, rgba(34, 255, 54, 0.1) 150%);
filter: blur(45px);
border-radius: 170px;
`;

export const PersonalCard = styled.div`
display: flex;
justify-content: center;
box-sizing: border-box;
width: 100%;
position: relative;
height: auto;
padding: 0px 28px 22px 28px;
background: linear-gradient(128.69deg, rgba(183, 94, 213, 0.2) 39.51%, rgba(13, 198, 160, 0.2) 141.45%), #1C1C1C;
backdrop-filter: blur(2.5px);
border-radius: 20px;
&:before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 20px;
  border: 2px solid transparent;
  background: linear-gradient(180deg, #835C7C, #240203) border-box;
  mask: 
  linear-gradient(#fff 0 0) padding-box, 
  linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude;
    opacity: 0.5;
}
`;

export const Line = styled.div`
    width: 98%;
    height: 1px;
    background: ${themes.colors.gray};
    opacity: 0.5;
`

export const GridImagesContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 70px);
  grid-auto-rows: 70px;
  grid-gap: 15px;
  height: auto;
`;

export const CustomTransparentTextArea = styled.textarea`
  width: 100%;
  height: auto;
  resize: none;
  overflow-wrap: break-word; 
  white-space: pre-wrap;
  z-index: 2;
  background-color: transparent;
  border: none;
  outline: none;
  margin-top: 10px;
  color: ${themes.colors.foreground};
  font-family: ${themes.fonts.h2.family};
  font-size: ${themes.fonts.h2.mobileSize};;
  font-weight: ${themes.fonts.h2.weight};;
  cursor: text;
  line-height: 1.2;
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: inherit; 
  }
`;