import styled from "styled-components";

export const SurfaceStyled = styled.div<{
    radius: number | string;
    width?: number | string;
    height?: number;
    color: string
    borderColor: string;
    padding?: number | [number?, number?, number?, number?];
    borderWidth?: number;
}>`
position: relative;
background-color: ${(({ color }) => `${color}`)};
border-radius: ${(({ radius }) => `${typeof radius === "number" ? `${radius}px` : radius}`)};
width: ${(({ width }) => width === undefined ? `100%` : typeof width === "number" ? `${width}px` : width)};
height: ${(({ height }) => height === undefined ? `auto` : typeof height === "number" ? `${height}px` : height)};
padding: ${({ padding }) => {
    if (typeof padding === "number") {
      return `${padding}px`;
    }
    if (Array.isArray(padding)) {
      if (padding.length === 1) {
        return padding[0];
      }
      if (padding.length === 2) {
        return `${padding[0]}px ${padding[1]}px`;
      }
      if (padding.length === 4) {
        return `${padding[0]}px ${padding[1]}px ${padding[2]}px ${padding[3]}px`;
      }
    }
    return "0px";
}};
&:before {
  content: "";
  position: absolute;
  inset: 0px;
  border-radius: ${(({ radius }) => `${typeof radius === "number" ? `${radius}px` : radius}`)};
  border: ${(({ borderWidth }) => borderWidth !== undefined ? `${borderWidth}px` : `2px`)} solid transparent;
  background: ${(({ borderColor }) => borderColor)};
  mask: 
  linear-gradient(#fff 0 0) padding-box, 
  linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude;
    opacity: 0.5;
}
`;