import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { walletApi } from "../../../api/wallet-api";
import { AppDispatch } from "../..";
import { refrashBalance } from "../user-slice";
import { verifySecuritySlice } from "./security-verify";

export const convertDate = (originalDate: string) : string => {
    let date = new Date(originalDate);
    let formattedDate = date.toISOString().slice(0, 19).replace('T', ' ');
    
    return formattedDate;
}

export type Withdrawal = {
    feeState: "idle" | "loading" | "success" | "error";
    currency: string;
    network: string;
    amount: string;
    fee: string;
    withdrawalAddress: string;
    withdrawalAmount: string;
    withdrawalTotalAmount: string;
    errorAmount: string | null;
    errorVerifyCode: string | null;
    errorWithdrawalAddress: string | null;
    stateVerify: "idle" | "loading" | "success" | "error";
    stateConfirm: "idle" | "loading" | "success" | "error";
}

export type Deposit = {
    addressState: "idle" | "loading" | "success" | "error";
    currency: string;
    network: string;
    amount: string;
    isButton: boolean;
    walletAddress: string;
    walletAddressQrCode: string;
    errorAmount: string | null;
}

export type WalletHistory = {
    id: number | null,
    amount: string,
    currencies: string,
    type: string,
    time: [
        number,
        number,
        number,
        number,
        number,
        number,
        number
    ],
    status: string | null,
    paymentId: string | null,
    createdAt: string | null,
    orderId: string | null,
    orderDescription: string | null
}

export type WalletBalance = {

}

export type WalletInfo = {
    history: WalletHistory[],
    balance: WalletBalance,
    deposit: Deposit,
    withdrawal: Withdrawal,
    networksWithdrawalList: string[],
    networksDepositList: string[],
    currenciesList: string[];
}

export type WalletState = {
    wallet: WalletInfo;
    loadingHistory: "idle" | "loading" | "success" | "error";
    error: string | null;
}

const initialState: WalletState = {
    wallet: {
        history: [],
        balance: {},
        withdrawal: {
            currency: 'BTC',
            stateVerify: 'idle',
            errorVerifyCode: null,
            network: 'BTC',
            feeState: 'idle',
            stateConfirm: 'idle',
            amount: '',
            fee: '',
            withdrawalAddress: '',
            withdrawalAmount: '',
            withdrawalTotalAmount: '',
            errorAmount: null,
            errorWithdrawalAddress: null,
        },
        deposit: {
            addressState: 'idle',
            currency: 'BTC',
            network: 'BTC',
            amount: '',
            isButton: false,
            walletAddress: '',
            walletAddressQrCode: '',
            errorAmount: null,
        },
        networksWithdrawalList: ['BTC'],
        networksDepositList: ['BTC'],
        currenciesList: ['BTC','ETH','TETHER','LTC','DOGE','XRP','BCH','TON']
    },
    loadingHistory: "idle",
    error: null,
};


export const walletSlice = createSlice({
    name: 'wallet',
    initialState: initialState,
    reducers: {
        setStateWalletHistory(state,action: PayloadAction<"error" | "idle" | "loading" | "success">) {
            state.loadingHistory = action.payload;
        },
        setWalletHistory(state,action: PayloadAction<WalletHistory[]>) {
            state.wallet.history = action.payload;
        },
        setDepositNetwork(state,action: PayloadAction<string>) {
            state.wallet.deposit.network = action.payload;
        },
        setStateDeposit(state,action: PayloadAction<"loading" | "error" | "idle" | "success">) {
            state.wallet.deposit.addressState = action.payload;
        },
        setDepositCurrency(state,action: PayloadAction<string>) {
            state.wallet.deposit.currency = action.payload;
            switch(action.payload){
                case 'ETH':
                    state.wallet.deposit.network = 'ERC20';
                    state.wallet.networksDepositList = ['ERC20'];
                    break;
                case 'TETHER':
                    state.wallet.deposit.network = 'TRC20';
                    state.wallet.networksDepositList = ['TRC20','ERC20'];
                    break;
                default:
                    state.wallet.deposit.network = action.payload;
                    state.wallet.networksDepositList = [action.payload];
                    break;
            }
        },
        setDepositAmount(state,action: PayloadAction<string>) {
            state.wallet.deposit.amount = action.payload;
            state.wallet.deposit.isButton = false;
        },
        setDepositWalletAddress(state,action: PayloadAction<string>) {
            state.wallet.deposit.walletAddress = action.payload;
        },
        setAmountWithButton(state,action: PayloadAction<string>) {
            state.wallet.deposit.amount = action.payload;
            state.wallet.deposit.isButton = true;
        },
        setWalletQrCodeAddress(state,action: PayloadAction<string>) {
            state.wallet.deposit.walletAddressQrCode = action.payload;
        },
        setErrorDepositAmount(state,action: PayloadAction<string | null>) {
            state.wallet.deposit.errorAmount = action.payload;
        },
        setErrorWithdrawalAddress(state,action: PayloadAction<string | null>) {
            state.wallet.withdrawal.errorWithdrawalAddress = action.payload;
        },
        setErrorWithdrawalAmount(state,action: PayloadAction<string | null>) {
            state.wallet.withdrawal.errorAmount = action.payload;
        },
        setErrorWithdrawalVerify(state,action: PayloadAction<string | null>) {
            state.wallet.withdrawal.errorVerifyCode = action.payload;
        },
        setStateConfrimWithdrawal(state,action: PayloadAction<"error" | "idle" | "loading" | "success">) {
            state.wallet.withdrawal.stateConfirm = action.payload;
        },
        setWithdrawalCurrency(state,action: PayloadAction<string>) {
            state.wallet.withdrawal.currency = action.payload;
            switch(action.payload){
                case 'ETH':
                    state.wallet.withdrawal.network = 'ERC20';
                    state.wallet.networksWithdrawalList = ['ERC20'];
                    break;
                case 'TETHER':
                    state.wallet.withdrawal.network = 'TRC20';
                    state.wallet.networksWithdrawalList = ['TRC20','ERC20'];
                    break;
                default:
                    state.wallet.withdrawal.network = action.payload;
                    state.wallet.networksWithdrawalList = [action.payload];
                    break;
            }
        },
        setWithdrawalNetwork(state,action: PayloadAction<string>) {
            state.wallet.withdrawal.network = action.payload;
        },
        setWithdrawalAddress(state,action: PayloadAction<string>) {
            state.wallet.withdrawal.withdrawalAddress = action.payload;
        },
        setWithdrawalAmount(state,action: PayloadAction<string>) {
            state.wallet.withdrawal.fee = '0';
            state.wallet.withdrawal.withdrawalTotalAmount = '0';
            state.wallet.withdrawal.amount = action.payload;
        },
        setWithdrawalFee(state,action: PayloadAction<string>) {
            state.wallet.withdrawal.fee = action.payload;
        },
        setWithdrawalTotalAmount(state,action: PayloadAction<string>) {
            state.wallet.withdrawal.withdrawalTotalAmount = action.payload;
        },
        setWithdrawalFeeState(state,action: PayloadAction<"error" | "idle" | "loading" | "success">) {
            state.wallet.withdrawal.feeState = action.payload;
        },
        setWithdrawalVerifyState(state,action: PayloadAction<"error" | "idle" | "loading" | "success">) {
            state.wallet.withdrawal.stateVerify = action.payload;
        },
        setDepositNetworkList(state,action: PayloadAction<string>) {
            state.wallet.deposit.amount = action.payload;
            state.wallet.deposit.isButton = true;
        },
        resetVerifyPopup(state){
            state.wallet.withdrawal.errorVerifyCode = null;
        },
        reset(state) {
            state.wallet = {
                history: [],
                balance: {},
                withdrawal: {
                    currency: 'BTC',
                    network: 'BTC',
                    feeState: 'idle',
                    stateVerify: 'idle',
                    amount: '',
                    errorVerifyCode: null,
                    stateConfirm: 'idle',
                    fee: '0',
                    withdrawalAddress: '',
                    withdrawalAmount: '',
                    withdrawalTotalAmount: '0',
                    errorAmount: null,
                    errorWithdrawalAddress: null,
                },
                deposit: {
                    addressState: 'idle',
                    currency: 'BTC',
                    network: 'BTC',
                    amount: '',
                    isButton: false,
                    walletAddress: '',
                    walletAddressQrCode: '',
                    errorAmount: null,
                },
                networksWithdrawalList: ['BTC'],
                networksDepositList: ['BTC'],
                currenciesList: ['BTC','ETH','TETHER','LTC','DOGE','XRP','BCH','TON']
            };
            state.loadingHistory = 'loading';
            state.error = null;
        },
    }
});


export const getDepositAddressActionCreater = (currency: string, network: string, amount: string,authToken: string) => {
    return (dispatch: AppDispatch) => {
        dispatch(walletSlice.actions.setErrorDepositAmount(null));

        if(amount === ''){
            dispatch(walletSlice.actions.setStateDeposit('idle'));
            return;
        }

        dispatch(walletSlice.actions.setStateDeposit('loading'));

        let currencyWithNetwork = getNameCurrencyWithNetwork(currency,network);

        walletApi.getMinAmountOfDeposit(currencyWithNetwork, authToken)
             .then(responce => {
                if(parseFloat(amount) < responce.fiat_equivalent){
                    dispatch(walletSlice.actions.setErrorDepositAmount(`Minimal ${responce.fiat_equivalent}$`));
                    dispatch(walletSlice.actions.setStateDeposit('error'));
                    return;
                }
                else{
                    dispatch(walletSlice.actions.setErrorDepositAmount(null));
                    walletApi.getDepositAddress(currencyWithNetwork,amount,authToken)
                         .then(responce => {
                            dispatch(walletSlice.actions.setDepositWalletAddress(responce.adress));
                            dispatch(walletSlice.actions.setWalletQrCodeAddress(responce.qr));
                            dispatch(walletSlice.actions.setStateDeposit('success'));
                         })
                         .catch((err) => {
                            dispatch(walletSlice.actions.setDepositWalletAddress(''));
                            dispatch(walletSlice.actions.setWalletQrCodeAddress(''));
                            dispatch(walletSlice.actions.setStateDeposit('error'));
                        })
                }
             })
             .catch((err) => {
                dispatch(walletSlice.actions.setErrorDepositAmount('Error'));
                dispatch(walletSlice.actions.setStateDeposit('error'));
                return;
             })
    }
}

export const validatePreWithdrawActionCreater = (
    currency: string, 
    network: string, 
    address: string,
    amount: string,
    authToken: string, onSuccess?: () => void) => {
    return (dispatch: AppDispatch) => {

        if(amount === ''){
            dispatch(walletSlice.actions.setErrorWithdrawalAmount(`Please enter the amount`));
            return;
        }
        dispatch(walletSlice.actions.setStateConfrimWithdrawal('loading'));

        let currencyWithNetwork = getNameCurrencyWithNetwork(currency,network);

        walletApi.getFeeWithdraw(amount, currencyWithNetwork, authToken)
            .then(responce => {

                walletApi.getEstimatePrice(responce.fee, currencyWithNetwork, 'USDTTRC20', authToken)
                .then(responce => {
                    const result = parseFloat(amount) - parseFloat(responce.estimated_amount);
                    if(result <= 0){
                        dispatch(walletSlice.actions.setErrorWithdrawalAmount(`Minimal ${parseFloat(responce.estimated_amount).toFixed(6)} USDT`));
                        dispatch(walletSlice.actions.setWithdrawalFee('0'));
                        dispatch(walletSlice.actions.setWithdrawalTotalAmount('0'));
                        dispatch(walletSlice.actions.setWithdrawalFeeState('error'));
                        dispatch(walletSlice.actions.setStateConfrimWithdrawal('error'));
                        return;
                    }
                    else{
                        walletApi.validateAddressWithdrawal(address,currencyWithNetwork, authToken)
                            .then(responce => {
                               dispatch(walletSlice.actions.setErrorWithdrawalAddress(null));
                               dispatch(walletSlice.actions.setErrorWithdrawalAmount(null));
                               if(onSuccess) onSuccess();
                            })
                            .catch((err) => {
                               dispatch(walletSlice.actions.setStateConfrimWithdrawal('error'));
                               dispatch(walletSlice.actions.setErrorWithdrawalAddress('Wrong Withdrowal Address'));
                            })
                    }
                })
                .catch((err) => {
                    dispatch(walletSlice.actions.setWithdrawalFeeState('error'));
                })
            })
            .catch((err) => {
                dispatch(walletSlice.actions.setStateConfrimWithdrawal('error'));
                dispatch(walletSlice.actions.setWithdrawalFeeState('error'));
                return;
            })
    }
}

export const validateAddressWithdrawalActionCreater = (address: string, currency: string, network: string, authToken: string) => {
    return (dispatch: AppDispatch) => {
        if(address === '') return;

        let currencyWithNetwork = getNameCurrencyWithNetwork(currency,network);

        walletApi.validateAddressWithdrawal(address, currencyWithNetwork,authToken)
            .then(responce => {
                dispatch(walletSlice.actions.setErrorWithdrawalAddress(null));
            })
            .catch((err) => {
               dispatch(walletSlice.actions.setErrorWithdrawalAddress('Wrong Withdrowal Address'));
            })
    }
}

export const verifyWithdrawActionCreater = (code: string, authToken: string, onSuccess?: () => void) => {
    return (dispatch: AppDispatch) => {
        dispatch(walletSlice.actions.setWithdrawalVerifyState('loading'));
        walletApi.verifyWithdraw(code, authToken)
            .then(responce => {
                dispatch(walletSlice.actions.setErrorWithdrawalVerify(null));
               dispatch(walletSlice.actions.reset());
               dispatch(verifySecuritySlice.actions.reset());
               if(onSuccess) onSuccess();
               dispatch(refrashBalance({ authToken: authToken }));
               dispatch(walletSlice.actions.setWithdrawalVerifyState('success'));
            })
            .catch((err) => {
                dispatch(walletSlice.actions.setWithdrawalVerifyState('error'));
                const responce: any = err.response?.data || "Error";
                dispatch(walletSlice.actions.setErrorWithdrawalVerify(responce.message));
            })
    }
}

export const getFeeWithdrawActionCreater = (amount: string, currency: string, network: string, authToken: string) => {
    return (dispatch: AppDispatch) => {
        if(amount === '' || parseFloat(amount) === 0){
            dispatch(walletSlice.actions.setErrorWithdrawalAmount(null));
            dispatch(walletSlice.actions.setWithdrawalFeeState('error'));
            return;
        };

        let currencyWithNetwork = getNameCurrencyWithNetwork(currency,network);

        dispatch(walletSlice.actions.setWithdrawalFeeState('loading'));
        walletApi.getFeeWithdraw(amount, currencyWithNetwork, authToken)
            .then(responce => {
                walletApi.getEstimatePrice(responce.fee, currencyWithNetwork, 'USDTTRC20', authToken)
                    .then(responce => {
                        const result = parseFloat(amount) - parseFloat(responce.estimated_amount);
                        if(result <= 0){
                            dispatch(walletSlice.actions.setErrorWithdrawalAmount(`Minimal ${parseFloat(responce.estimated_amount).toFixed(6)} USDT`));
                            dispatch(walletSlice.actions.setWithdrawalFeeState('error'));
                            return;
                        }
                        
                        dispatch(walletSlice.actions.setErrorWithdrawalAmount(null));
                        dispatch(walletSlice.actions.setWithdrawalFee(parseFloat(responce.estimated_amount).toFixed(6)));
                        dispatch(walletSlice.actions.setWithdrawalTotalAmount(result.toFixed(6)));
                        dispatch(walletSlice.actions.setWithdrawalFeeState('success'));
                    })
                    .catch((err) => {
                        dispatch(walletSlice.actions.setWithdrawalFeeState('error'));
                    })
            })
            .catch((err) => {
                dispatch(walletSlice.actions.setWithdrawalFeeState('error'));
            })
    }
}

export const getWalletDataActionCreater = (authToken: string) => {
    return (dispatch: AppDispatch) => {
        dispatch(walletSlice.actions.setStateWalletHistory('loading'));
        walletApi.getHistory(authToken)
            .then(responce => {
                dispatch(walletSlice.actions.setWalletHistory(responce));
                dispatch(walletSlice.actions.setStateWalletHistory('success'));
            })
            .catch((err) => {
                dispatch(walletSlice.actions.setStateWalletHistory('error'));
            })
    }
}

export const getNameCurrencyWithNetwork = (currency: string, network: string): string => {
    let currencyWithNetwork = currency;

        if(currency === 'TETHER'){
            switch(network){
                case 'TRC20':
                    currencyWithNetwork = 'USDTTRC20';
                    break;
                case 'ERC20':
                    currencyWithNetwork = 'USDTERC20';
                    break;
                default: 
                    currencyWithNetwork = 'USDTTRC20';
            }
        }
    
    return currencyWithNetwork;
};

type FormattedTransaction = {
    time: string;
    amount: string;
    currencies: string;
    type: string;
};

type GroupedTransactions = {
    date: string;
    data: FormattedTransaction[];
};

export const formatTransactions = (transactions: WalletHistory[]): GroupedTransactions[]  => {
    function formatDate(dateArray: [number, number, number, number, number, number, number]): string {
        const [year, month, day, hour, minute, second] = dateArray;
        const date = new Date(year, month - 1, day, hour, minute, second);
        return date.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    }

    function formatTime(dateArray: [number, number, number, number, number, number, number]): string {
        const [year, month, day, hour, minute, second] = dateArray;
        return new Date(year, month - 1, day, hour, minute, second).toLocaleTimeString('en-US', { hour12: false });
    }

    const grouped = transactions.reduce((acc: Record<string, FormattedTransaction[]>, transaction) => {
        const date = formatDate(transaction.time);
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push({
            time: formatTime(transaction.time),
            amount: transaction.amount,
            currencies: transaction.currencies,
            type: transaction.type
        });
        return acc;
    }, {});

    return Object.keys(grouped).map(date => ({
        date: date,
        data: grouped[date].sort((a, b) => b.time.localeCompare(a.time))
    }));

}

export default walletSlice.reducer;
