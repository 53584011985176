
import { FC, memo, useCallback } from 'react';
import { StatisticsProps } from './statistics.props';
import { StatisticsView } from './statistics.view';
import { useTelegramBack } from '../../../hooks/telegram-button';
import { usePersonalAccount } from '../personal-account/personal-account.props';
import { useStatistics } from '../../../hooks/personal-account/statistics.hook';
import { useNavigate } from 'react-router';

export const Statistics: FC<StatisticsProps> = memo(() => {

  const { games } = useStatistics();

  const personal = usePersonalAccount();
  const onBack = useCallback(() => {
    personal({});
  }, [personal])
  useTelegramBack(onBack);

  const navigate = useNavigate();

  const onClickCard = useCallback((roomId: number) => {
    navigate(`/end-game/${roomId}`);
  }, [navigate])

  return (
    <StatisticsView
      onClickCard={onClickCard}
      games={games}
    />
  );
});
