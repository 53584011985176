import { FC, memo } from "react";
import { StyledCheckbox } from "./checkbox.styled";
import { Pressable } from "../pressable";
import Checked from "../../images/checked.svg";
import { Image } from "../image";

export type CheckboxProps = {
   onPress?: () => void;
   checked: Boolean;
};

export const Checkbox: FC<CheckboxProps> = memo(( {onPress, checked} ) => {
    return (
        <Pressable onPress={onPress}>
            <StyledCheckbox>
                 { checked && <Image style={{ position: 'absolute' }} src={Checked} width={6} height={6} /> }
            </StyledCheckbox>
        </Pressable>
    );
});