import styled from "styled-components";

export const BackgroundCircle = styled.div<{ size: number }>`
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    width: ${(({ size }) => `${size}px`)};
    height: ${(({ size }) => `${size}px`)};
    background: linear-gradient(90deg, #0DC6A0 0%, #D238B0 100%);
    border-radius: 50%;
`;