import { createSlice } from "@reduxjs/toolkit";

export type MenuState = {
    isOpen: boolean
}

const initialState: MenuState = {
    isOpen: false
}

export const menuSlice = createSlice({
    name: 'menu',
    initialState: initialState,
    reducers: {
        controlMenu(state) {
            state.isOpen = !state.isOpen;
        },
    }
});

export default menuSlice.reducer;