import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { statisticsApi } from "../../../api/statistics-api";
import axios from "axios";
import { FinishPayloadType } from "../../../api/sit-go/game-api";

export type StatisticsGameInfo = {
    time: string;
    description: string;
    prize: string;
    roomId: number;
}
export type StatisticsGame = {
    date: string;
    gameInfo: StatisticsGameInfo[];
}

export type StatisticsState = {
    games: StatisticsGame[];
    gameResult: FinishPayloadType | null;
    state: "loading" | "success" | "error" | "idle";
    gameResultstate: "loading" | "success" | "error" | "idle";
}

const initialState: StatisticsState = {
    games: [],
    gameResult: null,
    state: "idle",
    gameResultstate: "idle",
}

export const statisticsSlice = createSlice({
    name: 'statistics',
    initialState: initialState,
    reducers: {
        clearGameResult(state){
           state.gameResult = null;
           state.gameResultstate = "idle";
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getGameStatistics.fulfilled, (state, action: PayloadAction<StatisticsGame[]>) => {
                state.games = action.payload;
                state.state = "success";
            })
            .addCase(getGameStatistics.pending, (state) => {
                state.state = "loading";
            })
            .addCase(getGameStatistics.rejected, (state) => {
                state.state = "error";
            })
            .addCase(getGameResult.fulfilled, (state, action: PayloadAction<FinishPayloadType>) => {
                state.gameResult = action.payload;
                state.gameResultstate = "success";
            })
            .addCase(getGameResult.pending, (state) => {
                state.gameResultstate = "loading";
            })
            .addCase(getGameResult.rejected, (state) => {
                state.gameResultstate = "error";
            })
    }
});

export const getGameStatistics = createAsyncThunk('statistics/game',
    async (data: { authToken: string }, thunkApi) => {
        const { authToken } = data;
        const responce = await statisticsApi.getGamesHistory(authToken);
        if (axios.isAxiosError(responce)) thunkApi.rejectWithValue(responce);
        else return responce;
    })

export const getGameResult = createAsyncThunk('statistics/game-result',
    async (data: { authToken: string, roomId: number }, thunkApi) => {
        const { authToken, roomId } = data;
        const responce = await statisticsApi.getEndGame(authToken, roomId);
        if (axios.isAxiosError(responce)) thunkApi.rejectWithValue(responce);
        else return responce;
    })

export default statisticsSlice.reducer;