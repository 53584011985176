import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { swiperApi } from "../../../api/swiper-api";
import { appStatusSlice } from "../app-status-slice";
import axios from "axios";

export type SwiperFarmState = {
    loading: "idle" | "loading" | "success" | "error";
    farm: Farm;
}

export type Farm = {
    dailyFarm: number;
    amountBtrt: number;
    energy: number;
    recovery: number;
    dailyMaxValue: number;
}

const initialState: SwiperFarmState = {
    loading: 'loading',
    farm: {
        dailyFarm: 0,
        amountBtrt: 0,
        energy: 0,
        recovery: 0,
        dailyMaxValue: 0
    },
}

export const swiperFarmSlice = createSlice({
    name: 'swiper-farm',
    initialState: initialState,
    reducers: {
        setDailyFarm(state, action: PayloadAction<number>) {
            //state.dailyFarm = action.payload;
        },
        setAmountBtrt(state, action: PayloadAction<number>) {
            state.farm.amountBtrt = action.payload;
        },
        setLoading(state, action: PayloadAction<"idle" | "loading" | "success" | "error">) {
            //state.loading = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(initFarmAction.fulfilled, (state, action: PayloadAction<Farm>) => {
                state.farm = action.payload;
                state.loading = "success";
            })
            .addCase(initFarmAction.pending, (state) => {
                state.loading = "loading";
            })
            .addCase(initFarmAction.rejected, (state) => {
                state.loading = "error";
            })
            .addCase(tapFarmAction.fulfilled, (state, action: PayloadAction<Farm>) => {
                state.farm.amountBtrt = action.payload.amountBtrt;
                state.farm.recovery = action.payload.recovery;
                state.farm.dailyFarm = action.payload.dailyFarm;
            })
            .addCase(intervalTapFarmAction.fulfilled, (state, action: PayloadAction<Farm>) => {
                state.farm.amountBtrt = action.payload.amountBtrt;
                state.farm.recovery = action.payload.recovery;
                state.farm.dailyFarm = action.payload.dailyFarm;
            })
            .addCase(tapFarmAction.pending, (state) => {
            })
            .addCase(tapFarmAction.rejected, (state) => {
            })
    }
});

export const initFarmAction = createAsyncThunk('swiper-farm/init',
    async (data: { authToken: string }, thunkApi) => {
        const { authToken } = data;
        try {
            const responce = await swiperApi.initSwiper(authToken);
            return responce;
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 401) {
                    thunkApi.dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }));
                }
            }
        }
    }
)

export const tapFarmAction = createAsyncThunk('swiper-farm/tap',
    async (data: { authToken: string }, thunkApi) => {
        const { authToken } = data;
        try {
            const responce = await swiperApi.tapSwiper(authToken);
            return responce;
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 401) {
                    thunkApi.dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }));
                }
            }
        }
    }
)

export const intervalTapFarmAction = createAsyncThunk('swiper-farm/interval-tap',
    async (data: { authToken: string }, thunkApi) => {
        const { authToken } = data;
        try {
            const responce = await swiperApi.intervalTapSwiper(authToken);
            return responce;
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 401) {
                    thunkApi.dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }));
                }
            }
        }
    }
)

export default swiperFarmSlice.reducer;