import { FC, memo, useCallback, useState } from "react";
import { useMediaQuery } from "react-responsive";

import AppIcon from "../../images/app.svg"
import EmailIcon from "../../images/email.svg"
import SmsIcon from "../../images/phone.svg"
import arrow from "../../images/arrow.svg"

import { VerifySecurityState } from "../../store/reducers/personal-account/security-verify";
import { UserState } from "../../store/reducers/user-slice";
import { theme } from "../../themes/theme";
import { Row } from "../row";
import { Image } from "../image";
import { Spacing } from "../spacing";
import { Text } from "../text";
import { PinCodeInput } from "../pin-code-input";
import { InputMessage } from "../input-message";
import { Column } from "../column";
import { Pressable } from "../pressable";
import { RectangleButton } from "../rectangle-button";
import { Popup } from "../popup";
import { ErrorChild } from "./verification-popup.styles";

export type VerificationPopupProps = {
    setDigits: (value: [string,string,string,string,string,string]) => void,
    onConfirmClick: () => void,
    onBack: () => void,
    onSwitchTypeVerify?: (value: 'app' | 'email' | 'sms' | 'none') => void,
    verifySecurityState: VerifySecurityState,
    userState?: UserState;
    typeVerify?: 'app' | 'email' | 'sms';
    recipient?: string;
    sendCode: () => void;
    closePopup: () => void;
    errorVerify?: string | null;
    isOpenVerificationPopup: boolean;
    stateVerify?: "error" | "idle" | "loading" | "success"
};

export const VerificationPopup: FC<VerificationPopupProps> = memo(({
    isOpenVerificationPopup, closePopup,
    onSwitchTypeVerify, sendCode, typeVerify,
    verifySecurityState, userState,recipient,
    onBack, onConfirmClick, setDigits, errorVerify,
    stateVerify}) => {

    const isMobile = useMediaQuery({maxWidth: theme.toMobileSize});

    const [tab, setTab] = useState<boolean>(true);
    const onReferalOpen = useCallback(() => {
        setTab(!tab);
    }, [tab])

    const onGetPopupName = useCallback(() => {
        if(verifySecurityState.typeVerify === 'app' || typeVerify === 'app') return 'Authenticator App';
        if(verifySecurityState.typeVerify === 'sms' || typeVerify === 'sms') return 'SMS Authentication';
        if(verifySecurityState.typeVerify === 'email' || typeVerify === 'email') return 'Email Authentication';
    }, [verifySecurityState.typeVerify,typeVerify])

    return (
        <Popup name={onGetPopupName()} isActive={isOpenVerificationPopup} closePopup={closePopup}>
            {
                (verifySecurityState.typeVerify === 'app' || typeVerify === 'app') && <>
                    <Row style={{width: '100%'}}>
                        <Row style={isMobile? {width: 260} : {}}>
                            <Image src={AppIcon}/>
                            <Spacing variant="Row" themeSpace={5} />
                            <Text themeFont={theme.fonts.t} themeColor={theme.colors.surface}>
                                Enter the 6-digit code generated in the App
                            </Text> 
                        </Row>
                    </Row>
                    <Spacing variant="Column" themeSpace={15} />   
                    {errorVerify !== undefined ?
                    <Column style={{position: 'relative'}}>
                        <PinCodeInput isError={errorVerify !== null} digits={verifySecurityState.code} setDigits={setDigits}/>
                        <ErrorChild>
                            {errorVerify !== null  && <InputMessage marginTop={5} themeColor={theme.colors.error} text={errorVerify}/>}       
                        </ErrorChild>
                    </Column> :
                    <Column style={{position: 'relative'}} >
                        <PinCodeInput isError={verifySecurityState.errorApp !== null} digits={verifySecurityState.code} setDigits={setDigits}/>
                        <Spacing variant="Column" themeSpace={5} />
                         <Column horizontalAlign='flex-end' style={{height: 30}}>
                             <ErrorChild>
                                 {verifySecurityState.errorApp !== null  && <InputMessage marginTop={5} themeColor={theme.colors.error} text={verifySecurityState.errorApp}/>}
                             </ErrorChild>
                         </Column> 
                    </Column>}
                </>
            }   
            {
                (verifySecurityState.typeVerify === 'email' || typeVerify === 'email') && <>
                    <Row style={{width: '100%'}}>
                        <Row style={isMobile? {width: 260} : {}}>
                            <Image src={EmailIcon}/>
                            <Spacing variant="Row" themeSpace={5} />
                            <Text themeFont={theme.fonts.t} themeColor={theme.colors.surface}>
                            A verification code will be sent {userState !== undefined ? userState.user.email : recipient}
                            </Text> 
                        </Row>
                    </Row>
                    <Spacing variant="Column" themeSpace={15} />   
                    {errorVerify !== undefined ?
                    <Column style={{position: 'relative'}}>
                        <PinCodeInput isError={errorVerify !== null} digits={verifySecurityState.code} setDigits={setDigits}/>
                        <ErrorChild>
                            {errorVerify !== null  && <InputMessage marginTop={5} themeColor={theme.colors.error} text={errorVerify}/>}       
                        </ErrorChild>    
                    </Column> :
                    <Column style={{position: 'relative'}}>
                        <PinCodeInput isError={verifySecurityState.errorEmail !== null} digits={verifySecurityState.code} setDigits={setDigits}/>
                        <ErrorChild>
                            {verifySecurityState.errorEmail !== null  && <InputMessage marginTop={5} themeColor={theme.colors.error} text={verifySecurityState.errorEmail}/>}
                        </ErrorChild>
                    </Column>}
                    <Spacing variant="Column" themeSpace={5} />
                    <Column horizontalAlign='flex-end' style={{height: 30}}>
                        <Text
                            style={{height: 20, position: 'absolute', paddingTop: verifySecurityState.errorEmail === null && (errorVerify === null || errorVerify === undefined) ? 0 : 20}}
                            themeFont={theme.fonts.t} themeColor={theme.colors.surfaceGray}>
                            {verifySecurityState.emailTimer === 0 && verifySecurityState.emailStatus === 'started' && <>
                            No code received? <span onClick={sendCode} style={{color: theme.colors.primary}}>Resend</span>
                            </>}
                        </Text>
                        <Text style={{height: 20, position: 'absolute'}} themeFont={theme.fonts.t} themeColor={theme.colors.surfaceGray}>
                            {verifySecurityState.emailTimer !== 0 ? `${verifySecurityState.emailTimer} sec` : '' } 
                        </Text>
                    </Column> 
                </>
            }
            {
                (verifySecurityState.typeVerify === 'sms' || typeVerify === 'sms') && <>
                    <Row style={{width: '100%'}}>
                        <Row style={isMobile? {width: 260} : {}}>
                            <Image src={SmsIcon}/>
                            <Spacing variant="Row" themeSpace={5} />
                            <Text themeFont={theme.fonts.t} themeColor={theme.colors.surface}>
                            A verification code will be sent {userState !== undefined ? userState.user.phone : recipient}
                            </Text> 
                        </Row>
                    </Row>
                    <Spacing variant="Column" themeSpace={15} />   
                    {errorVerify !== undefined ?
                    <Column style={{position: 'relative'}}>
                        <PinCodeInput isError={errorVerify !== null} digits={verifySecurityState.code} setDigits={setDigits}/>
                        <ErrorChild>
                            {errorVerify !== null  && <InputMessage marginTop={5} themeColor={theme.colors.error} text={errorVerify}/>}       
                        </ErrorChild>       
                    </Column> :
                    <Column style={{position: 'relative'}}>
                        <PinCodeInput isError={verifySecurityState.errorSms !== null} digits={verifySecurityState.code} setDigits={setDigits}/>
                        <ErrorChild>
                            {verifySecurityState.errorSms !== null  && <InputMessage marginTop={5} themeColor={theme.colors.error} text={verifySecurityState.errorSms}/>}
                        </ErrorChild>
                    </Column>}
                    <Spacing variant="Column" themeSpace={5} />      
                    <Column horizontalAlign='flex-end' style={{height: 30}}>
                        <Text 
                            style={{height: 20, position: 'absolute', paddingTop: verifySecurityState.errorSms === null && (errorVerify === null || errorVerify === undefined) ? 0 : 20}}
                            themeFont={theme.fonts.t} themeColor={theme.colors.surfaceGray}>
                             {verifySecurityState.smsTimer === 0 && verifySecurityState.smsStatus === 'started' && <>
                            No code received? <span onClick={sendCode} style={{color: theme.colors.primary}}>Resend</span>
                            </>}
                        </Text>
                        <Text style={{height: 20, position: 'absolute'}} themeFont={theme.fonts.t} themeColor={theme.colors.surfaceGray}>
                            {verifySecurityState.smsTimer !== 0 ? `${verifySecurityState.smsTimer} sec` : '' } 
                        </Text>
                    </Column>
                </>
            }
            <Spacing variant="Column" themeSpace={15} />
            { onSwitchTypeVerify !== undefined && <Column style={
                (userState?.user.twoFactorAuthEnabled && userState?.user.phone !== 'null') ||
                (userState?.user.email !== 'null' && userState?.user.phone !== 'null') ||
                (userState?.user.twoFactorAuthEnabled && userState?.user.email !== 'null') ? {} : {display: "none"}}>
                <Column>
                    <Pressable onClick={onReferalOpen} style={{padding:0, width: "100%"}}>
                        <Row verticalAlign={"center"} style={{width:"100%"}}>
                            <Text align={"left"} themeFont={theme.fonts.ht2} themeColor={theme.colors.primary}>
                                Switch to another way of authentication
                            </Text>
                            <Spacing variant={"Row"} themeMobileSpace={5} themeSpace={5} />
                            <Image width={10} height={10} src={arrow} style={tab ? { transform: "rotate(180deg)", transition: "0.1s" } : { transition: "0.1s" }}/>
                        </Row>
                    </Pressable>
                </Column>
                {tab === true &&
                    <Column>
                        <Pressable style={!userState?.user.twoFactorAuthEnabled || verifySecurityState.typeVerify === 'app' ? {display: 'none'} : {padding:0, marginTop: isMobile? 10 : 15}} onClick={() => onSwitchTypeVerify('app')}>
                            <Row style={{height: isMobile ? 26 : 30}} verticalAlign={"center"}>
                                <Spacing variant="Row" themeSpace={10} />
                                <Image src={AppIcon}/>
                                <Spacing variant={"Row"} themeMobileSpace={5} themeSpace={5} />
                                <Text align={"left"} themeFont={theme.fonts.t} themeColor={theme.colors.surface}>
                                    Authenticator App
                                </Text>
                            </Row>
                        </Pressable>
                        <Pressable style={userState?.user.phone === 'null' || verifySecurityState.typeVerify === 'sms' ? {display: 'none'} : {padding:0, marginTop: isMobile? 10 : 15}} onClick={() => onSwitchTypeVerify('sms')}>
                            <Row style={{height: isMobile ? 26 : 30}} verticalAlign={"center"}>
                                <Spacing variant="Row" themeSpace={10} />
                                <Image src={SmsIcon}/>
                                <Spacing variant={"Row"} themeMobileSpace={5} themeSpace={5} />
                                <Text align={"left"} themeFont={theme.fonts.t} themeColor={theme.colors.surface}>
                                    SMS
                                </Text>
                            </Row>
                        </Pressable>
                        <Pressable style={userState?.user.email === 'null' || verifySecurityState.typeVerify === 'email' ? {display: 'none'} : {padding:0, marginTop: isMobile? 10 : 15}} onClick={() => onSwitchTypeVerify('email')}>
                            <Row style={{height: isMobile ? 26 : 30}} verticalAlign={"center"}>
                                <Spacing variant="Row" themeSpace={10} />
                                <Image src={EmailIcon}/>
                                <Spacing variant={"Row"} themeMobileSpace={5} themeSpace={5} />
                                <Text align={"left"} themeFont={theme.fonts.t} themeColor={theme.colors.surface}>
                                    Email
                                </Text>
                            </Row>
                        </Pressable>
                    </Column>
                }
            </Column>}
            <Spacing variant={"Column"} themeSpace={isMobile? 25 : 35} />
            <Row style={{width: "100%"}} verticalAlign={"center"} horizontalAlign={isMobile? "space-between" : "center"}>
                <RectangleButton state={stateVerify !== undefined ? stateVerify : verifySecurityState.isVerify} onClick={onConfirmClick} variant={"primary"} height={isMobile? 40 : 50} width={isMobile? 135 : 190}>
                    <Text themeFont={theme.fonts.ht1} themeColor={theme.colors.background}>
                        Confirm
                    </Text>
                </RectangleButton>
                <Spacing variant={"Row"} themeMobileSpace={10} themeSpace={30} />
                <RectangleButton onClick={onBack} variant={"tertiary"} height={isMobile? 40 : 50} width={isMobile? 125 : 140}>
                    <Text themeFont={theme.fonts.ht1} themeColor={theme.colors.primary}>
                        Back
                    </Text>
                </RectangleButton>
            </Row>
        </Popup>
    );
});