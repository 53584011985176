import { FC, memo } from "react";
import { Row } from "../row/row";
import { Image } from "../image/image"
import { Text } from "../text";
import error from "../../images/icon-warning.svg";
import { theme } from "../../themes/theme";
import { Spacing } from "../spacing";

export type InputMessageProps ={
    text: string;
    themeColor: string;
    marginTop?: number;
};

export const InputMessage : FC<InputMessageProps> = memo(( {marginTop, text, themeColor } ) => (
    <Row style={ {marginTop: marginTop ?? 0, width: '100%' } }>
        <Image src={error} width={18} height={18} themeColor={themeColor} />
        <Spacing themeSpace={5} variant="Row" />
        <Text align="left" themeFont={theme.fonts.t} themeColor={themeColor}>{text}</Text>
    </Row>
))