import { useCallback } from "react";
import { useAppDispatch, useTypedSelector } from "./use-typed-selector";
import { useUser } from "./user-hook";
import { getNotifiaction } from "../store/reducers/notifiaction-slice";

export const useNotification = () => {
    const { data } = useTypedSelector(state => state.notifiaction);
    const { authToken } = useUser();
    const dispatch = useAppDispatch();

    const getNotifications = useCallback(() => {
       dispatch(getNotifiaction({ authToken: authToken }));
    }, [authToken, dispatch]);

    return { 
        notification: data,
        getNotifications,
    }
}