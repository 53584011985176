import { FC, HtmlHTMLAttributes, memo, ReactNode } from "react"
import { StyledTouch } from "./touch.styles"

export type TouchProps = {
    onTouch?: () => void;
    disabled?: boolean;
    children?: ReactNode;
} & HtmlHTMLAttributes<HTMLElement>;

export const Touch: FC<TouchProps> = memo(({ onTouch, children, disabled, ...rest }) => {
    return (
        <StyledTouch onTouchStart={onTouch} {...rest}>
            {children}
        </StyledTouch>
    )
})