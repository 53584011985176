import styled from 'styled-components';

export const StyledCheckbox = styled.div`
  display: flex;
  justify-content: center;
  width: 10px;
  height: 10px;
  align-items: center;
  margin-top: 3px;
  border: 1px solid #FFEEAA;
`;
