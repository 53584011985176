import { useCallback } from "react";
import { useAppDispatch, useTypedSelector } from "./use-typed-selector";
import { getNextBenefitsActionCreater, getRefferalInfoActionCreater } from "../store/reducers/personal-account/referral-slice";

export const useReferralHook = () => {

    const dispatch = useAppDispatch();
    const referralState = useTypedSelector(state => state.referral);
    const {tgReferralCode} = useTypedSelector(state => state.user.user);
    const {authToken} = useTypedSelector(state => state.user);
    
    const getNextBenefits = useCallback(() => {
      dispatch(getNextBenefitsActionCreater(referralState.referral.page,referralState.referral.totalPages,authToken));
    }, [dispatch,referralState.referral.page,referralState.referral.totalPages,authToken]);
  
    const getRefferalInfo = useCallback(() => {
      dispatch(getRefferalInfoActionCreater(authToken));
    }, [dispatch,authToken]);
  
    const onShare = async ( ) => {
      await navigator.share({
          title: "Bitrate",
          url: `https://${process.env.REACT_APP_API_TG_REF_LINK}${tgReferralCode}`,
      });
    }

    return { 
        getNextBenefits: getNextBenefits, 
        onShare: onShare, 
        referralState: referralState,
        refCode: tgReferralCode,
        getRefferalInfo: getRefferalInfo
    }
}

