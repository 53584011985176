import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../..";
import { statisticsApi } from "../../../api/statistics-api";
import { appStatusSlice } from "../app-status-slice";

export type ProfileState = {
    playerName: string;
    gifts: {
        type: 'one' | 'two' | 'three'
    }[],
    loading: "idle" | "loading" | "success" | "error";
    error: string | null;
    errorUpdate: string | null;
}

const initialState: ProfileState = {
    gifts: [{type: 'one'},{type: 'two'},{type: 'three'}],
    playerName: '',
    loading: 'idle',
    error: null,
    errorUpdate: null
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState: initialState,
    reducers: {
        setUserNameActionCreater(state, action: PayloadAction<string>) {
            state.playerName = action.payload;
            state.errorUpdate = null;
            state.error = null;
        },
        setErrorActionCreater(state, action: PayloadAction<string | null>) {
            state.error = action.payload;
        },
        fetchErrorActionCreater(state, action: PayloadAction<string>) {
            state.error = action.payload;
            state.loading = "error";
        },
        fetchErrorUpdateActionCreater(state, action: PayloadAction<string>) {
            state.errorUpdate = action.payload;
            state.loading = "error";
        },
        fetchUserNameActionCreater(state) {
            state.loading = "loading";
        },
        fetchSuccessActionCreater(state) {
            state.loading = "success";
            state.errorUpdate = null;
            state.error = null;
        },
        reset(state) {
            state.playerName = '';
            state.loading = 'idle';
            state.error = null;
        },
        resetError(state) {
            state.loading = 'idle';
            state.error = null;
        },
    }
})

export const setInitalPlayerNameActionCreater = (
    name: string
) => {
    return (dispatch: AppDispatch) => {
        dispatch(profileSlice.actions.resetError());
        dispatch(profileSlice.actions.setUserNameActionCreater(name));
    }
}

export const getPnLOfLastDayActionCreater = (
    authToken: string
) => {
return (dispatch: AppDispatch) => {
    dispatch(profileSlice.actions.fetchUserNameActionCreater());
    statisticsApi.getStatisticsByFilter(authToken, 'last24h')
        .then(responce => {
            dispatch(profileSlice.actions.setUserNameActionCreater(responce.playerName));
            dispatch(profileSlice.actions.fetchSuccessActionCreater());
        })
        .catch((err) => {
            dispatch(profileSlice.actions.fetchErrorActionCreater("User not found"));
            if(err?.responce.status === 401){
                dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
            }
        })
}
}

export default profileSlice.reducer;