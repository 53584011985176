
import { FC, memo, useCallback, useEffect } from 'react';
import { ReferralProps } from './referral.props';
import { ReferralView } from './referral.view';
import { useReferralHook } from '../../../hooks/referral-hook';
import { usePersonalAccount } from '../personal-account/personal-account.props';
import { useTelegramBack } from '../../../hooks/telegram-button';

export const Referral: FC<ReferralProps> = memo(() => {

  const {
    getNextBenefits, 
    refCode,
    referralState,
    getRefferalInfo,
    onShare} = useReferralHook();

  useEffect(() => {
    getRefferalInfo();
  }, [getRefferalInfo]);

  const personal = usePersonalAccount();
  const onBack = useCallback(() => {
     personal({});
  }, [personal])
  useTelegramBack(onBack);

  return (
    <ReferralView 
      getNextBenefits={getNextBenefits}
      onShare={onShare}
      referralState={referralState}
      refCode={refCode}/>
    );
});
