import styled from "styled-components";
import { themes } from "../../../themes/theme";


export const Blur = styled.div`
position: absolute;
width: 100%;
height: 100%;

background: linear-gradient(0deg, rgba(183, 94, 213, 0.1) 53.97%, rgba(34, 255, 54, 0.1) 150.62%);
filter: blur(45px);
`

export const CardTopPlayerStyled = styled.div<{ max: boolean }>`
    display: flex;
    box-sizing: border-box;
    justify-content: flex-start;
    flex-direction: column;
    width: ${(({ max }) => max ? "110px" : "75px")};
    height: ${(({ max }) => max ? "110px" : "75px")};
    border-radius: 50%;
    border: 1px solid ${(({ max }) => max ? themes.colors.gradient : "#AA44DD")};
    background: ${themes.colors.background};
    padding: 10px 10px 0px 10px;
`;

export const GridTopCard = styled.div<{ isOne: boolean }>`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: ${(({ isOne }) => isOne ? '1fr' : '1fr 1fr 1fr')};
    margin-top: -70px;
    grid-column-gap: 7px;
    justify-items: center;

    align-items: end;
 
`;

export const GridPlayers = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ScrollContainer = styled.div`
    padding-top: 20px;
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow-y: auto;
    z-index: 5;
    max-width: 300px;
    padding-bottom: 10px;
    &::-webkit-scrollbar{
        display: none;
    }
`

export const Slice = styled.div`
    height: 33px;
    width: 1px;
    background: #D6D6D6;
`

export const RateBoardStyled = styled.div`
box-sizing: border-box;
position: relative;
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 13px 30px;
background: linear-gradient(157.44deg, #835C7C -10.89%, #242023 37.68%);
border-radius: 10px;
&:before {
  content: "";
  position: absolute;
  inset: 0px;
  border-radius: 10px;
  border: 2px solid transparent;
  background: linear-gradient(180deg, #835C7C, #240203) border-box;
  mask: 
  linear-gradient(#fff 0 0) padding-box, 
  linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude;
    opacity: 0.5;
}
`;

export const TopBoard = styled.div`
display: flex;
justify-content: center;
width: 100%;
min-height: 150px;
position: relative;
background: linear-gradient(128.69deg, rgba(183, 94, 213, 0.2) 39.51%, rgba(13, 198, 160, 0.2) 141.45%), #1C1C1C;
backdrop-filter: blur(2.5px);
border-radius: 20px;
&:before {
  content: "";
  position: absolute;
  height: 150px;
  inset: 0;
  border-radius: 20px;
  border: 2px solid transparent;
  background: linear-gradient(180deg, #835C7C, #240203) border-box;
  mask: 
  linear-gradient(#fff 0 0) padding-box, 
  linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude;
    opacity: 0.5;
}
`;