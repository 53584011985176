import { FC, memo, useCallback, useEffect } from "react";
import { RegistrationLogInView } from "./registration-log-in.view";
import { useRegistrationCreate } from "../registration-create/registration-create.props";
import { useRegistrationForgotPassword } from "../registration-forgot-password-email/registration-forgot-password.props";
import { RegistrationLogInProps } from "./registration-log-in.props";
import { useAppDispatch, useTypedSelector } from "../../../hooks/use-typed-selector";
import { UserTelegram, authSlice, loginActionCreater, loginByGoogleActionCreater, loginByTelegramActionCreater } from "../../../store/reducers/auth-slice"; 


export const RegistrationLogIn: FC<RegistrationLogInProps> = memo(() => {

    const { userName, loading, password, error } = useTypedSelector(state => state.auth);
    const { setUserNameActionCreater, setPasswordActionCreater } = authSlice.actions;
    const { cloud, isTelegram } = useTypedSelector(state => state.telegram);
    
    const dispatch = useAppDispatch();

    const onDasboard = useCallback(() => {
        //dasboard({});
    }, [])

    useEffect(() => {
        if (localStorage.getItem("authToken") !== null) {
            onDasboard();
        }
    }, [onDasboard]);

    const onLogin = (userName: string, password: string) => {
        dispatch(loginActionCreater(userName, password, onDasboard));
    };

    const setUserName = useCallback((login: string) => {
        dispatch(setUserNameActionCreater(login));
    }, [dispatch, setUserNameActionCreater]);

    const setPassword = useCallback((password: string) => {
        dispatch(setPasswordActionCreater(password));
    }, [dispatch, setPasswordActionCreater])

    const registrationCreate = useRegistrationCreate();
    const onRegistrationCreate = useCallback(() => {
        registrationCreate();
    }, [registrationCreate]);

    const registationForgotPassword = useRegistrationForgotPassword();
    const onRegistrationForgotPasswordEmail = useCallback(() => {
        registationForgotPassword();
    }, [registationForgotPassword]);

    const onAuthByTelegram = useCallback((user: UserTelegram) => {
        dispatch(loginByTelegramActionCreater(user,cloud,isTelegram,onDasboard));
    }, [dispatch,onDasboard,cloud,isTelegram]);

    const onAuthByGoogle = useCallback(() => {
        dispatch(loginByGoogleActionCreater(onDasboard));
    }, [dispatch,onDasboard]);

    return (
        <RegistrationLogInView
            setUserName={setUserName}
            setPassword={setPassword}
            onLogin={onLogin}
            onRegistrationCreate={onRegistrationCreate}
            onRegistrationForgotPasswordEmail={onRegistrationForgotPasswordEmail}
            userName={userName}
            password={password}
            loading={loading}
            error={error}
            onAuthByGoogle={onAuthByGoogle}
            onAuthByTelegram={onAuthByTelegram}
        />
    );
});
