import { useEffect, useState } from "react";

export const useDebounced = (value : string, delay: number) => {

    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
          }, delay);

          return () => clearInterval(handler);
    }, [delay, value])

    return debouncedValue
}