export const privacyPolicyTextPortuguese = [
  {
    header: <>Introdução</>,
    text: <>
      A Bitrate se compromete a proteger a privacidade de seus usuários e a cumprir 
      todas as leis, princípios e regulamentos aplicáveis de proteção de dados. 
      Esta Política de Privacidade descreve as práticas de administração de informações 
      da Bitrate e dos serviços relacionados.
    </>
  },
  {
    header: <>Consentimento</>,
    text: <>
      Ao utilizar os serviços da Bitrate, incluindo o site e o aplicativo móvel, 
      os usuários concordam com os termos desta Política de Privacidade e com 
      as práticas de administração de informações. A Bitrate pode fornecer 
      notificações adicionais relacionadas às práticas de privacidade, que 
      complementarão esta Política de Privacidade.
    </>
  },
  {
    header: <>Alterações na Política de Privacidade</>,
    text: <>
      A Bitrate reserva-se o direito de modificar ou atualizar esta Política de 
      Privacidade a qualquer momento. Os usuários são incentivados a revisar esta 
      política periodicamente para verificar se houve alterações. Os usuários 
      serão notificados sobre alterações significativas por e-mail.
    </>
  },
  {
    header: <>Aplicabilidade</>,
    text: <>
      Esta Política de Privacidade se aplica a todos os usuários dos serviços da Bitrate, 
      incluindo indivíduos, comerciantes e entidades jurídicas. Os usuários incluem aqueles 
      que utilizam e/ou compram serviços da Bitrate ou serviços relacionados.
    </>
  },
  {
    header: <>Informações pessoais que coletamos</>,
    text: <>
      A Bitrate coleta apenas as informações necessárias para fornecer seus serviços. 
      Isso pode incluir:
      <ul style={{paddingLeft: 30, textIndent: 0, marginTop: 8}}>
        <li>Informações de identificação: como e-mail, número de telefone, dados do Telegram. 
          Caso seja necessário passar por uma verificação KYC, podem ser coletadas informações 
          de identificação adicionais.</li>
        <li>Informações financeiras: histórico de transações, dados de pagamento.</li>
        <li>Qualquer outra informação necessária com base na interação do usuário com a Bitrate.</li>
      </ul>
    </>
  },
  {
    header: <>Objetivo da coleta de informações</>,
    text: <>
      A Bitrate coleta informações pessoais para os seguintes fins:
      <ul style={{paddingLeft: 30, textIndent: 0, marginTop: 8}}>
        <li>Para melhorar a experiência do usuário e personalizar os serviços.</li>
        <li>Para proteger os usuários contra atividades fraudulentas e garantir a segurança.</li>
        <li>Para cumprir com requisitos regulatórios, incluindo as regras "Conheça seu Cliente" 
          (KYC) e combate à lavagem de dinheiro (AML).</li>
        <li>Para cumprir os termos e acordos com os usuários.</li>
      </ul>
    </>
  },
  {
    header: <>Compartilhamento de informações pessoais</>,
    text: <>
      A Bitrate pode compartilhar informações pessoais:
      <ul style={{paddingLeft: 30, textIndent: 0, marginTop: 8}}>
        <li>Com prestadores de serviços e parceiros envolvidos na prestação dos serviços da Bitrate.</li>
        <li>Com autoridades reguladoras para o cumprimento de obrigações legais.</li>
        <li>Com organizações envolvidas em transações comerciais ou fusões com a Bitrate.</li>
      </ul>
    </>
  },
  {
    header: <>Proteção das informações pessoais</>,
    text: <>
      A Bitrate implementa medidas de segurança para proteger os dados dos usuários contra 
      acesso, alteração ou divulgação não autorizados. Essas medidas incluem medidas de 
      segurança físicas, eletrônicas e processuais.
    </>
  },
  {
    header: <>Uso de cookies</>,
    text: <>
      A Bitrate pode utilizar cookies para melhorar a experiência do usuário e analisar o 
      tráfego do site. Os usuários podem gerenciar as configurações de cookies através das 
      opções do navegador.
    </>
  },
  {
    header: <>Direitos dos usuários</>,
    text: <>
      Os usuários têm o direito de acessar, corrigir, se opor e excluir as informações 
      pessoais mantidas pela Bitrate. Solicitações podem ser enviadas através das 
      informações de contato fornecidas.
    </>
  },
  {
    header: <>Entre em contato conosco</>,
    text: <>
      Para perguntas relacionadas a esta Política de Privacidade ou informações pessoais, 
      os usuários podem entrar em contato com a Bitrate através da janela de suporte.
    </>
  }
];
