import styled from "styled-components";
import { themes } from "../../../themes/theme";

export const Blur = styled.div`
  position: absolute;
  width: 100%;
  height: 686px;
  left: 0px;
  top: 0px;
  background: linear-gradient(
    0deg,
    rgba(183, 94, 213, 0.1) 75%,
    rgba(34, 255, 54, 0.1) 150%
  );
  filter: blur(45px);
  z-index: -1;
  border-radius: 170px;
`;

export const WalletBlur = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  box-sizing: border-box;
  inset: 0;
  border: 2px solid transparent;
  /* BorderColor */
  background: linear-gradient(157.44deg, #835c7c -10.89%, #242023 37.68%);
  backdrop-filter: blur(2.5px);
`;

export const StyledInput = styled.input<{ validate: boolean }>`
  width: 100%;
  height: 40px;
  border: ${({ validate }) =>
    validate ? "2px solid #3a3249" : "2px solid #AA0020"};
  background-color: transparent;
  color: ${themes.colors.foreground};
  border-radius: 10px;
  padding-inline: 16px;
  transition: 0.1s linear;
  &:focus {
    outline: none;
    border: 2px solid ${themes.colors.secondary};
  }
`;

export const ScrollContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 30px;
  &::-webkit-scrollbar {
    display: none;
  }
`;
