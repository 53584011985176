
import { FC, memo, ReactNode, useState } from 'react';
import { Blur, InformationContainer } from './terms-and-conditions.styled';
import { Column } from '../../../ui-kit/column';
import { Text } from '../../../ui-kit/text';
import { Spacing } from '../../../ui-kit/spacing';
import { themes } from '../../../ui-kit/themes/theme';
import { Surface } from '../../../ui-kit/surface';
import { Translate } from '../../../ui-kit/translate';
import { termsAndConditionsTextEng } from '../../../resources/texts/termsAndConditionsText/termsAndConditionsTextEng';
import { termsAndConditionsTextRu } from '../../../resources/texts/termsAndConditionsText/termsAndConditionsTextRu';
import { termsAndConditionsTextPortuguese } from '../../../resources/texts/termsAndConditionsText/termsAndConditionsTextPortuguese';
import { termsAndConditionsTextSpain } from '../../../resources/texts/termsAndConditionsText/termsAndConditionsTextSpain';

type LanguageCode = 'en' | 'ru' | 'pt' | 'ES';

const textOptions: Record<LanguageCode, dataTextType[]> = {
  en: termsAndConditionsTextEng,
  ru: termsAndConditionsTextRu,
  pt: termsAndConditionsTextPortuguese,
  ES: termsAndConditionsTextSpain,
};

export type dataTextType = {
  header: ReactNode,
  text: ReactNode
}

export type TermsAndConditionsViewProps = {
  language: string
};

export const TermsAndConditionsView: FC<TermsAndConditionsViewProps> = memo(({language}) => {

  const [data] = useState<dataTextType[]>(textOptions[language as LanguageCode] || termsAndConditionsTextEng);

  return (
    <Column style={{ height: "100vh", position: "relative", overflow: 'auto' }} horizontalAlign="center">
      <Blur />
      <InformationContainer>
        <Surface padding={25}>
          <Text align='center' themeColor={themes.colors.gray} themeFont={themes.fonts.h1}>
            <Translate keyWord='account.tc' />
          </Text>
          <Spacing themeSpace={25} variant='Column' />
          { data.map(item => <Paragraph header={item.header} text={item.text}/>) }
        </Surface>
      </InformationContainer>
    </Column>
  )
});

export type ParagraphProps ={
  header?: ReactNode;
  text?: ReactNode;
}

export const Paragraph: FC<ParagraphProps> = memo(({
  header,
  text
}) => {
  return (
    <>
      <Text style={{fontSize: 16, lineHeight: 1.2, textAlign: 'center'}} themeColor={themes.colors.secondary} themeFont={themes.fonts.t}>
        <b>{header}</b>
      </Text>
      <br/>
      <Text style={{fontSize: 14, lineHeight: 1.3, textAlign: 'justify',textIndent: 30}} themeColor={themes.colors.foreground} themeFont={themes.fonts.t}>
        {text}
      </Text>
      <br/>
    </>
  );
});