
import { useNavigate } from 'react-router';

export type SitAndGoRoomsProps = {};
export const useSitAndGoRooms = () => {

    const navigate = useNavigate();
    const sitAndGoRooms = (props?: SitAndGoRoomsProps) => {
        navigate('/sit-and-go-rooms', { state: props });
    };

    return sitAndGoRooms;
};