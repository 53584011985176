import { walletApi } from "../../../api/wallet"

export const apiGetEsimatePrice = async (
    authToken: string,
    amount: string,
    currencyFrom: string,
    currencyTo: string
) => {
    const response = await walletApi(authToken).getEstimatePrice(amount, currencyFrom, currencyTo);

    if (response.error) throw response;
    return response.data;
}