import { FC, HtmlHTMLAttributes, memo } from "react";
import { ContainerCircleLoading, StyledCircleLoading } from "./circle-loading.styles";

export type CircleLoadingProps = {
  color?: string;
  width?: number;
  height?: number;
  state?: "idle" | "loading" | "success" | "error";
} & HtmlHTMLAttributes<HTMLElement>;
  
export const CircleLoading: FC<CircleLoadingProps> = memo(({state = 'idle', color, width, height, ...rest }) => 
  <ContainerCircleLoading>
    { state === 'loading' && <StyledCircleLoading width={width} color={color} height={height} {...rest}/>}
  </ContainerCircleLoading>
);
  