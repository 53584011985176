import { FC, HtmlHTMLAttributes, ReactNode, memo } from "react";
import { StyledSkeleton } from "./skeleton.styles";

export type SkeletonProps = {
   variant?: 'background' | 'foreground';
   width?: string;
   height?: string;
   isSkeleton: boolean;
   maxWidth?: string;
   maxHeight?: string;
   children: ReactNode;
} & HtmlHTMLAttributes<HTMLElement>;

export const Skeleton: FC<SkeletonProps> = memo(({
   isSkeleton, width = '100%', height = '100%',
   children, 
   variant = 'foreground', 
   maxWidth = '100%', maxHeight = '100%',
   ...rest }) => {

   return (
      isSkeleton ?
      <StyledSkeleton 
         variant={variant} 
         width={width} maxHeight={maxHeight}
         height={height} maxWidth={maxWidth}
      {...rest}/> :
      <>
         {children}
      </>
   )
});