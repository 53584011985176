import styled from "styled-components";
import { themes } from "../../../../ui-kit/themes/theme";

export const Blur = styled.div`
position: absolute;
width: 100%;
height: 100%;

background: linear-gradient(0deg, rgba(183, 94, 213, 0.1) 53.97%, rgba(34, 255, 54, 0.1) 150.62%);
filter: blur(45px);
`

export const ImageSurface = styled.div<{
  src: string
}>`
  display: flex;
  position: relative;
  aspect-ratio: 1 / 1;
  height: 100%;
  cursor: pointer;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  padding: 0px;
  margin: 0px;
  width: 100%;
  min-width: 130px;
  min-height: 130px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
`;

export const SitAndGoUsdtSurface = styled.div<{
  src: string
}>`
  display: flex;
  aspect-ratio: 2.25 / 1;
  width: 100%;
  cursor: pointer;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: top;
  
`;

export const ImageGameBackground = styled.div<{
  src: string;
  widthRatio: number;
  heightRatio: number;
  isSoon: boolean;
}>`
  display: flex;
  aspect-ratio: ${props => props.widthRatio} / ${props => props.heightRatio};
  width: 100%;
  cursor: pointer;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: top;
  flex-direction: column;
  justify-content: flex-end;
  padding: 8px;
  ${props => props.isSoon && 'filter: blur(2px);'}
`;

export const SurfaceNameGame = styled.div<{
  strokePanel: boolean;
  padding?: number | [number?, number?, number?, number?];
}>`
height: 30px;
width: 100%;
display: flex;
border-radius: 20px;
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
box-shadow: 0 -3px 15px 0 rgba(186, 167, 255, 0.1);
background: rgba(28, 28, 28, 0.5);
border: ${props => props.strokePanel ? '1px' : '0px'} solid ${themes.colors.gradients.foreground};
padding: ${({ padding }) => {
    if (typeof padding === "number") {
      return `${padding}px`;
    }
    if (Array.isArray(padding)) {
      if (padding.length === 1) {
        return padding[0];
      }
      if (padding.length === 2) {
        return `${padding[0]}px ${padding[1]}px`;
      }
      if (padding.length === 4) {
        return `${padding[0]}px ${padding[1]}px ${padding[2]}px ${padding[3]}px`;
      }
    }
    return "0px";
}};
&:before {
  content: "";
  position: absolute;
  inset: 0px;
  border-radius: 20px;
  border: ${props => props.strokePanel ? '1px' : '0px'} solid transparent;
  background: ${themes.colors.gradients.foreground};
  mask: 
  linear-gradient(#fff 0 0) padding-box, 
  linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude;
    opacity: 0.5;
}
`