import styled from "styled-components";

export const Blur = styled.div`
position: absolute;
width: 100%;
height: 615px;
left: 0px;
top: 68px;

background: linear-gradient(0deg, rgba(183, 94, 213, 0.1) 53.97%, rgba(34, 255, 54, 0.1) 150.62%);
filter: blur(45.032px);

`

export const BackgroundPanel = styled.img`
    position: absolute;
    width: 100%;
    height: 204px;
    object-fit: cover;
`;

export const DescriptionSection = styled.div`
    width: 100%;
    height: 92px;
    z-index: 2;
    position: absolute;
    bottom: 0px;
    border: none;
    background-color: rgba(28,28,28, 0.6);
    border-top: ridge rgba(186, 167, 255, 0.2);
    border-radius: 10px 10px 0px 0px;
`

export const CustomGrid = styled.div`
    display: grid;
    justify-items: center;
    width: 100%;
    grid-template-columns: auto;
    gap: 10px;
`;

export const ScrollContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow-y: auto;
    padding-top: 30px;
    padding-bottom: 80px;
    &::-webkit-scrollbar{
        display: none;
    }
`