import styled from "styled-components";

export const WrapperSegment = styled.div<{currentAngle: number}>`
    width: 245px;
    height: 245px;
    position: absolute;
    transform: rotate( ${(({ currentAngle }) => `${currentAngle}deg`)});
    transition: transform 15s cubic-bezier(0.33, 1, 0.68, 1);
    will-change: transform;
    backface-visibility: hidden;

    div{
      width: 245px;
      height: 245px;
      position: absolute;
      display: flex;
      
      svg{
        transform: rotate(29deg);
        position: absolute;
        left: 50%;
      }
    }
    
    div:nth-child(1) {
      transform: rotate(0deg);
    }
    div:nth-child(2) {
      transform: rotate(45deg);
    }
    div:nth-child(3) {
      transform: rotate(90deg);
    }
    div:nth-child(4) {
      transform: rotate(135deg);
    }
    div:nth-child(5) {
      transform: rotate(180deg);
    }
    div:nth-child(6) {
      transform: rotate(225deg);
    }
    div:nth-child(7) {
      transform: rotate(270deg);
    }
    div:nth-child(8) {
      transform: rotate(315deg);
    }
`

export const WrapperFortuneWhile = styled.div`
    width: 245px;
    height: 245px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`

export const RingFortuneWhile = styled.div<{size: number}>`
    border: 1px solid rgba(186, 167, 255, 0.2);
    width: ${(({ size }) => `${size}px`)};
    border-radius: 50%;
    height: ${(({ size }) => `${size}px`)};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`

export const ArrowWrapper = styled.div`
  position: absolute;
  z-index: 100;
  display: flex;
  width: 100%;
  top: -10px;
  justify-content: center;
  align-items: center;
  transform: translateZ(0);
`

export const WrapperWhileFooter = styled.div`
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1C1C1C;
  position: absolute;
  z-index: 1000;
  width: 100%;
  bottom: 0;
`