import { useUser } from "./user-hook"
import { useAppDispatch, useTypedSelector } from "./use-typed-selector";
import { useEffect } from "react";
import { getPnlUserInfo } from "../store/reducers/pnl-slice";

export const usePnlUser = () => {
    const { authToken } = useUser();
    const dispatch = useAppDispatch();
    const { pnlUserInfo, state } = useTypedSelector(state => state.pnl);

    useEffect(() => {
        dispatch(getPnlUserInfo({ authToken: authToken }));
    }, [authToken, dispatch])

    return { pnlUserInfo, state };
}