import { useCallback, useEffect } from "react";
import { useAppDispatch, useTypedSelector } from "../use-typed-selector"
import { useUser } from "../user-hook";
import { initFarmAction, intervalTapFarmAction, tapFarmAction } from "../../store/reducers/swiper/swiper-farm-slice";

export const useFarm = () => {
    const { farm } = useTypedSelector(state => state.swiperFarm);
    const { authToken } = useUser();
    const dispatch = useAppDispatch();

    const init = useCallback(() => {
        dispatch(initFarmAction({ authToken: authToken }));
    }, [authToken, dispatch])

    useEffect(() => {
        init();
    }, [init,farm.energy]);

    const tap = useCallback(() => {
        dispatch(tapFarmAction({ authToken: authToken }));
    }, [authToken, dispatch]);

    const intervalTap = useCallback(() => {
        dispatch(intervalTapFarmAction({ authToken: authToken }));
    }, [authToken, dispatch]);

    return {
        farm: farm,
        tap: tap,
        intervalTap: intervalTap
    }
}