import styled from "styled-components";
import SVG from "react-inlinesvg";

export type SVGProps = {
  color: string;
  size: number
}

export const StyledSVG = styled(SVG)<SVGProps>`
  width: ${(({size}) => `${size}px`)};
  height: ${(({size}) => `${size}px`)};
  & path {
    stroke: ${({ color }) => color};
  }
`;