
import { FC, memo, ReactNode, useState } from 'react';
import { Blur, InformationContainer } from './privacy-policy.styled';
import { Column } from '../../../ui-kit/column';
import { Text } from '../../../ui-kit/text';
import { themes } from '../../../ui-kit/themes/theme';
import { Spacing } from '../../../ui-kit/spacing';
import { Surface } from '../../../ui-kit/surface';
import { Translate } from '../../../ui-kit/translate';
import { privacyPolicyTextEng } from '../../../resources/texts/privacyPolicyText/privacyPolicyTextEng';
import { privacyPolicyTextRu } from '../../../resources/texts/privacyPolicyText/privacyPolicyTextRu';
import { privacyPolicyTextSpain } from '../../../resources/texts/privacyPolicyText/privacyPolicyTextSpain';
import { privacyPolicyTextPortuguese } from '../../../resources/texts/privacyPolicyText/privacyPolicyTextPortuguese';

type LanguageCode = 'en' | 'ru' | 'pt' | 'ES';

const textOptions: Record<LanguageCode, dataTextType[]> = {
  en: privacyPolicyTextEng,
  ru: privacyPolicyTextRu,
  pt: privacyPolicyTextPortuguese,
  ES: privacyPolicyTextSpain,
};

export type dataTextType = {
  header: ReactNode,
  text: ReactNode
}

export type PrivacyPolicyViewProps = {
  language: string
};
export const PrivacyPolicyView: FC<PrivacyPolicyViewProps> = memo(({language}) => {

  const [data] = useState<dataTextType[]>(textOptions[language as LanguageCode] || privacyPolicyTextEng);

  return (
    <Column style={{ height: "100vh", position: "relative", overflow: 'auto' }} horizontalAlign="center">
      <Blur />
      <InformationContainer>
        <Surface padding={25}>
          <Text align='center' themeColor={themes.colors.gray} themeFont={themes.fonts.h1}>
            <Translate keyWord='account.policy' />
          </Text>
          <Spacing themeSpace={25} variant='Column' />
          { data.map(item => <Paragraph header={item.header} text={item.text}/>) }
        </Surface>
      </InformationContainer>
    </Column>
  )
});

export type ParagraphProps ={
  header?: ReactNode;
  text?: ReactNode;
}

export const Paragraph: FC<ParagraphProps> = memo(({
  header,
  text
}) => {
  return (
    <>
      <Text style={{fontSize: 16, lineHeight: 1.2, textAlign: 'center'}} themeColor={themes.colors.secondary} themeFont={themes.fonts.t}>
        <b>{header}</b>
      </Text>
      <br/>
      <Text style={{fontSize: 14, lineHeight: 1.3, textAlign: 'justify',textIndent: 30}} themeColor={themes.colors.foreground} themeFont={themes.fonts.t}>
        {text}
      </Text>
      <br/>
    </>
  );
});