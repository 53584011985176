import { ChangeEvent, FC, HTMLAttributes, ReactNode, memo, useCallback, useState } from "react";
import { ErrorChild, InputButton, InputContainer, InputStyled, Label } from "./input.styles";
import watchInput from "../../images/watchInput.svg"
import noWatchInput from "../../images/noWatchInput.svg"
import resetInput from "../../images/resetInput.svg"
import { Spacing } from "../spacing";
import { FieldValues, UseFormRegister } from "react-hook-form";
import { theme } from "../../themes/theme";
import { useMediaQuery } from "react-responsive";

export type InputProps = {
  placeholder?: string;
  placeholderInput?: string;
  name: string;
  width?: number | string;
  value?: string;
  register: UseFormRegister<FieldValues>;
  type?: "text" | "password";
  maxLength?: Lenght;
  minLength?: Lenght;
  required?: boolean;
  pattern?: Pattern;
  disabled?: boolean;
  typeButton?: "input" | "password";
  errorInput?: ReactNode;
  setValue?: (value: string) => void;
  validate?: (value: any) => true | string;
  maxWidth?: number;
} & HTMLAttributes<HTMLInputElement>;

export type Lenght = {
  value: number;
  message: string,
};

export type Pattern = {
  value: RegExp;
  message: string;
}


export const Input: FC<InputProps> = memo(({maxWidth, setValue, disabled, value, validate, width, pattern, required, placeholder, placeholderInput, name, type, register, minLength, maxLength, typeButton, errorInput,...rest }) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (setValue !== undefined) {
      setValue(event.target.value);
    }
  };

  const reset = () => {
    if (setValue !== undefined)
      setValue("");
  }

  const [inputWathc, setWatch] = useState<Boolean>(false);

  const onClickWatch = useCallback(() => {
    setWatch(!inputWathc);
  }, [inputWathc]);


  const validationRules = {
    required: required !== undefined ? required : true,
    onChange: (e: ChangeEvent<HTMLInputElement>) => handleInputChange(e),
    ...(minLength && {
      minLength: {
        value: minLength.value,
        message: minLength.message,
      },
    }),
    ...(maxLength && {
      maxLength: {
        value: maxLength.value,
        message: maxLength.message,
      },
    }),
    ...(pattern && {
      pattern: {
        value: pattern.value,
        message: pattern.message,
      },
    }),
    ...(validate && {
      validate: (value: any) => validate(value)
    }),
  };

  const isMobile = useMediaQuery({ maxWidth: theme.toMobileSize });
  return (
    <InputContainer width={width} maxWidth={maxWidth}>
      {placeholder !== undefined && <Label>{placeholder}</Label>}
      <Spacing variant="Column" themeSpace={(isMobile) ? 2 : 10} />
      <InputStyled
        maxLength={maxLength?.value}
        minLength={minLength?.value}
        placeholder={placeholderInput}
        disabled={disabled}
        id={name}
        required={true}
        value={value}
        type={type === "text" || type === undefined ? "text" : inputWathc ? "text" : "password"}
        {...register(name, validationRules)}
        {...rest}
      >
      </InputStyled>
      {typeButton === "input" &&
        <InputButton
          style={disabled ? { display: 'none' } : {}}
          onClick={reset}
          src={resetInput}
          placeholder={placeholder} />
      }
      {typeButton === "password" &&
        <InputButton onClick={onClickWatch} src={inputWathc ? watchInput : noWatchInput} placeholder={placeholder} />
      }
      <Spacing variant="Column" themeSpace={5} />
      <ErrorChild>
        {errorInput}
      </ErrorChild>
    </InputContainer>
  );
});

