import { FC, memo } from "react";
import { useUser } from "../../hooks/user-hook";

import russianJsonRaw from '../../resources/languages/russian.json';
import englishJsonRaw from '../../resources/languages/english.json';
import spainJsonRaw from '../../resources/languages/spain.json';
import portugueseJsonRaw from '../../resources/languages/portuguese.json';

const russianJson = russianJsonRaw as Record<string, string>;
const englishJson = englishJsonRaw as Record<string, string>;
const spainJson = spainJsonRaw as Record<string, string>;
const portugueseJson = portugueseJsonRaw as Record<string, string>;

export type TranslateProps = {
   keyWord: string;
};

export const Translate : FC<TranslateProps> = memo(({keyWord}) => {
    const {language} = useUser();

    switch(language){
        case 'ru':
            return russianJson[keyWord] || "undefined";
        case 'pt':
            return portugueseJson[keyWord] || "undefined";
        case 'ES':
            return spainJson[keyWord] || "undefined";
        case 'en':
        default: 
            return englishJson[keyWord] || "undefined";
    }
});

export const useTranslation = () => {
    const { language } = useUser();

    const getTranslation = (keyWord: string): string => {
        switch (language) {
            case 'ru':
                return russianJson[keyWord] || "undefined";
            case 'portuguese':
                return portugueseJson[keyWord] || "undefined";
            case 'spain':
                return spainJson[keyWord] || "undefined";
            case 'en':
            default:
                return englishJson[keyWord] || "undefined";
        }
    };

    return getTranslation;
};