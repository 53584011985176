import { useNavigate } from "react-router";

export type RegistrationForgotPasswordEmailProps = {

};

export const useRegistrationForgotPassword = () => {
    const navigate = useNavigate();
    const registrationForgotPassword = (props?: RegistrationForgotPasswordEmailProps) => {
        navigate('/registration-forgot-passwprd-email', props);
    }

    return registrationForgotPassword;
}
