import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../../..";
import { getUserInfoActionCreater } from "../../user-slice";
import { phoneSecurityApi } from "../../../../api/security/phone-security-api";
import { appStatusSlice } from "../../app-status-slice";

export type PhoneSecurityState = {
    phone: string;
    code: string;
    phoneTimer: number;
    error: string | null;
    errorVerify: string | null;
    errorPhone: string | null;
    intervalSms: NodeJS.Timer | null;
    isGotCode: boolean;
    status: 'sended' | 'wait';
    isVerify: "idle" | "loading" | "success" | "error";
};

const initialState: PhoneSecurityState = {
    phone: '',
    code: '',
    phoneTimer: 0, 
    errorVerify: null,
    errorPhone: null,
    isGotCode: false,
    intervalSms: null,
    error: null,
    status: 'wait',
    isVerify: 'idle'
};


export const phoneSecuritySlice = createSlice({
    name: "phone-security",
    initialState: initialState,
    reducers: {
        fetchErrorActionCreater(state, action: PayloadAction<string>) {
            state.error = action.payload;
        },
        setVerifyErrorActionCreater(state, action: PayloadAction<string | null>) {
            state.errorVerify = action.payload;
        },
        setErrorPhoneActionCreater(state, action: PayloadAction<string | null>) {
            state.errorPhone = action.payload;
        },
        setPhoneActionCreater(state, action: PayloadAction<string>) {
            state.phone = action.payload;
        },
        setIsVerifyActionCreater(state, action: PayloadAction<"idle" | "loading" | "success" | "error">) {
            state.isVerify = action.payload;
        },
        setPhoneTimerActionCreater(state, action: PayloadAction<{ time: number,interval: NodeJS.Timer | null}>) {
            if(action.payload.interval ===  null) {
                state.phoneTimer = action.payload.time;
                return;
            }
            if(action.payload.time !== 0){
                state.phoneTimer = action.payload.time;
            } else {
                state.phoneTimer = 0;
                clearInterval(action.payload.interval);
                state.status = 'wait';
            };
        },
        setIntervalSms(state, action: PayloadAction<NodeJS.Timer | null>) {
            state.intervalSms = action.payload;
        },
        setStatus(state, action: PayloadAction<'wait' | 'sended'>) {
            state.status = action.payload;
        },
        setCodeActionCreater(state, action: PayloadAction<string>) {
            state.code = action.payload;
        },
        setIsGotTrue(state) {
            state.isGotCode = true;
        },
        reset(state) {
            if(state.intervalSms !==  null){
                clearInterval(state.intervalSms);
            }
            state.phone = '';
            state.code = '';
            state.phoneTimer = 0;
            state.errorVerify = null;
            state.errorPhone = null;
            state.isGotCode = false;
            state.status = 'wait';
            state.error = null;
        }
    }
});

export const sendCodeOnPhoneActionCreater = (userName: string, phoneTimer: number, phone: string, authToken: string, status: 'wait' | 'sended') => {
    return (dispatch: AppDispatch) => {
        if(phoneTimer !== 0 || status === 'sended') return;  
        dispatch(phoneSecuritySlice.actions.setStatus('sended'));  

        phoneSecurityApi.sendCodeOnPhone(userName, phone, authToken)
            .then(responce => {
                dispatch(phoneSecuritySlice.actions.setErrorPhoneActionCreater(null));
                dispatch(phoneSecuritySlice.actions.setVerifyErrorActionCreater(null));

                dispatch(phoneSecuritySlice.actions.setIsGotTrue());

                dispatch(phoneSecuritySlice.actions.setPhoneTimerActionCreater({time: 30, interval: null}));     

                let secound: number = 30;
                let intervalId: NodeJS.Timeout = setInterval(() => {
                if (secound > 0) { 
                    dispatch(phoneSecuritySlice.actions.setPhoneTimerActionCreater({time: --secound, interval: intervalId}))} else { clearInterval(intervalId); }
                }, 1000);
                dispatch(phoneSecuritySlice.actions.setIntervalSms(intervalId));
            })
            .catch((error) => {
                dispatch(phoneSecuritySlice.actions.setErrorPhoneActionCreater("Phone entered incorrectly"));
                dispatch(phoneSecuritySlice.actions.setStatus('wait'));
                if(error?.responce.status === 401){
                    dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                }
            })
    }
}

export const verifySendedCodeOnPhoneActionCreater = (userName: string, phone: string, code: string, authToken: string, onSuccessfully?: () => void) => {
    return (dispatch: AppDispatch) => {
        dispatch(phoneSecuritySlice.actions.setIsVerifyActionCreater('loading'));

        phoneSecurityApi.verifySendedCodeOnPhone(userName, code, authToken)
            .then(responce => {
                if(responce.verified){
                    phoneSecurityApi.updatePhone(phone, authToken)
                        .then(responce => {
                            dispatch(phoneSecuritySlice.actions.reset());
                            dispatch(getUserInfoActionCreater(authToken));
                            if(onSuccessfully !== undefined) onSuccessfully();
                            dispatch(phoneSecuritySlice.actions.setIsVerifyActionCreater('success'));
                        })
                        .catch((err) => {
                            dispatch(phoneSecuritySlice.actions.setIsVerifyActionCreater('error'));
                            dispatch(phoneSecuritySlice.actions.setErrorPhoneActionCreater('Phone is already in use'));
                            dispatch(phoneSecuritySlice.actions.setVerifyErrorActionCreater(null));
                            if(err?.responce.status === 401){
                                dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                            }
                        })
                } else {
                    dispatch(phoneSecuritySlice.actions.setIsVerifyActionCreater('error'));
                    dispatch(phoneSecuritySlice.actions.setVerifyErrorActionCreater('The code doesn’t work. Try again'));
                }
            })
            .catch((err) => {
                dispatch(phoneSecuritySlice.actions.setIsVerifyActionCreater('error'));
                dispatch(phoneSecuritySlice.actions.setVerifyErrorActionCreater('The code doesn’t work. Try again'));
                if(err?.responce.status === 401){
                    dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                }
            })
    }
}

export const disableTwoFactorPhone = (authToken: string) => {
    return (dispatch: AppDispatch) => {
        phoneSecurityApi.disablePhone(authToken)
            .then(responce => {
                dispatch(getUserInfoActionCreater(authToken));
                dispatch(phoneSecuritySlice.actions.reset());
            })
            .catch((err) => {
                dispatch(phoneSecuritySlice.actions.fetchErrorActionCreater('The data has not been updated'));
                if(err?.responce.status === 401){
                    dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                }
            })
    }
}

export default phoneSecuritySlice.reducer;