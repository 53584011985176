import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { registrationApi } from "../../api/registration-api";
import { AppDispatch } from "..";
import { twoFactorApi } from "../../api/two-factor-api";
import { AxiosError } from "axios";

export type RegistrationState = {
    email: string;
    tel: string;
    password: string;
    confirmPassword: string;
    referralCode: string;
    loading: "idle" | "loading" | "success" | "error";
    error: string | null;
    errorEmail: string | null;
    errorPassword: string | null;
    errorConfirmPassword: string | null;
    errorTel: string | null;
}

const initialState: RegistrationState = {
    email: '',
    tel: '',
    password: '',
    confirmPassword: '',
    referralCode: '',
    loading: "idle",
    error: null,
    errorTel: null,
    errorEmail: null,
    errorPassword: null,
    errorConfirmPassword: null,
}


export const registrationSlice = createSlice({
    name: 'registration',
    initialState: initialState,
    reducers: {
        setUserName(state, action: PayloadAction<{ variant: 'email' | 'tel', value: string }>) {
            state.error = null;
            state.errorTel = null;
            if(action.payload.variant === 'email' && state.errorEmail !== null){
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                state.email = action.payload.value;
                if (emailRegex.test(action.payload.value)) {
                    state.errorEmail = null;
                }
            } else state[action.payload.variant] = action.payload.value;
        },
        setPassword(state, action: PayloadAction<string>) {
            state.password = action.payload;

            if(state.errorPassword !== null){
                if( state.password.length >= 8)
                    state.errorPassword = null;
            }
        },
        setConfirmPasswordActionCreater(state, action: PayloadAction<string>) {
            state.confirmPassword = action.payload;

            if(state.errorConfirmPassword !== null){
                if(state.confirmPassword === state.password)
                    state.errorConfirmPassword = null;
            }
        },
        setReferral(state, action: PayloadAction<string>) {
            state.referralCode = action.payload;
        },
        reset(state) {
            state.email = '';
            state.tel =  '';
            state.password = '';
            state.confirmPassword = '';
            state.referralCode = '';
            state.loading = "idle";
            state.error = null;
            state.errorTel = null;
            state.errorEmail = null;
            state.errorPassword = null;
            state.errorConfirmPassword = null;
        },
        fetchRegistration(state) {
            state.loading = "loading";
        },
        fetchRegistrationSuccess(state) {
            state.loading = "success";
            state.error = null;
            state.errorTel = null;
            state.errorEmail = null;
        },
        setEmailError(state,action: PayloadAction<string | null>) {
            state.errorEmail = action.payload;
        },
        setTelError(state,action: PayloadAction<string | null>) {
            state.errorTel = action.payload;
        },
        setPasswordError(state,action: PayloadAction<string | null>) {
            state.errorPassword = action.payload;
        },
        setConfirmPasswordError(state,action: PayloadAction<string | null>) {
            state.errorConfirmPassword = action.payload;
        },
        fetchRegistrationError(state, action: PayloadAction<{ variant: 'email' | 'tel', value: string }>) {
            state.loading = "error";
            if(action.payload.variant === 'email'){
                state.errorEmail = action.payload.value
            }
            if(action.payload.variant === 'tel'){
                state.errorTel = action.payload.value
            }
        }
    }
});

export const registrationActionCreater = (userName: string, password: string, confirmPassword: string, referralCode: string, variant: "Email" | "Phone",onSuccessfully?: () => void) => {
    return (dispatch: AppDispatch) => {
        dispatch(registrationSlice.actions.fetchRegistration());

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if ((!emailRegex.test(userName) && variant === 'Email')) {
            dispatch(registrationSlice.actions.setEmailError("Email entered incorrectly"));
            return;
        }
        dispatch(registrationSlice.actions.setEmailError(null));


        if (password.length < 8) {
            dispatch(registrationSlice.actions.setPasswordError("Password must be 8 characters or longer"));
            return;
        }
        dispatch(registrationSlice.actions.setPasswordError(null));


        if (password !== confirmPassword) {
            dispatch(registrationSlice.actions.setConfirmPasswordError("Passwords do not match"));
            return;
        }
        dispatch(registrationSlice.actions.setConfirmPasswordError(null));

        dispatch(registrationSlice.actions.setTelError(null));

        registrationApi.registration(userName, password, confirmPassword, referralCode === '' ? null : referralCode )
            .then(responce => {
                dispatch(registrationSlice.actions.fetchRegistrationSuccess());
                if(onSuccessfully) onSuccessfully();
            })
            .catch((error) => {
                const responce: any = error.response?.data.message || "Error";
                dispatch(registrationSlice.actions.fetchRegistrationError({
                    variant: variant === "Email" ? "email" : "tel",
                    value: responce
                }
                ));
            })
    }
}

export const authActionCreater = (userName: string, password: string, onSuccessfully?: () => void) => {
    return (dispatch: AppDispatch) => {
        twoFactorApi.welcome(userName, password)
            .then(responceWelcome => {
                localStorage.setItem('authToken',responceWelcome.accessToken);
                if(onSuccessfully) onSuccessfully();
            })
            .catch((error) => {
                const err = error as AxiosError;
                const responce: any = err.response?.data;
                dispatch(registrationSlice.actions.fetchRegistrationError(responce.message));
            })
    }
}

export default registrationSlice.reducer;