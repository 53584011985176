import styled from "styled-components";

export const ScrollViewContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  max-height: 100%;
  overflow: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar{
      display: none;
  }
`;