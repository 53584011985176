import { ChangeEvent, FC, InputHTMLAttributes, memo } from "react";
import { Column } from "../../../../ui-kit/column";
import {
  AccentBlock,
  Blur,
  Circle,
  GameParent,
  Grid,
  GridRow,
  InputStyled,
} from "./sit-and-go.styled";
import { Row } from "../../../../ui-kit/row";
import { Pressable } from "../../../../сomponents/pressable";
import { Icon } from "../../../../ui-kit/icon";
import { FontProps, themes } from "../../../../themes/theme";
import { Text } from "../../../../ui-kit/text";
import { Image } from "../../../../ui-kit/image";
import { Spacing } from "../../../../ui-kit/spacing";
import { Surface } from "../../../../ui-kit/surface";

import leave from "../../../../ui-kit/assets/leave.svg";
import bets from "../../../../ui-kit/assets/bets.svg";

import {
  FinishPayloadType,
  GameStatus,
  UserDataType,
} from "../../../../api/sit-go/game-api";
import Chart, { ChartData } from "../../../../ui-kit/chart/chart";
import { formatTime } from "../../../../data/room-info";
import { Popup } from "../../../../ui-kit/popup";
import { Button } from "../../../../ui-kit/button";
import { PrizePoolDataType } from "../../../../store/reducers/sit-and-go/rooms-slice";
import { EndView } from "../../end-view/end.view";
import { SSEStatus } from "../../../../api/chart-api";
import { Translate } from "../../../../ui-kit/translate";
import { useTranslation } from "../../../../ui-kit/translate/translate";

type GameStatusPriview = GameStatus | "PADDING";
export type SitAndGoViewProps = {
  players: { name: string; picture: string; size: number }[];
  chartData: ChartData;
  status: GameStatusPriview;
  userIsSeat: boolean;
  prizePool: PrizePoolDataType[];

  onMakeBet: () => void;
  onSeat: () => void;
  onLeave: () => void;
  onLobby: () => void;

  maxPlayers: number;
  timeRoom: string;
  deposite: string;

  timer: number;

  inputValue: string;
  setInputValue: (value: string) => void;
  isMakeBet: boolean;
  makeBetValue: number;

  validatePopup: boolean;
  closeValidatePopup: () => void;
  currencies: "USDTERC20" | "BTRT";

  prizePoolPopup: boolean;
  onPrizePoolPopup: () => void;
  closePrizePoolPopup: () => void;

  playersBetPopup: boolean;
  onPlayersBetPopup: () => void;
  closePlayersBetPopup: () => void;
  playersBets: UserDataType[];

  amountGamePopup: boolean;
  closeAmountGamePopup: () => void;

  end: FinishPayloadType | null;
  timestamp: string;
  statusChart: SSEStatus;
  onReconnect: () => void;
};
export const SitAndGoView: FC<SitAndGoViewProps> = memo(
  ({
    players,
    prizePool,
    status,
    onLobby,
    chartData,
    onSeat,
    onLeave,
    userIsSeat,
    maxPlayers,
    deposite,
    timeRoom,
    timer,
    inputValue,
    setInputValue,
    onMakeBet,
    isMakeBet,
    makeBetValue,
    validatePopup,
    closeValidatePopup,
    currencies,
    prizePoolPopup,
    onPrizePoolPopup,
    closePrizePoolPopup,
    end,
    timestamp,
    playersBetPopup,
    onPlayersBetPopup,
    closePlayersBetPopup,
    playersBets,
    amountGamePopup,
    closeAmountGamePopup,
    onReconnect,
    statusChart,
  }) => {
    return status === "ENDED" && end !== null ? (
      <EndView
        back={onLobby}
        topThree={
          end.leaders.length > 2 ? end.leaders.slice(0, 3) : [end.leaders[0]]
        }
        leaders={end.leaders}
        time={timestamp}
        rate={end.rate}
      />
    ) : (
      <GameParent horizontalAlign="center">
        <Blur />
        <HeaderGame
          onPrizePool={onPrizePoolPopup}
          deposite={deposite}
          time={timeRoom}
          maxPlayers={maxPlayers}
          onLobby={onLobby}
        />
        <Spacing themeSpace={30} variant="Column" />
        <GameState
          isMakeBet={isMakeBet}
          makeBetValue={makeBetValue}
          inputValue={inputValue}
          setInputValue={setInputValue}
          maxPlayers={maxPlayers}
          onLeave={onLeave}
          onPrizePool={onPrizePoolPopup}
          onSeat={onSeat}
          status={status}
          userIsSeat={userIsSeat}
          timer={timer}
          onMakeBet={onMakeBet}
          onPlayersBetPopup={onPlayersBetPopup}
        />
        <Spacing themeSpace={10} variant="Column" />
        <TableView
          price={
            chartData.length > 0 ? chartData[chartData.length - 1].value : 0
          }
          statusChart={statusChart}
          onReconnect={onReconnect}
          chartData={chartData}
          players={players}
        />
        <Spacing themeSpace={40} variant="Column" />
        <Popup
          style={{ maxWidth: "300px" }}
          showCloseButton={false}
          active={validatePopup}
          closePopup={closeValidatePopup}
          width={"100%"}
          height={200}
          padding={[35, 35]}
          closeByExternalClick={false}
        >
          <Column horizontalAlign="center">
            <Text align="center" themeFont={themes.fonts.h2}>
              <Translate keyWord="sitgo.popup.funds.part1" />.<br></br>{" "}
              <span style={{ color: `${themes.colors.secondary}` }}>
                <Translate keyWord="sitgo.popup.funds.part2" />.
              </span>
            </Text>
            <Spacing variant="Column" themeSpace={30} />
            <Row
              style={{ width: "100%" }}
              horizontalAlign="space-around"
              verticalAlign="center"
            >
              {currencies === "USDTERC20" && (
                <Button height={40} width={110} onClick={closeValidatePopup}>
                  <Text
                    align="center"
                    themeColor={themes.colors.surface}
                    themeFont={themes.fonts.h4}
                  >
                    <Translate keyWord="sitgo.deposite.button" />
                  </Text>
                </Button>
              )}
              <Button
                variant="foreground"
                height={40}
                width={110}
                onClick={closeValidatePopup}
              >
                <Text
                  align="center"
                  themeColor={themes.colors.foreground}
                  themeFont={themes.fonts.h4}
                >
                  <Translate keyWord="sitgo.end.back" />
                </Text>
              </Button>
            </Row>
          </Column>
        </Popup>
        <Popup
          style={{ maxWidth: "300px" }}
          showCloseButton={true}
          active={prizePoolPopup}
          closePopup={closePrizePoolPopup}
          width={"100%"}
          padding={[35, 20]}
          closeByExternalClick={true}
        >
          <Column horizontalAlign="center">
            <Text align="center" themeFont={themes.fonts.h2}>
              <Translate keyWord="sitgo.popup.prize.title.part1" />{" "}
              <span style={{ color: `${themes.colors.secondary}` }}>
                {" "}
                <Translate keyWord="sitgo.popup.prize.title.part2" />
              </span>
            </Text>
            <Spacing variant="Column" themeSpace={30} />
            <Column horizontalAlign="center">
              {prizePool.map((item, index) => (
                <>
                  <Surface padding={[0, 10]} radius={10} height={37}>
                    <Row
                      verticalAlign="center"
                      horizontalAlign="space-between"
                      style={{ width: "100%", height: "100%" }}
                    >
                      <Text align="center" themeFont={themes.fonts.h4}>
                        {item.description}
                      </Text>
                      <Text
                        align="center"
                        themeColor={themes.colors.primary}
                        themeFont={themes.fonts.ht1}
                      >
                        {item.prize}
                      </Text>
                    </Row>
                  </Surface>
                  {index < prizePool.length - 1 && (
                    <Spacing variant="Column" themeSpace={3} />
                  )}
                </>
              ))}
            </Column>
          </Column>
        </Popup>
        <Popup
          style={{ maxWidth: "300px" }}
          showCloseButton={true}
          active={playersBetPopup}
          closePopup={closePlayersBetPopup}
          width={"100%"}
          padding={[35, 20]}
          closeByExternalClick={false}
        >
          <Column horizontalAlign="center">
            <Text align="center" themeFont={themes.fonts.h2}>
              <Translate keyWord="sitgo.bets" />{" "}
              <span
                style={{ color: `${themes.colors.secondary}` }}
              >{`(${playersBets.length})`}</span>
            </Text>
            <Spacing variant="Column" themeSpace={30} />
            <Column horizontalAlign="center">
              {playersBets.map((item, index) => (
                <>
                  <Surface padding={[0, 10]} radius={10} height={37}>
                    <Row
                      verticalAlign="center"
                      style={{ width: "100%", height: "100%" }}
                    >
                      <Text themeFont={themes.fonts.h4}>
                        {players.length === 0 ? 0 : index + 1}.
                      </Text>
                      <Spacing variant="Row" themeSpace={10} />
                      <Image src={item.picture} size={20} />
                      <Spacing variant="Row" themeSpace={10} />
                      <Text themeFont={themes.fonts.h4}>
                        {item.playerName}:
                      </Text>
                      <Spacing variant="Row" themeSpace={5} />
                      <Text themeFont={themes.fonts.ht2}>
                        {" "}
                        <Translate keyWord="sitgo.popup.text" />
                        {` ${item.bet === null ? "****" : item.bet}`}
                      </Text>
                    </Row>
                  </Surface>
                  {index < prizePool.length - 1 && (
                    <Spacing variant="Column" themeSpace={3} />
                  )}
                </>
              ))}
            </Column>
          </Column>
        </Popup>
        <Popup
          style={{ maxWidth: "300px" }}
          showCloseButton={false}
          active={amountGamePopup}
          closePopup={closeAmountGamePopup}
          width={"100%"}
          height={200}
          padding={[35, 35]}
          closeByExternalClick={false}
        >
          <Column horizontalAlign="center">
            <Text align="center" themeFont={themes.fonts.h2}>
              <Translate keyWord="upTo" />{" "}
              <span style={{ color: `${themes.colors.secondary}` }}>3</span>{" "}
              <Translate keyWord="tournamentsAtOnce" />
            </Text>
            <Spacing variant="Column" themeSpace={30} />
            <Row
              style={{ width: "100%" }}
              horizontalAlign="space-around"
              verticalAlign="center"
            >
              <Button
                variant="foreground"
                height={40}
                width={110}
                onClick={closeAmountGamePopup}
              >
                <Text
                  align="center"
                  themeColor={themes.colors.foreground}
                  themeFont={themes.fonts.h4}
                >
                  <Translate keyWord="sitgo.exit.button" />
                </Text>
              </Button>
            </Row>
          </Column>
        </Popup>
      </GameParent>
    );
  }
);

type HeaderGameProps = {
  onLobby: () => void;
  onPrizePool: () => void;
  maxPlayers: number;
  deposite: string;
  time: string;
};

const HeaderGame: FC<HeaderGameProps> = memo(
  ({ onLobby, onPrizePool, maxPlayers, deposite, time }) => {
    return (
      <Row
        style={{ width: "100%", zIndex: "10" }}
        padding={[20, 15, 0, 15]}
        horizontalAlign="space-between"
        verticalAlign="center"
      >
        <Pressable onClick={onLobby}>
          <Circle>
            <Icon themeColor={themes.colors.surface} src={leave} />
          </Circle>
        </Pressable>
        <Column verticalAlign="center" horizontalAlign="center">
          <Text themeFont={themes.fonts.h2}>Sit&Go</Text>
          <Spacing themeSpace={5} variant="Column" />
          <Text themeFont={themes.fonts.ht2}>
            {`${time} / ${maxPlayers}max /`}{" "}
            <span
              style={{ color: `${themes.colors.primary}` }}
            >{`${deposite}`}</span>
          </Text>
        </Column>
        <Pressable onClick={onPrizePool}>
          <Circle>
            <Icon themeColor={themes.colors.surface} src={bets} />
          </Circle>
        </Pressable>
      </Row>
    );
  }
);

type TableViewProps = {
  players: { name: string; picture: string; size: number }[];
  chartData: ChartData;
  statusChart: SSEStatus;
  onReconnect: () => void;
  price: number;
};

const TableView: FC<TableViewProps> = memo(
  ({ players, chartData, onReconnect, statusChart, price }) => {
    const part1 = players.slice(0, players.length / 2);
    const part2 = players.slice(players.length / 2);
    return (
      <Grid>
        <GridRow>
          {part1.map((item) => (
            <Surface
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              width={50}
              height={50}
              radius={50}
            >
              <Column style={{ position: "relative" }} horizontalAlign="center">
                <Image
                  style={{ borderRadius: "50%" }}
                  size={item.size}
                  src={item.picture}
                />
                <Text
                  align="center"
                  style={{ position: "absolute", top: "50px" }}
                  themeFont={themes.fonts.ht2}
                >
                  {item.name}
                </Text>
              </Column>
            </Surface>
          ))}
        </GridRow>
        <Column
          padding={[0, 10]}
          style={{ height: "100%", position: "relative" }}
          horizontalAlign="center"
        >
          <Surface
            radius={0}
            height={31}
            width={200}
            style={{ position: "absolute", zIndex: 100, top: "-17px" }}
          >
            <Column
              verticalAlign="center"
              horizontalAlign="center"
              style={{ height: "100%" }}
            >
              <Text themeFont={themes.fonts.h4}>
                {" "}
                <Translate keyWord="sitgo.price" /> ={" "}
                <span style={{ color: themes.colors.primary }}>${price}</span>
              </Text>
            </Column>
          </Surface>
          <Surface padding={[12, 12]} radius={57} style={{ height: "100%" }}>
            <Surface
              padding={[5, 5, 0, 0]}
              radius={57}
              style={{
                height: "100%",
                overflow: "hidden",
                boxShadow: "0px 0px 7px rgba(186, 167, 255, 0.3)",
              }}
            >
              <Chart statusChart={statusChart} data={chartData} />
            </Surface>
          </Surface>
        </Column>
        <GridRow style={{ alignItems: "flex-start", height: "100%" }}>
          {part2.map((item) => (
            <Surface
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              width={50}
              height={50}
              radius={50}
            >
              <Image
                style={{ borderRadius: "50%" }}
                size={item.size}
                src={item.picture}
              />
              <Text
                align="center"
                style={{ position: "absolute", top: "50px" }}
                themeFont={themes.fonts.ht2}
              >
                {item.name}
              </Text>
            </Surface>
          ))}
        </GridRow>
      </Grid>
    );
  }
);

type FooterGameProps = {
  status: GameStatusPriview;
  onSeat: () => void;
  onLeave: () => void;
  onMakeBet: () => void;
  onPrizePool: () => void;
  onPlayersBetPopup: () => void;
  userIsSeat: boolean;
  maxPlayers: number;
  timer: number;

  inputValue: string;
  setInputValue: (value: string) => void;
  isMakeBet: boolean;
  makeBetValue: number;
};

const GameState: FC<FooterGameProps> = memo(
  ({
    status,
    onLeave,
    onSeat,
    onMakeBet,
    userIsSeat,
    maxPlayers,
    timer,
    inputValue,
    setInputValue,
    isMakeBet,
    makeBetValue,
    onPrizePool,
    onPlayersBetPopup,
  }) => {
    return (
      <Column style={{ zIndex: 10 }} padding={[0, 10]} horizontalAlign="center">
        {status === "MAKE_BET" && userIsSeat && !isMakeBet && (
          <Column horizontalAlign="center" verticalAlign="center">
            <Text themeColor={themes.colors.gray} themeFont={themes.fonts.ht1}>
              <Translate keyWord="sitgo.text1" />
            </Text>
            <Text
              themeColor={
                Number(formatTime(timer).slice(-2)) > 10
                  ? themes.colors.foreground
                  : "#AA0020"
              }
              themeFont={themes.fonts.h4}
            >
              00h : 00m : {formatTime(timer).slice(-2)}s
            </Text>
            <Spacing variant="Column" themeSpace={10} />
          </Column>
        )}
        <AccentBlock isActive={status === 'MAKE_BET' && !isMakeBet}>
          <Surface radius={30} height={60} padding={[0, 10, 0, 15]}>
            <FooterPlayerContent
              onPlayersBetPopup={onPlayersBetPopup}
              onLeave={onLeave}
              onSeat={onSeat}
              onMakeBet={onMakeBet}
              isMakeBet={isMakeBet}
              makeBetValue={makeBetValue}
              status={status}
              onPrizePool={onPrizePool}
              userIsSeat={userIsSeat}
              maxPlayers={maxPlayers}
              timer={timer}
              inputValue={inputValue}
              setInputValue={setInputValue}
            />
          </Surface>
        </AccentBlock>
        
      </Column>
    );
  }
);

type FooterPlayerContentProps = {
  status: GameStatusPriview;
  isMakeBet: boolean;

  onSeat: () => void;
  onLeave: () => void;
  onMakeBet: () => void;
  onPrizePool: () => void;
  onPlayersBetPopup: () => void;

  userIsSeat: boolean;
  maxPlayers: number;
  timer: number;

  inputValue: string;
  setInputValue: (value: string) => void;
  makeBetValue: number;
};

const FooterPlayerContent: FC<FooterPlayerContentProps> = memo(
  ({
    status,
    isMakeBet,
    onLeave,
    onSeat,
    onPrizePool,
    userIsSeat,
    onMakeBet,
    maxPlayers,
    timer,
    inputValue,
    setInputValue,
    makeBetValue,
    onPlayersBetPopup,
  }) => {
    const translate = useTranslation();

    switch (status) {
      case "NEW":
      case "WAITING":
        return (
          <Row
            style={{ width: "100%", height: "100%" }}
            horizontalAlign="space-between"
            verticalAlign="center"
          >
            <Column>
              <Text themeFont={themes.fonts.t} themeColor={themes.colors.gray}>
                <Translate keyWord="sitgo.start" />
              </Text>
              <Spacing variant="Column" themeSpace={5} />
              <Text
                themeFont={themes.fonts.h4}
                themeColor={themes.colors.foreground}
              >
                <Translate keyWord="sitgo.join.part1" />{" "}
                <span style={{ color: `${themes.colors.primary}` }}>
                  {maxPlayers}
                </span>{" "}
                <Translate keyWord="sitgo.join.part2" />
              </Text>
            </Column>
            {!userIsSeat ? (
              <Pressable onClick={onSeat}>
                <Surface
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  radius={50}
                  themeColor={themes.colors.primary}
                  width={90}
                  height={40}
                >
                  <Text
                    themeColor={themes.colors.background}
                    themeFont={themes.fonts.h4}
                  >
                    <Translate keyWord="sitgo.join.button" />
                  </Text>
                </Surface>
              </Pressable>
            ) : (
              <Pressable onClick={onLeave}>
                <Surface
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  radius={50}
                  themeColor={themes.colors.primary}
                  width={90}
                  height={40}
                >
                  <Text
                    themeColor={themes.colors.background}
                    themeFont={themes.fonts.h4}
                  >
                    <Translate keyWord="sitgo.exit.button" />
                  </Text>
                </Surface>
              </Pressable>
            )}
          </Row>
        );
      case "PRE_BET":
        return (
          <Row
            style={{ width: "100%", height: "100%" }}
            horizontalAlign="space-between"
            verticalAlign="center"
          >
            <Column>
              <Text themeFont={themes.fonts.t} themeColor={themes.colors.gray}>
                <Translate keyWord="sitgo.timer1" />
              </Text>
              <Spacing variant="Column" themeSpace={5} />
              <Text
                themeFont={themes.fonts.h4}
                themeColor={themes.colors.foreground}
              >
                00h : 00m : {formatTime(timer).slice(-2)}s
              </Text>
            </Column>
            <Pressable onClick={onPrizePool}>
              <Surface
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                radius={50}
                themeColor={themes.colors.primary}
                width={90}
                height={40}
              >
                <Text
                  themeColor={themes.colors.background}
                  themeFont={themes.fonts.h4}
                >
                  <Translate keyWord="sitgo.prize.button" />
                </Text>
              </Surface>
            </Pressable>
          </Row>
        );
      case "MAKE_BET":
        const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.code === "Enter") {
            onMakeBet();
          }
        };
        return !userIsSeat ? (
          <Row
            style={{ width: "100%", height: "100%" }}
            horizontalAlign="flex-start"
            verticalAlign="center"
          >
            <Column>
              <Text themeFont={themes.fonts.t} themeColor={themes.colors.gray}>
                <Translate keyWord="playersAreMakingTheirBets" />
              </Text>
              <Text
                themeFont={themes.fonts.h4}
                themeColor={themes.colors.foreground}
              >
                00h : 00m : {formatTime(timer).slice(-2)}s
              </Text>
            </Column>
          </Row>
        ) : isMakeBet ? (
          <Row
            style={{ width: "100%", height: "100%" }}
            horizontalAlign="flex-start"
            verticalAlign="center"
          >
            <Column>
              <Text themeFont={themes.fonts.t} themeColor={themes.colors.gray}>
                <Translate keyWord={"sitgo.text2"} />
                <span style={{ color: `${themes.colors.primary}` }}>
                  {makeBetValue}
                </span>
              </Text>
              <Text
                themeFont={themes.fonts.h4}
                themeColor={themes.colors.foreground}
              >
                00h : 00m: {formatTime(timer).slice(-2)}s
              </Text>
            </Column>
          </Row>
        ) : (
          <Row
            style={{ width: "100%", height: "100%" }}
            horizontalAlign="space-between"
            verticalAlign="center"
          >
            <Column
              verticalAlign="center"
              style={{
                height: "100%",
                paddingRight: "10px",
                position: "relative",
              }}
            >
              <Input
                onKeyDown={onKeyDown}
                value={inputValue}
                setValue={setInputValue}
                placeholder={translate("sitgo.btrt.text.input")}
              />
              <Text
                themeColor={themes.colors.primary}
                style={{ position: "absolute", right: "10px" }}
                themeFont={themes.fonts.ht2}
              >
                {" "}
                {`00:${formatTime(timer).slice(-2)}`}
              </Text>
            </Column>
            <Pressable onClick={onMakeBet}>
              <Surface
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                radius={50}
                themeColor={themes.colors.primary}
                width={90}
                height={40}
              >
                <Text
                  themeColor={themes.colors.background}
                  themeFont={themes.fonts.h4}
                >
                  <Translate keyWord={"sitgo.play.button"} />
                </Text>
              </Surface>
            </Pressable>
          </Row>
        );
      case "STARTED":
        return (
          <Row
            style={{ width: "100%", height: "100%" }}
            horizontalAlign="space-between"
            verticalAlign="center"
          >
            <Column>
              <Text themeFont={themes.fonts.t} themeColor={themes.colors.gray}>
                <Translate keyWord="sitgo.text3" />
              </Text>
              <Spacing variant="Column" themeSpace={5} />
              <Text
                themeFont={themes.fonts.h4}
                themeColor={themes.colors.foreground}
              >
                {formatTime(timer).slice(0, 2)}h :{" "}
                {formatTime(timer).slice(3, 5)}m :{" "}
                {formatTime(timer).slice(6, 8)}s
              </Text>
            </Column>
            <Pressable onClick={onPlayersBetPopup}>
              <Surface
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                radius={50}
                themeColor={themes.colors.primary}
                width={100}
                height={40}
              >
                <Text
                  themeColor={themes.colors.background}
                  themeFont={themes.fonts.h5}
                >
                  <Translate keyWord="sitgo.bets" />
                </Text>
              </Surface>
            </Pressable>
          </Row>
        );
      default:
        return null;
    }
  }
);

type InputProps = {
  font?: FontProps;
  placeholder?: String;
  placeholderFont?: FontProps;
  themePlaceholderTextColor?: string;
  setValue?: (value: string) => void;
  value?: string;
} & InputHTMLAttributes<HTMLInputElement>;

const Input: FC<InputProps> = memo(
  ({
    font = themes.fonts.h5,
    placeholderFont = themes.fonts.ht2,
    themePlaceholderTextColor = themes.colors.gray,
    placeholder = "",
    setValue,
    value,
    ...rest
  }) => {
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (setValue !== undefined) {
        setValue(event.target.value);
      }
    };
    return (
      <InputStyled
        font={font}
        placeholder={placeholder}
        placeholderFont={placeholderFont}
        placeholderTextColor={themePlaceholderTextColor}
        value={value}
        onChange={handleInputChange}
        {...rest}
      />
    );
  }
);
