import { useQuery } from "@tanstack/react-query";
import { WalletGetValidateAddress } from "../../api/wallet/dto/request";
import { queryKeys } from "../queryKeys";
import { apiValidateAddressWithdrawal } from "./lib/apiValidateAddressWithdrawal";

type Props = {
  authToken: string;
  address: string;
  currency: string;
};

export const useGetValidateAddressWithdrawal = ({
  authToken,
  address,
  currency,
}: Props) => {
  const { data, isFetched, isError, error, isPending } = useQuery<
    void | null,
    WalletGetValidateAddress
  >({
    queryKey: queryKeys.wallet.verifyWithdraw(currency, address),
    queryFn: async () =>
      apiValidateAddressWithdrawal({ authToken, address, currency }),
    enabled: !!address && !!currency,
  });

  return {
    data,
    isFetched,
    isError,
    error,
    isPending,
  };
};
